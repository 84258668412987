import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useDispatch } from "react-redux";

import { useForm, type UseFormReturn } from "common/core/form";
import { TextAreaInput, TextInput } from "common/core/form/text";
import { Pill } from "common/core/pill_tabs";
import { isAriaInvalid } from "common/core/form/error";
import Button from "common/core/button";
import { SettingsTitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import {
  PopoutMenuMultilineItem,
  PopoutMenuMultilineHeaderItem,
} from "common/core/popout_menu/multiline";
import { Card, CardHeading, CardSection } from "common/core/card";
import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { addError } from "redux/actions/errors";
import { OrganizationTypeEnum, SettingProfileEnum } from "graphql_globals";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { IconButton } from "common/core/button/icon_button";

import CustomizedLandingPreview, { PreviewTypes, PreviewTypeLabels } from "./preview";
import BrandCustomizationLandingQuery, {
  type BrandCustomizationLanding,
  type BrandCustomizationLandingVariables,
  type BrandCustomizationLanding_organization_Organization as Organization,
} from "./index.query.graphql";
import CreateOrganizationBrandMutation from "./create_organization_brand.mutation.graphql";
import UpdateOrganizationBrandMutation from "./update_organization_brand.mutation.graphql";
import Styles from "./index.module.scss";
import { SettingsRestrictedBanner } from "../../../../../organization/setting_profile/brand/common/common";

const MESSAGES = defineMessages({
  enterText: {
    id: "a7240413-4019-4631-9702-65b7f5926efc",
    defaultMessage: "Enter text...",
  },
  saveSuccess: {
    id: "db154bdc-7254-4e91-b4ab-167630ca76b4",
    defaultMessage: "The changes have been made successfully!",
  },
  errorUpdating: {
    id: "c858ea12-15a0-4779-a973-2d679fff726f",
    defaultMessage:
      "There was an error updating your customizations. If this issue persists, please contact support.",
  },
  removeBullet: {
    id: "ad038c78-32da-4845-8c51-9cd25659f740",
    defaultMessage: "Remove bullet point",
  },
});

type FormValues = {
  bodyContent: {
    value: string | null;
  }[];
  title: string | null;
  useThemedBackgroundColor: boolean;
};

export const BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE = "landing-page";

export function CustomizeLandingPageTab() {
  return (
    <Tab to={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE}>
      <FormattedMessage
        id="0a0a3341-4cf0-4c5c-92fd-5d5a04320fc6"
        defaultMessage="Signer landing page"
      />
    </Tab>
  );
}

function getParsedValues(form: UseFormReturn<FormValues>) {
  const { title, bodyContent } = form.getValues();

  const cleanBodyContent = bodyContent.filter(({ value }) => value !== null && value !== "");

  return {
    cleanBodyContent,
    bodyContent: cleanBodyContent.map(({ value }) => value) as string[],
    title,
  };
}

function PublishLandingPageButton({
  form,
  organization,
  isLoading,
  setIsLoading,
  disabled,
}: {
  form: UseFormReturn<FormValues>;
  organization: Organization;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);

  const handleSubmit = () => {
    const useThemedBackgroundColor = form.watch("useThemedBackgroundColor");
    const { title, bodyContent, cleanBodyContent } = getParsedValues(form);

    setIsLoading(true);
    updateOrganizationBrand({
      variables: {
        input: {
          organizationBrandId: organization.organizationBrand!.id,
          signerLandingPageBlockThemed: useThemedBackgroundColor,
          signerLandingPageBlockTitle: title,
          signerLandingPageBodyContent: bodyContent,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.saveSuccess),
        });
        form.setValue("bodyContent", cleanBodyContent, { shouldDirty: true });
      })
      .catch((error) => {
        captureException(error);
        dispatch(addError(intl.formatMessage(MESSAGES.errorUpdating)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Button
      isLoading={isLoading}
      onClick={handleSubmit}
      buttonColor="action"
      variant="primary"
      disabled={disabled}
    >
      <FormattedMessage
        id="aee7d481-650b-42be-b9f3-746ec7236c78"
        defaultMessage={"Publish Landing Page"}
      />
    </Button>
  );
}

function PreviewChangesButton({
  form,
  organization,
  isLoading,
}: {
  form: UseFormReturn<FormValues>;
  organization: Organization;
  isLoading: boolean;
}) {
  const [previewType, setPreviewType] = useState<PreviewTypes>();
  const useThemedBackgroundColor = form.watch("useThemedBackgroundColor");
  const { organizationType } = organization;
  const { title, bodyContent } = getParsedValues(form);

  const previewOrganization = {
    ...organization,
    parentOrganizationId: organization.rootOrganizationId,
    organizationBrand: {
      ...organization.organizationBrand!,
      signerLandingPageBlockThemed: useThemedBackgroundColor,
      signerLandingPageBlockTitle: title,
      signerLandingPageBodyContent: bodyContent,
    },
  };

  const handleClosePreview = () => {
    setPreviewType(undefined);
  };

  return (
    <>
      {previewType && (
        <CustomizedLandingPreview
          publicOrganization={previewOrganization}
          previewType={previewType}
          onClose={handleClosePreview}
        />
      )}
      <PopoutMenu
        target={
          <Button isLoading={isLoading} buttonColor="action" variant="secondary">
            <FormattedMessage
              id="3fac673c-62b1-477c-b2b5-07d1c1a61581"
              defaultMessage="Preview Changes"
            />
            <Icon name="caret-up" />
          </Button>
        }
        placement="top"
      >
        {() => (
          <>
            <PopoutMenuMultilineHeaderItem>
              <span className={Styles.dropdownHeader}>
                <FormattedMessage
                  id="c077ce5e-3058-4bce-b894-ade268e882db"
                  defaultMessage="Select Transaction Type"
                />
              </span>
            </PopoutMenuMultilineHeaderItem>
            {(organizationType === OrganizationTypeEnum.LENDER ||
              organizationType === OrganizationTypeEnum.TITLE_AGENCY) && (
              <>
                <PopoutMenuMultilineItem
                  onClick={() => {
                    setPreviewType(PreviewTypes.FULL_RON);
                  }}
                  primaryContent={PreviewTypeLabels[PreviewTypes.FULL_RON]}
                />
                <PopoutMenuMultilineItem
                  onClick={() => {
                    setPreviewType(PreviewTypes.HYBRID);
                  }}
                  primaryContent={PreviewTypeLabels[PreviewTypes.HYBRID]}
                />
              </>
            )}
            <PopoutMenuMultilineItem
              onClick={() => {
                setPreviewType(PreviewTypes.STANDARD_NOTARIZATION);
              }}
              primaryContent={PreviewTypeLabels[PreviewTypes.STANDARD_NOTARIZATION]}
            />
            {organizationType === OrganizationTypeEnum.BUSINESS && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  setPreviewType(PreviewTypes.EASYLINK);
                }}
                primaryContent={PreviewTypeLabels[PreviewTypes.EASYLINK]}
              />
            )}
            {(organizationType === OrganizationTypeEnum.BUSINESS ||
              organizationType === OrganizationTypeEnum.TITLE_AGENCY) && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  setPreviewType(PreviewTypes.ESIGN);
                }}
                primaryContent={PreviewTypeLabels[PreviewTypes.ESIGN]}
              />
            )}
          </>
        )}
      </PopoutMenu>
    </>
  );
}

export function CustomizeLandingPageCard({
  form,
  webThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50"),
  organization,
  showPublishChangesButton,
}: {
  form: UseFormReturn<FormValues>;
  webThemeColor: string | null;
  organization: Organization;
  showPublishChangesButton: boolean;
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { errors } = form.formState;
  const fields = form.watch("bodyContent");
  const paragraphStyle = fields.length === 1;
  const useThemedBackgroundColor = form.watch("useThemedBackgroundColor");
  const disabled = Boolean(organization.organizationBrand?.managedBySettingProfile);

  const handleParagraphClick = () => {
    // Remove all but the first element
    if (fields.length > 1) {
      form.setValue("bodyContent", [fields[0]]);
    }
  };

  const handleBulletListClick = () => {
    // Add new item
    if (fields.length === 1) {
      form.setValue("bodyContent", [...fields, { value: "" }]);
    }
  };
  return (
    <Card
      footer={
        <div className={Styles.headingButtons}>
          <PreviewChangesButton form={form} organization={organization} isLoading={isLoading} />
          {showPublishChangesButton && (
            <PublishLandingPageButton
              form={form}
              organization={organization}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              disabled={disabled}
            />
          )}
        </div>
      }
    >
      <CardHeading level="h3">
        <FormattedMessage id="4be690a5-a752-4262-adec-c071b01fe758" defaultMessage="Custom Block" />
        <p className={Styles.cardHeadingDescription}>
          <FormattedMessage
            id="b3672f92-11b2-436c-93a5-ff2a71279b84"
            defaultMessage={
              "All signers invited to participate in a Proof transaction will be shown a landing page. Add an optional custom text block to the page for all signers to view."
            }
          />
        </p>
      </CardHeading>
      <CardSection>
        <CardHeading level="h3">
          <FormattedMessage
            id="ba39c66a-622a-4648-849a-67a08d227d8c"
            defaultMessage="Background color"
          />
        </CardHeading>
        <div className={Styles.pillContainer}>
          <Pill
            selected={useThemedBackgroundColor}
            disabled={disabled}
            onClick={() => {
              form.setValue("useThemedBackgroundColor", true, { shouldDirty: true });
            }}
          >
            <span className={Styles.pillContent}>
              <FormattedMessage
                id="a379a143-86eb-4458-b022-09392952d886"
                defaultMessage="Theme Color"
              />
              <div
                className={Styles.smallColorPreview}
                style={{ backgroundColor: webThemeColor! }}
              />
            </span>
          </Pill>
          <Pill
            selected={!useThemedBackgroundColor}
            disabled={disabled}
            onClick={() => {
              form.setValue("useThemedBackgroundColor", false, { shouldDirty: true });
            }}
          >
            <FormattedMessage id="d7e11ced-285e-48df-b991-7ea620b98620" defaultMessage="White" />
          </Pill>
        </div>
      </CardSection>
      <CardSection>
        <CardHeading level="h3">
          <FormattedMessage id="d9caa174-1714-4b0c-8fd5-995b463b1f30" defaultMessage="Title" />
        </CardHeading>
        <TextAreaInput
          placeholder={intl.formatMessage(MESSAGES.enterText)}
          aria-invalid={false}
          disabled={disabled}
          {...form.register("title")}
        />
      </CardSection>
      <CardSection>
        <CardHeading level="h3">
          <FormattedMessage id="06a35e1b-94e6-47ff-877a-a06490893315" defaultMessage="Body" />
        </CardHeading>
        <div className={Styles.pillContainer}>
          <Pill selected={paragraphStyle} onClick={handleParagraphClick} disabled={disabled}>
            <FormattedMessage
              id="d3de0eaf-2e6b-4159-80f9-d202d56c2dac"
              defaultMessage="Paragraph"
            />
          </Pill>
          <Pill selected={!paragraphStyle} onClick={handleBulletListClick} disabled={disabled}>
            <FormattedMessage
              id="858fabb7-2735-43dc-8e98-af415ba4a136"
              defaultMessage="Bullet List"
            />
          </Pill>
        </div>
        {fields.length === 1 ? (
          <TextAreaInput
            placeholder={intl.formatMessage(MESSAGES.enterText)}
            aria-invalid={isAriaInvalid(errors.bodyContent?.[0]?.value)}
            disabled={disabled}
            {...form.register(`bodyContent.0.value`)}
          />
        ) : (
          <>
            {fields.map((_, i) => (
              <div className={Styles.inputItem} key={i}>
                <TextInput
                  placeholder={intl.formatMessage(MESSAGES.enterText)}
                  aria-invalid={isAriaInvalid(errors.bodyContent?.[i])}
                  disabled={disabled}
                  {...form.register(`bodyContent.${i}.value`)}
                />
                <IconButton
                  name="x-mark"
                  onClick={() => {
                    form.setValue(
                      "bodyContent",
                      fields.filter((_, index) => index !== i),
                    );
                  }}
                  className={Styles.inputItemIcon}
                  label={intl.formatMessage(MESSAGES.removeBullet)}
                  variant="tertiary"
                  buttonColor="danger"
                  buttonSize="condensed"
                  disabled={disabled}
                />
              </div>
            ))}
            <div className={Styles.inputAdd}>
              <Button
                variant="tertiary"
                buttonColor="action"
                data-automation-id="add-bullet-point-button"
                disabled={disabled}
                onClick={() => {
                  form.setValue("bodyContent", [...fields, { value: "" }]);
                }}
              >
                <FormattedMessage
                  id="a8fdd242-3505-4fcf-b0d4-6fd2c2e8d749"
                  defaultMessage="Add Bullet Point +"
                />
              </Button>
            </div>
          </>
        )}
      </CardSection>
    </Card>
  );
}

function CustomizeLandingPageInner({ organization }: { organization: Organization }) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsWebsiteCustomization),
  });

  const {
    signerLandingPageBodyContent,
    signerLandingPageBlockTitle,
    signerLandingPageBlockThemed,
    styles,
  } = organization.organizationBrand!;

  const form = useForm<FormValues>({
    defaultValues: {
      bodyContent: signerLandingPageBodyContent.length
        ? signerLandingPageBodyContent.map((value) => ({ value }))
        : [{ value: "" }],
      title: signerLandingPageBlockTitle,
      useThemedBackgroundColor: signerLandingPageBlockThemed,
    },
  });

  return (
    <CustomizeLandingPageCard
      form={form}
      webThemeColor={styles.webThemeColor}
      organization={organization}
      showPublishChangesButton
    />
  );
}

export function useGetOrCreateOrgBrand(): {
  isLoading: boolean;
  organization: Organization | null | undefined;
} {
  const [activeOrganizationId] = useActiveOrganization();
  const organizationId = activeOrganizationId!;
  const [isLoading, setIsLoading] = useState(true);

  const { data, loading } = useQuery(BrandCustomizationLandingQuery, {
    variables: { organizationId },
  });

  const createOrganizationBrand = useMutation(CreateOrganizationBrandMutation);

  useEffect(() => {
    if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      !data.organization.organizationBrand
    ) {
      createOrganizationBrand({
        variables: {
          input: {
            organizationId,
          },
        },
        update(cacheProxy, { data }) {
          const { organization, ...other } = cacheProxy.readQuery<
            BrandCustomizationLanding,
            BrandCustomizationLandingVariables
          >({
            query: BrandCustomizationLandingQuery,
            variables: { organizationId },
          })!;
          if (organization?.__typename !== "Organization") {
            throw new Error(`Expected Organization, got ${organization?.__typename}.`);
          }
          if (!data?.createOrganizationBrand?.organizationBrand) {
            throw new Error("Organization brand failed to be created.");
          }
          const newOrganization = {
            ...organization,
            organizationBrand: data.createOrganizationBrand.organizationBrand,
          };
          cacheProxy.writeQuery({
            query: BrandCustomizationLandingQuery,
            variables: { organizationId },
            data: { ...other, organization: newOrganization },
          });
        },
      }).then(() => {
        setIsLoading(false);
      });
    } else if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      data.organization.organizationBrand
    ) {
      setIsLoading(false);
    }
  }, [organizationId, data, loading]);

  if (isLoading) {
    return { isLoading, organization: null };
  }

  const organization = data ? data.organization! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }

  if (!organization.organizationBrand) {
    throw new Error("Missing organization brand");
  }

  return { isLoading, organization };
}

export function CustomizeLandingPage() {
  const { isLoading, organization } = useGetOrCreateOrgBrand();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <SettingsTitle>
      <SettingsRestrictedBanner
        showBanner={organization!.organizationBrand!.managedBySettingProfile}
        profileType={SettingProfileEnum.BRAND}
      />
      <CustomizeLandingPageInner organization={organization!} />
    </SettingsTitle>
  );
}
