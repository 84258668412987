import { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import { FormattedMessage } from "react-intl";

import IdScanImage from "assets/images/signer/id-scan.svg";
import FingerprintImage from "assets/images/signer/fingerprint.svg";
import { Heading, Paragraph } from "common/core/typography";
import { segmentTrack } from "util/segment";

import type { BiometricConsentInfoTransaction } from "./biometric_consent_info_transaction.fragment.graphql";
import Styles from "./biometric_consent_modal.module.scss";

// If you modify this, note that the apps view this in a web view, so you may
// need to update the web view as well
export default function BiometricConsentCopyDisplay({
  transaction,
}: {
  transaction: BiometricConsentInfoTransaction;
}) {
  const systemCopyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClassActionClick = () => {
      segmentTrack("[biometric-consent-modal] class action link clicked");
    };

    if (systemCopyRef.current) {
      const controller = new AbortController();
      systemCopyRef.current.querySelectorAll("a").forEach((link) => {
        link.addEventListener("click", handleClassActionClick, { signal: controller.signal });
      });
      return () => controller.abort();
    }
  }, []);

  return (
    <div className={Styles.layout}>
      <img className={Styles.titleImage} src={IdScanImage} alt="ID scan image" aria-hidden />
      <Heading textStyle="headingFive" level="h1">
        <FormattedMessage
          id="0b161add-3dc8-4fcf-9096-8ff54370a73b"
          defaultMessage="How Proof uses biometric data"
        />
      </Heading>

      <div className={Styles.intro}>
        <img src={FingerprintImage} alt="fingerprint image" aria-hidden />
        <Paragraph textAlign="left">
          <FormattedMessage
            id="13551e72-720f-4171-8209-b1523a17bdb2"
            defaultMessage="Proof uses biometric data for identity verification in order to secure transactions. It is securely stored in line with applicable laws and retention policies."
          />
        </Paragraph>
      </div>
      <div
        className={Styles.copy}
        ref={systemCopyRef}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(transaction.biometricConsentCopy.systemCopy || ""),
        }}
      ></div>
      {transaction.biometricConsentCopy.addendumCopy && (
        <div
          className={Styles.copy}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(transaction.biometricConsentCopy.addendumCopy),
          }}
        ></div>
      )}
    </div>
  );
}
