import type { ReactNode } from "react";

import { FullLogo } from "common/core/logo";

import Styles from "./index.module.scss";

export function LightProofFrame({ children }: { children: ReactNode }) {
  return (
    <>
      <header className={Styles.header}>
        <FullLogo />
      </header>
      <main className={Styles.container}>{children}</main>
    </>
  );
}
