import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import SaveButton from "common/core/save_button";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { Card } from "common/core/card";
import { useLogout, getEncodedLoginUrlWithSearchParams } from "common/authentication";
import UpdatePasswordMutation, {
  type UpdatePassword_updatePassword as Password,
} from "common/form/password_form/update_password_mutation.graphql";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useId } from "util/html";
import { useForm } from "common/core/form";
import { StyledTextInput } from "common/core/form/text";
import { Heading, Paragraph } from "common/core/typography";
import { HookFormPassword } from "common/account/password/password";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { isPasswordStrong } from "util/password";
import { pushNotification } from "common/core/notification_center/actions";
import { COMPROMISED_PASSWORD } from "redux/actions/authentication";

import { isSsoEnforced } from "../utils";
import Styles from "../index.module.scss";
import SettingsUserPasswordQuery, {
  type SettingsUserPassword_viewer_user as User,
} from "./password_query.graphql";

type Props = {
  user: User;
  forceLogout: boolean;
  fullWidthFooter?: boolean;
};

type FormValues = {
  password: string;
  currentPassword: string;
};

const MESSAGES = defineMessages({
  currentPassword: {
    id: "f7b3c33c-6877-4eb6-9460-a780e8a3a354",
    defaultMessage: "Current password",
  },
  invalidPassword: {
    id: "c6f9e183-08be-47f7-87d9-3e452bc6586b",
    defaultMessage: "Invalid password",
  },
  passwordCompromised: {
    id: "e51618b9-04db-4562-99d6-217a8b5ae952",
    defaultMessage:
      "The password you are trying to use is either commonly used or has been identified in a data breach on another platform. Please choose a stronger password.",
  },
  currentPasswordRequired: {
    id: "d74b6562-8ab4-45a6-a6e7-48964a0636a6",
    defaultMessage: "Current password is required",
  },
  newPassword: {
    id: "5ff9821d-c8e0-42e9-8fe1-87f3e9b6b7c6",
    defaultMessage: "New password",
  },
  newPasswordRequired: {
    id: "a2ff708a-842a-4bb1-aade-c47e0dbcf9b3",
    defaultMessage: "New password is required",
  },
  weakPassword: {
    id: "5ff9821d-c8e0-42e9-8fe1-87f3e9b6b7c6",
    defaultMessage: "Please enter a stronger password",
  },
  save: {
    id: "a5f1c240-08ae-4ce2-bdf5-ed624c46286c",
    defaultMessage: "Save changes",
  },
  updatePasswordSuccess: {
    id: "419948fe-2015-438a-acd4-c5d6c5bd646e",
    defaultMessage: "Password successfully updated",
  },
  passwordDisabledSSO: {
    id: "d0c4c5d4-6b7d-4d6d-8f5e-3e1c5d6b7d4d",
    defaultMessage:
      "Your administrator has enabled Single Sign-On (SSO) for your account. You cannot set or change your password.",
  },
});

function PasswordForm({ user, forceLogout, fullWidthFooter }: Props) {
  const [status, setStatus] = useState<"error" | "success" | "loading" | null>(null);
  const intl = useIntl();

  const updatePasswordMutation = useMutation(UpdatePasswordMutation);

  const loginUrl = getEncodedLoginUrlWithSearchParams({
    optionalParams: {
      forbidden: "true",
      email: user.email as string,
    },
  });
  const logoutWithError = useLogout({
    redirectUrl: loginUrl,
  });
  const defaultLogout = useLogout();

  const hookForm = useForm<FormValues>({
    defaultValues: {
      ...(!user.needsPassword && { currentPassword: undefined }),
      password: undefined,
    },
  });
  const {
    handleSubmit,
    reset,
    setError,
    formState: { isValid, errors, isDirty },
  } = hookForm;

  const ssoEnforced = isSsoEnforced(user.authenticationTypes);
  const passwordInstructionsId = useId();
  const save = ({ currentPassword, password }: FormValues) => {
    if ((user.needsPassword || currentPassword) && password) {
      return updatePasswordMutation({
        variables: {
          mutationInput: {
            currentPassword,
            password,
            logout: forceLogout,
          },
        },
      })
        .then((response) => {
          const { errors } = response.data?.updatePassword as Password;
          if (!errors) {
            reset();
            if (!forceLogout) {
              pushNotification({
                message: intl.formatMessage(MESSAGES.updatePasswordSuccess),
              });
              return Promise.resolve();
            }
            defaultLogout();
          } else if (errors[0].attribute === "current_password") {
            setError(
              "currentPassword",
              { message: intl.formatMessage(MESSAGES.invalidPassword) },
              { shouldFocus: true },
            );
          }
        })
        .catch((error: Error) => {
          if (error.message.includes("password_reset_attempts_exceeded")) {
            logoutWithError();
          } else if (error.message === COMPROMISED_PASSWORD) {
            setError(
              "password",
              { message: intl.formatMessage(MESSAGES.passwordCompromised) },
              { shouldFocus: true },
            );
          } else {
            setError(
              "currentPassword",
              { message: intl.formatMessage(MESSAGES.invalidPassword) },
              { shouldFocus: true },
            );
          }
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} data-automation-id="update-password-form">
        <Card
          fullWidth
          className={Styles.card}
          {...(!fullWidthFooter && {
            footer: (
              <SaveButton
                submitting={status === "loading"}
                className="is-form"
                automationId="save-changes"
                title={intl.formatMessage(MESSAGES.save)}
                disabled={ssoEnforced || (isDirty && !isValid)}
              />
            ),
          })}
        >
          <Heading level="h3" textStyle="headingSix" className={Styles.heading}>
            <FormattedMessage id="ca888a02-79aa-45fd-849e-2c7f6d2b3e08" defaultMessage="Password" />
          </Heading>
          {forceLogout && (
            <Paragraph id={passwordInstructionsId} className={Styles.copy}>
              <FormattedMessage
                id="8b066479-9e94-44a9-a4f6-0bb873394942"
                defaultMessage="For security reasons, you will be logged out if you update your password."
              />
            </Paragraph>
          )}
          <div className={Styles.formWrapper}>
            {!user.needsPassword && (
              <>
                <StyledTextInput
                  type="password"
                  aria-invalid={isAriaInvalid(errors.currentPassword)}
                  label={intl.formatMessage(MESSAGES.currentPassword)}
                  placeholder={intl.formatMessage(MESSAGES.currentPassword)}
                  autoComplete="current-password"
                  data-automation-id="current-password-field"
                  disabled={ssoEnforced}
                  disabledHint={intl.formatMessage(MESSAGES.passwordDisabledSSO)}
                  displayRequiredAsterisk
                  {...hookForm.register("currentPassword", {
                    required: intl.formatMessage(MESSAGES.currentPasswordRequired),
                  })}
                />
                {errors.currentPassword && (
                  <FormattedFieldError
                    inputName={"currentPassword"}
                    error={errors.currentPassword}
                  />
                )}
              </>
            )}
            <HookFormPassword
              label={intl.formatMessage(MESSAGES.newPassword)}
              placeholder={intl.formatMessage(MESSAGES.newPassword)}
              registerProps={hookForm.register("password", {
                required: intl.formatMessage(MESSAGES.newPasswordRequired),
                validate: (pw) => isPasswordStrong(pw) || intl.formatMessage(MESSAGES.weakPassword),
              })}
              invalid={Boolean(errors.password)}
              error={errors.password}
              withRequirements
              autoComplete="new-password"
              aria-describedby={!errors.password ? passwordInstructionsId : undefined}
              disabled={ssoEnforced}
              disabledHint={intl.formatMessage(MESSAGES.passwordDisabledSSO)}
              displayRequiredAsterisk
            />
          </div>
          {fullWidthFooter && (
            <div className={Styles.fullWidthFooter}>
              <SaveButton
                submitting={status === "loading"}
                className="is-form"
                automationId="save-changes"
                title={intl.formatMessage(MESSAGES.save)}
                fullWidth
                disabled={ssoEnforced || (isDirty && !isValid)}
              />
            </div>
          )}
        </Card>
        {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
      </form>
    </>
  );
}

export default function PasswordFormContainer({ fullWidthFooter }: { fullWidthFooter?: boolean }) {
  const { data, loading } = useQuery(SettingsUserPasswordQuery);
  const user = data?.viewer.user;
  const forceLogout = !user?.needsPassword;

  return loading || !user ? (
    <LoadingIndicator />
  ) : (
    <PasswordForm user={user} forceLogout={forceLogout} fullWidthFooter={fullWidthFooter} />
  );
}
