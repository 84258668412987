import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useMobileScreenClass } from "common/core/responsive";
import { isMobileDevice } from "util/support";
import { Heading, Paragraph } from "common/core/typography";
import PhoneMeetingImage from "assets/images/phone-meeting.svg";
import Svg from "common/core/svg";
import { TwoColumnMediaSection } from "common/account/guest_signup/mobile_web_qr_code";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  qrAlt: {
    id: "4b505937-a00e-4aec-8923-49fcadff8216",
    defaultMessage: "QR code to scan and start notarization process on phone",
  },
});

export function BitLandingQrCode({
  qrStream,
  automationPrefix,
}: {
  qrStream?: string | null;
  automationPrefix: string;
}) {
  const intl = useIntl();
  const isMobile = useMobileScreenClass();

  if (!qrStream || isMobileDevice() || isMobile) {
    return null;
  }

  const svgDataUrlSrc = `data:image/svg+xml;base64,${btoa(qrStream)}`;

  return (
    <TwoColumnMediaSection
      leftColumn={
        <>
          <Heading level="h2" textStyle="headingSix">
            <FormattedMessage
              id="6a2eb657-746b-4ac2-ba00-595352b9a8ec"
              defaultMessage="Want to use your phone for the meeting?"
            />
          </Heading>
          <div className={Styles.qrWrapper}>
            <img
              data-pendo-id={`${automationPrefix}-qr-code`}
              src={svgDataUrlSrc}
              alt={intl.formatMessage(MESSAGES.qrAlt)}
            />
            <Paragraph size="large">
              <FormattedMessage
                id="442c0686-a6e5-4eb2-a5b5-0b68e11baf6d"
                defaultMessage="Scan the QR code to set up your audio and video and get your document notarized using your phone."
              />
            </Paragraph>
          </div>
        </>
      }
      rightColumn={<Svg alt="" src={PhoneMeetingImage} />}
    />
  );
}
