import { FormattedMessage } from "react-intl";

import InitiateSigning from "common/signer/initiate_signing";
import { useMobileScreenClass } from "common/core/responsive";
import { isActive } from "util/transaction";
import { useNavigateToBundleViewRoute } from "util/routes";
import { DocumentBundleParticipantStatus, OrgTransactionStates } from "graphql_globals";
import CoBrandedBlock from "common/signer/bit_landing/content/custom_block";
import Contacts from "common/signer/bit_landing/content/contacts";
import Support from "common/signer/bit_landing/content/support";
import { LandingWrapper } from "common/signer/bit_landing/content/common";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
} from "common/signer/bit_landing/index.query.graphql";
import AlertMessage from "common/core/alert_message";
import { Paragraph } from "common/core/typography";
import { ProofFooter } from "common/proof_footer";

import { PageWrapper, ContentWrapper, MobileFooter } from "../../sections/common";
import { AwaitingOtherSigners, Complete } from "./hero";
import { Expired } from "../../sections/hero/expired";
import { RonHero, InactiveRonHero } from "../../sections/hero/ron";
import { Stats } from "./stats";
import { SIGNING_STATE, useTransactionSigningState } from "./util";
import { BitLandingQrCode } from "../../sections/qr_code";

export const REAL_RON_LANDING_AUTOMATION_PREFIX = "mortgage-landing";
export const REAL_RON_PENDO_ID = "mortgage-ron-landing-cta";
const SECONDARY_BTN_AUTO_ID = `${REAL_RON_LANDING_AUTOMATION_PREFIX}-preview-button`;

type WrapperProps = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  handleBlockedAction: (action: () => void) => void;
};

type Props = WrapperProps & {
  initiateSigningForBundle: () => void;
  initiatingSigning: boolean;
};

function RealRonLandingPage({
  documentBundle,
  viewer,
  initiatingSigning,
  initiateSigningForBundle,
  readOnly,
  handleBlockedAction,
}: Props) {
  const { user } = viewer;
  const { id, availableForSigning, organizationTransaction, participants, documents } =
    documentBundle;
  const { activation, isExpired, state, publicOrganization } = organizationTransaction;
  const { organizationBrand } = publicOrganization;
  const isMobile = useMobileScreenClass();

  const brandName = publicOrganization.organizationBrand.name;

  const navigateToBundleView = useNavigateToBundleViewRoute();
  const currentUserSigned =
    participants?.find((participant) => participant?.userId === user?.id)?.signingStatus ===
    DocumentBundleParticipantStatus.COMPLETE;

  // This function can only render a single state so below need to check unableToSign state separately to render the alert message alongside normal hero section (DEFAULT)
  const transactionSigningState = useTransactionSigningState({
    isActive: isActive(activation),
    isExpired,
    isComplete: state === OrgTransactionStates.COMPLETED,
    currentUserSigned,
  });

  const unableToSign = !availableForSigning && !currentUserSigned;

  const handleContinue = () => {
    if (readOnly) {
      return;
    }
    if (transactionSigningState !== SIGNING_STATE.DEFAULT || unableToSign) {
      navigateToBundleView({ bundleId: id });
    } else {
      initiateSigningForBundle();
    }
  };

  const showPreviewDocumentsButton =
    (unableToSign && transactionSigningState !== SIGNING_STATE.COMPLETE) ||
    transactionSigningState === SIGNING_STATE.INACTIVE ||
    transactionSigningState === SIGNING_STATE.AWAITING_OTHER_SIGNERS;

  const showViewDocumentsButton =
    transactionSigningState === SIGNING_STATE.EXPIRED ||
    transactionSigningState === SIGNING_STATE.COMPLETE;

  const buttonCta = showPreviewDocumentsButton ? (
    <FormattedMessage
      id="00e4fa2d-9eab-4a07-a0f6-cdf10a25e950"
      defaultMessage="Preview documents"
    />
  ) : showViewDocumentsButton ? (
    <FormattedMessage id="18684a96-e147-4f47-80a7-889547a7b24c" defaultMessage="View documents" />
  ) : (
    <FormattedMessage id="8af5131a-fc0d-44c7-9dea-cf47f1c691c4" defaultMessage="Notarize now" />
  );

  const buttonCtaOnClick =
    transactionSigningState === SIGNING_STATE.DEFAULT && !unableToSign
      ? () => handleBlockedAction(handleContinue)
      : handleContinue;

  const unableToSignAlert = (
    <AlertMessage banner={!isMobile} centerText kind="info">
      <Paragraph size={isMobile ? "defaultSize" : "large"}>
        <FormattedMessage
          id="1949c30e-2325-4186-a63e-b2254552ce4a"
          defaultMessage="Someone is already signing. To keep your documents secure, we only allow one person to sign at a time. You can read through the contents of your document or return later to sign."
        />
      </Paragraph>
    </AlertMessage>
  );

  function renderHero() {
    switch (transactionSigningState) {
      case SIGNING_STATE.INACTIVE:
        return (
          <InactiveRonHero
            isMortgage
            buttonCta={buttonCta}
            buttonOnClick={buttonCtaOnClick}
            brandName={brandName}
            documentCount={documents.totalCount}
            activation={organizationTransaction.activation}
          />
        );
      case SIGNING_STATE.EXPIRED:
        return (
          <Expired
            buttonOnClick={buttonCtaOnClick}
            automationPrefix={REAL_RON_LANDING_AUTOMATION_PREFIX}
            brandName={brandName}
            pendoId={`${REAL_RON_PENDO_ID}-expired`}
          />
        );
      case SIGNING_STATE.AWAITING_OTHER_SIGNERS:
        return (
          <AwaitingOtherSigners
            pendoId={`${REAL_RON_PENDO_ID}-awaiting`}
            buttonCta={buttonCta}
            buttonOnClick={buttonCtaOnClick}
            automationId={SECONDARY_BTN_AUTO_ID}
          />
        );
      case SIGNING_STATE.COMPLETE:
        return (
          <Complete
            readOnly={readOnly}
            documentBundle={documentBundle}
            pendoId={`${REAL_RON_PENDO_ID}-complete`}
            buttonCta={buttonCta}
            buttonOnClick={buttonCtaOnClick}
            automationId={SECONDARY_BTN_AUTO_ID}
          />
        );
      default:
        return (
          <>
            {unableToSign && unableToSignAlert}
            <RonHero
              isMortgage
              automationId={unableToSign ? SECONDARY_BTN_AUTO_ID : undefined}
              buttonCta={buttonCta}
              buttonOnClick={buttonCtaOnClick}
              brandName={brandName}
              documentCount={documents.totalCount}
            />
          </>
        );
    }
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        {renderHero()}
        <CoBrandedBlock useNewStyles organizationBrand={organizationBrand} />
        <BitLandingQrCode
          qrStream={viewer.qrStream}
          automationPrefix={REAL_RON_LANDING_AUTOMATION_PREFIX}
        />
        <Stats />
        <Contacts useNewStyles contacts={organizationTransaction.contacts} />
        <Support useNewStyles />
        <ProofFooter withContainer />
      </ContentWrapper>

      <MobileFooter
        pendoId={REAL_RON_PENDO_ID}
        isLoading={initiatingSigning}
        buttonCta={buttonCta}
        buttonOnClick={buttonCtaOnClick}
      />
    </PageWrapper>
  );
}

function RealRonLandingWrapper(props: WrapperProps) {
  const { documentBundle, viewer, readOnly, handleBlockedAction } = props;
  return (
    <InitiateSigning viewer={viewer} documentBundle={documentBundle}>
      {({ loading, initiateSigningForBundle }) => (
        <LandingWrapper skipExpiredOrInactiveCheck {...props}>
          {() => (
            <RealRonLandingPage
              viewer={viewer}
              documentBundle={documentBundle}
              initiateSigningForBundle={initiateSigningForBundle}
              initiatingSigning={loading}
              readOnly={readOnly}
              handleBlockedAction={handleBlockedAction}
            />
          )}
        </LandingWrapper>
      )}
    </InitiateSigning>
  );
}

export { RealRonLandingWrapper as RealRonLandingPage };
