import { usePermissions } from "common/core/current_user_role";
import { useShowDocTemplates, useShowEasylinks } from "common/dashboard/util";
import { useFeatureFlag } from "common/feature_gating";
import { Feature, OrganizationTypeEnum } from "graphql_globals";
import { useReferralsTitle } from "util/feature_detection";

import { type Tools_node_Organization as Organization } from "./index_query.graphql";

function useShowToolsBusiness(featureList: Organization["featureList"] = []) {
  const { hasPermissionFor } = usePermissions();

  // useShowDocTemplates checks the permissions for us
  const showTemplates = useShowDocTemplates(featureList);
  const showEasyLinks = useShowEasylinks(featureList) && hasPermissionFor("viewEasyLinks");
  const showReferrals =
    useFeatureFlag("transaction-referrals") &&
    (hasPermissionFor("createReferralCampaigns") || hasPermissionFor("updateReferralCampaigns"));
  return {
    showTemplates,
    showEasyLinks,
    showReferrals,
    hasToolsFeatures: Boolean(showTemplates || showEasyLinks || showReferrals),
  };
}

function useShowToolsReal(featureList: Organization["featureList"] = []) {
  const { hasPermissionFor } = usePermissions();

  const showTemplates =
    featureList.includes(Feature.DOCUMENT_TEMPLATES) &&
    (hasPermissionFor("manageLenderTemplates") || hasPermissionFor("manageTitleTemplates"));
  const realEstateEasylinksEnabled = useFeatureFlag("real-estate-easylink");
  const showEasyLinks = useShowEasylinks(featureList) && realEstateEasylinksEnabled;
  const showReferrals =
    useReferralsTitle() &&
    (hasPermissionFor("createReferralCampaigns") || hasPermissionFor("updateReferralCampaigns"));

  return {
    showTemplates,
    showEasyLinks,
    showReferrals,
    hasToolsFeatures: Boolean(showTemplates || showEasyLinks || showReferrals),
  };
}

export function useShowTools(
  featureList: Organization["featureList"] = [],
  organizationType: OrganizationTypeEnum = OrganizationTypeEnum.BUSINESS,
) {
  const toolsFeaturesBusiness = useShowToolsBusiness(featureList);
  const toolsFeaturesReal = useShowToolsReal(featureList);

  return organizationType === OrganizationTypeEnum.BUSINESS
    ? toolsFeaturesBusiness
    : toolsFeaturesReal;
}
