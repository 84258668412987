import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useForm, type UseFormReturn } from "common/core/form";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";

import { ColorUpdater } from "./common/color_updater";
import type { BrandSettingsWebThemeColor as OrganizationBrand } from "./web_theme_color.fragment.graphql";
import useOrganizationBrandUpdater from "./common/update_organization_brand_styles";

const MESSAGES = defineMessages({
  primaryColor: {
    id: "03d4c442-7afc-414e-92cd-5319625ad6c2",
    defaultMessage: "Primary color",
  },
});

type FormValues = {
  primaryColor: string;
};

type Props = {
  organizationBrand: OrganizationBrand;
  disabled?: boolean;
};

export function ThemeColorCard({
  form,
  isUpdating = false,
  setResetValue,
  withFooter = false,
  disabled,
}: {
  form: UseFormReturn<FormValues>;
  isUpdating?: boolean;
  setResetValue: (value: boolean) => void;
  withFooter?: boolean;
  disabled?: boolean;
}) {
  const intl = useIntl();

  return (
    <Card
      footer={
        withFooter && (
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            isLoading={isUpdating}
            automationId="web-theme-color-submit"
            disabled={disabled}
          >
            <FormattedMessage
              id="63f1c8b8-1af7-445f-8ac8-e4eb1c1f21d6"
              defaultMessage="Save changes"
            />
          </Button>
        )
      }
    >
      <CardHeading level="h3">
        <FormattedMessage
          id="3a57f1b2-0dfd-4845-9061-54b2173adecb"
          defaultMessage="Web theme color"
        />
      </CardHeading>
      <CardText>
        <FormattedMessage
          id="cc52747c-005b-4473-9949-f6cf3d3622ca"
          defaultMessage="The custom web theme color applies to the signer login screen, signer landing pages, and the signer progress bar. Enter a hex code to customize."
        />
      </CardText>

      <ColorUpdater
        colorFieldName="primaryColor"
        form={form}
        placeholderText={intl.formatMessage(MESSAGES.primaryColor)}
        isUpdating={isUpdating}
        setResetValue={setResetValue}
        disabled={disabled}
      />
    </Card>
  );
}

export function WebThemeColor(props: Props) {
  const { id: organizationBrandId, styles } = props.organizationBrand;
  const defaultThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50");
  const webThemeColor = styles.webThemeColor || defaultThemeColor;
  const [resetValue, setResetValue] = useState(false);

  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      primaryColor: webThemeColor,
    },
  });

  const { handleUpdate, isUpdating } = useOrganizationBrandUpdater(organizationBrandId);
  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      const { primaryColor } = formValues;
      const webThemeColor = resetValue ? null : primaryColor;

      return handleUpdate({
        webThemeColor,
      }).then(() => {
        setResetValue(false);
      });
    },
    [organizationBrandId, resetValue],
  );

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <ThemeColorCard
        form={form}
        isUpdating={isUpdating}
        setResetValue={setResetValue}
        withFooter
        disabled={props.disabled}
      />
    </form>
  );
}
