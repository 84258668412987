import { memo, useState, type ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useApolloClient, useMutation } from "util/graphql";
import { encodeSearchParams } from "util/location";
import SelectSignersModal from "common/signer/select_signers_modal";
import TermsOfServiceModal from "common/modals/terms_of_service";
import BundleUnavailableModal from "common/modals/bundle_unavailable_modal";
import BundleNotFoundModal from "common/document_bundle/bundle_not_found_modal";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { getParticipantGroups } from "util/document_bundle";
import { useIsMobileWebEnabled } from "util/mobile_web";
import SelectMobileWebOrAppBlock from "common/signer/select_mobile_web_or_app_block";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import { useNavigateToBundleViewRoute, SIGN_PATH } from "util/routes";
import { useLazyQuery } from "util/graphql/query";
import {
  StepType,
  RequiredFeature,
  SigningRequirementEnum,
  DocumentBundleParticipantActivity,
  DocumentBundleParticipantStatus,
  ProcessingStates,
} from "graphql_globals";
import { COLOCATED_SIGNER_EMAILS_KEY } from "common/account/guest_signup/v2/gather_signer_details";
import { captureException } from "util/exception";

import InitiateSigningQuery, { type InitiateSigning_viewer as Viewer } from "./index_query.graphql";
import SignerStepsQuery, {
  type SignerSteps,
  type SignerStepsVariables,
} from "./signer_steps_query.graphql";
import SignParticipantTOSMutation from "./sign_participant_tos_mutation.graphql";
import CreateSignerIdentitiesMutation from "./create_signer_identities_mutation.graphql";
import type {
  InitiateSigningDocBundle_participants as Participant,
  InitiateSigningDocBundle as DocumentBundle,
} from "./index_docbundle_fragment.graphql";
import {
  CURRENT_STEP_SEARCH_PARAM,
  getCurrentStep,
  useMapStepToRoute,
} from "../signer_steps_v2/util";
import DocumentsProcessingModal from "./documents_processing_modal";

export const SIGNER_IDS_SESSION_KEY = "signer-identity-ids";

type Modal =
  | "select-signers"
  | "accept-tos"
  | "bundle-unavailable"
  | "bundle-not-found"
  | "mweb-block"
  | "signer-identity-creation-error"
  | "docs-processing";
type RenderProps = Omit<ReturnType<typeof getParticipantGroups>, "participantGroup"> & {
  initiateSigningForBundle: () => void;
  loading: boolean;
};
type Props = {
  viewer: Viewer;
  documentBundle: DocumentBundle;
  children: (renderProps: RenderProps) => ReactNode;
};

const getParticipantWhoStillNeedsToAcceptTos = (
  participants: Props["documentBundle"]["participants"],
  userIds: string[],
) => {
  const participantsWhoStillNeedsToAcceptTos = participants?.filter((participant) => {
    return participant && userIds.includes(participant.userId) && !participant.tosSigned;
  });

  return participantsWhoStillNeedsToAcceptTos?.[0] || null;
};

function InitiateSigningWrapper(props: Props) {
  const {
    documentBundle: { id: documentBundleId },
  } = props;

  const [selectedOtherParticipantGroupOwners, setSelectOtherParticipantGroupOwners] = useState<
    string[]
  >([]);
  const [openModal, setOpenModal] = useState<Modal | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [participantAcceptingTos, setParticipantAcceptingTos] = useState<Participant | null>(null);
  const [outstandingAction, setOutstandingAction] = useState(false);
  const navigate = useNavigate();
  const navigateToBundleView = useNavigateToBundleViewRoute();
  const location = useLocation();
  const client = useApolloClient();
  const isMobileWebEnabled = useIsMobileWebEnabled();
  const mapStepToRoute = useMapStepToRoute();

  const [initiateSigning] = useLazyQuery(InitiateSigningQuery);

  function checkIfBundleAvailableForSigning() {
    setOutstandingAction(true);
    return initiateSigning({
      variables: { documentBundleId },
    })
      .then(({ data }) => {
        if (data?.documentBundle?.__typename !== "DocumentBundle") {
          throw new Error("Initiate signing query failed to return data");
        }
        const userParticipantRequirement = data.documentBundle.participants?.find(
          (p) => p?.userId === props.viewer.user?.id,
        )?.signingRequirement;

        // This is to support mixed signers, where we want to prevent esign only signers from
        // starting the signing if there are notary or other esign signers who have already started the signign process.
        // Same thing for notary signers, we want to prevent them from starting signing is an esign signer
        // has started signing, but allow them to continue if another notary signer has started signing.
        const canContinueWithOtherActiveSigners = data.documentBundle.participants
          ?.filter(
            (p) =>
              p?.userId !== props.viewer.user?.id &&
              p?.signingStatus !== DocumentBundleParticipantStatus.COMPLETE,
          )
          .every((p) => {
            if (p?.signingActivity !== DocumentBundleParticipantActivity.NOT_SIGNING) {
              return userParticipantRequirement === SigningRequirementEnum.ESIGN
                ? false
                : p?.signingRequirement === userParticipantRequirement;
            }
            return true;
          });
        return data.documentBundle.availableForSigning && canContinueWithOtherActiveSigners;
      })
      .finally(() => {
        setOutstandingAction(false);
      });
  }

  const signParticipantTOSMutateFn = useMutation(SignParticipantTOSMutation);
  const acceptTermsOfService = (participant: Pick<Participant, "userId">) =>
    signParticipantTOSMutateFn({
      variables: {
        input: {
          userId: participant.userId,
          documentBundleId,
        },
      },
      // prevent the document bundle from being re-fetched by parent components or page will
      // refresh before all participants can sign
      onQueryUpdated: () => false,
    });

  const createSignerIdentitiesMutateFn = useMutation(CreateSignerIdentitiesMutation);
  async function createSignerIdentities(userIds: string[]) {
    const response = await createSignerIdentitiesMutateFn({
      variables: {
        input: {
          userIds,
          documentBundleId,
        },
      },
    }).catch((error: Error) => {
      setErrorMessage(error.message);
      setOpenModal("signer-identity-creation-error");
    });
    if (!response || !response.data?.createSignerIdentities?.signerIdentities) {
      throw new Error("Failed to create signer identities");
    }
    const signerIdentities = response.data.createSignerIdentities.signerIdentities;
    const { data: viewerData } = await client.query<SignerSteps, SignerStepsVariables>({
      query: SignerStepsQuery,
      variables: { signerIdentityIds: signerIdentities.map((s) => s.id) },
    });

    return {
      signerIdentities: response.data.createSignerIdentities.signerIdentities,
      signerStepsV2: viewerData.viewer.signerStepsV2,
    };
  }

  async function handleInitiateSigningForBundle() {
    const {
      viewer: { user },
      documentBundle: {
        participants,
        organizationTransaction,
        completionRequirements,
        isMortgage,
        requiredFeatures,
        documents,
      },
    } = props;
    const { hasOptionalSigners, hasMultipleRequiredSigners } = getParticipantGroups(
      participants,
      user!,
      true,
      requiredFeatures?.includes(RequiredFeature.SIGNING_ORDER),
    );

    const requiresNsaMeeting = organizationTransaction.requiresNsaMeeting;
    const docVisibilityEnabled = organizationTransaction.docVisibilityEnabled;
    const isOnlyEsign = onlyRequiresEsign(completionRequirements);
    const userParticipantIsEsign =
      participants?.find((p) => p?.userId === user?.id)?.signingRequirement ===
      SigningRequirementEnum.ESIGN;

    let availableForSigning;
    try {
      availableForSigning = await checkIfBundleAvailableForSigning();
    } catch {
      setOpenModal("bundle-not-found");
      return;
    }

    // Not available for signing yet; redirect to document bundle view to display
    // busy state
    if (!availableForSigning) {
      const skipBundleUnavailable =
        !isMortgage &&
        (location.pathname.endsWith("/sign") || location.pathname.endsWith("/prepare"));
      if (skipBundleUnavailable) {
        setOpenModal(null);
        return navigateToBundleView({ bundleId: documentBundleId });
      }

      setOpenModal("bundle-unavailable");
      return;
    }

    // if on mobile and the transaction does not support mobile web, present
    // the download app block.
    if (!isMobileWebEnabled) {
      setOpenModal("mweb-block");
      return;
    }

    // if any of the bundle's documents are still processing we cannot create signer identities,
    // so show a blocking/loading modal
    if (documents.edges.some(({ node }) => node.processingState !== ProcessingStates.DONE)) {
      setOpenModal("docs-processing");
      return;
    }

    // Available for signing; decide whether we need to open the select signers
    // modal.
    // Skip modal if:
    // - Nsa meeting not required
    // - is only an esign
    // - user participant has a signing requirement of esign
    // - Doc visibility is enabled
    // - both required and optional signers are present
    // - both required and optional signers are missing
    if (
      docVisibilityEnabled ||
      isOnlyEsign ||
      userParticipantIsEsign ||
      (!hasOptionalSigners && !hasMultipleRequiredSigners) ||
      !requiresNsaMeeting
    ) {
      if (user) {
        createSignerIdentitiesForParticipantsAndRedirect([user.id]);
      }
    } else if (window.sessionStorage.getItem(COLOCATED_SIGNER_EMAILS_KEY)) {
      findParticipantsMissingTosOrCreateSignerIdentitiesThenRedirect(
        (props.documentBundle.participants || []) as Participant[],
      );
    } else {
      setOpenModal("select-signers");
    }
  }

  function handleContinueSelectModalClicked() {
    findParticipantsMissingTosOrCreateSignerIdentitiesThenRedirect(
      (props.documentBundle.participants || []) as Participant[],
    );
  }

  function handleContinueAcceptTosModalClicked(participant: Pick<Participant, "userId">) {
    setOutstandingAction(true);

    acceptTermsOfService(participant)
      .then(({ data }) => {
        if (!data?.signParticipantTos?.document_bundle) {
          throw new Error("Failed to retrieve documentBundle after accepting terms of service");
        }
        findParticipantsMissingTosOrCreateSignerIdentitiesThenRedirect(
          (data.signParticipantTos.document_bundle.participants || []) as Participant[],
        );
      })
      .finally(() => {
        setOutstandingAction(false);
      });
  }

  function closeModals() {
    setOpenModal(null);
    navigate(SIGN_PATH);
  }

  function findParticipantsMissingTosOrCreateSignerIdentitiesThenRedirect(
    participants: Participant[],
  ) {
    const {
      viewer: { user },
      documentBundle: { isMortgage },
    } = props;

    const { participantGroups, participantGroup } = getParticipantGroups(participants, user!, true);

    const selectedParticipantGroups = (participantGroup || []).concat(
      selectedOtherParticipantGroupOwners.flatMap((owner) => participantGroups[owner]),
    );

    let selectedParticipants = selectedParticipantGroups.map((participant) => participant.userId);

    const sessionColocatedEmails = window.sessionStorage.getItem(COLOCATED_SIGNER_EMAILS_KEY);
    if (sessionColocatedEmails) {
      try {
        const colocatedEmails = JSON.parse(sessionColocatedEmails) as string[];
        const colocatedParticipants = participants.filter(
          (p) => p.email && colocatedEmails.includes(p.email),
        );
        selectedParticipants = colocatedParticipants.map((p) => p.userId);
      } catch (error) {
        captureException(error);
      }
      window.sessionStorage.removeItem(COLOCATED_SIGNER_EMAILS_KEY);
    }

    const participant = getParticipantWhoStillNeedsToAcceptTos(participants, selectedParticipants);

    if (!participant || !isMortgage) {
      createSignerIdentitiesForParticipantsAndRedirect(selectedParticipants);
    } else {
      setParticipantAcceptingTos(participant);
      setOpenModal("accept-tos");
    }
  }

  function createSignerIdentitiesForParticipantsAndRedirect(userIds: string[]) {
    const { documentBundle } = props;

    setOutstandingAction(true);

    createSignerIdentities(userIds).then(({ signerIdentities, signerStepsV2 }) => {
      const signerIdentityIds = signerIdentities.map((signerIdentity) => signerIdentity.id);
      const currentStep = getCurrentStep({ steps: signerStepsV2, currentStepIndex: 0 });

      const searchParams = new URLSearchParams([
        ["currentSignerIdentityId", signerIdentityIds[0]],
        ...signerIdentityIds.map((si) => ["signerIdentityIds", si]),
      ]);
      if (currentStep.stepType === StepType.DOCUMENT) {
        searchParams.set("step", "prefill");
      }
      searchParams.set(CURRENT_STEP_SEARCH_PARAM, "0");
      const search = encodeSearchParams(searchParams);
      window.sessionStorage.setItem(SIGNER_IDS_SESSION_KEY, signerIdentityIds.join(","));
      setOutstandingAction(false);
      setOpenModal(null);

      const pathname = mapStepToRoute({
        step: currentStep,
        documentBundle,
      });
      // kick start signer steps by setting the first step as the current step
      navigate(`${pathname}?${search}`, { replace: true });
    });
  }

  function handleParticipantSelectToggled(participantId: string) {
    setSelectOtherParticipantGroupOwners(
      selectedOtherParticipantGroupOwners.includes(participantId)
        ? selectedOtherParticipantGroupOwners.filter((participant) => participant !== participantId)
        : [...selectedOtherParticipantGroupOwners, participantId],
    );
  }

  function handleViewDocumentsClicked() {
    const {
      documentBundle: { id },
    } = props;

    if (openModal === "select-signers") {
      return navigateToBundleView({ bundleId: id, viewOnly: true });
    }
    navigateToBundleView({ bundleId: id });
    setOpenModal(null);
  }

  const {
    viewer,
    documentBundle,
    documentBundle: { participants, organizationTransaction, requiredFeatures },
    children,
  } = props;

  const {
    participantGroups,
    participantGroupOwners,
    hasOptionalSigners,
    hasMultipleRequiredSigners,
    participantGroup,
  } = getParticipantGroups(
    participants,
    viewer.user!,
    true,
    requiredFeatures?.includes(RequiredFeature.SIGNING_ORDER),
  );

  return (
    <>
      {openModal === "bundle-not-found" ? (
        <BundleNotFoundModal />
      ) : openModal === "bundle-unavailable" ? (
        <BundleUnavailableModal
          onContinue={handleViewDocumentsClicked}
          documentBundle={documentBundle}
        />
      ) : openModal === "mweb-block" ? (
        <SelectMobileWebOrAppBlock organizationTransaction={organizationTransaction} />
      ) : openModal === "signer-identity-creation-error" ? (
        <WorkflowModal
          autoFocus
          closeBehavior={{ tag: "with-button", onClose: () => navigate("/") }}
          isSensitive={false}
          buttons={[
            <Button
              buttonColor="action"
              variant="primary"
              onClick={() => navigate("/")}
              key="close-error-modal"
              automationId="close-error-modal"
            >
              <FormattedMessage
                id="90c5735d-0a5c-448a-bb1f-1c958a696e27"
                defaultMessage="Back to dashboard"
              />
            </Button>,
          ]}
          title={
            <FormattedMessage id="d143c614-faed-4d97-bb67-74aa4f563ac2" defaultMessage="Error" />
          }
        >
          {errorMessage}
        </WorkflowModal>
      ) : openModal === "docs-processing" ? (
        <DocumentsProcessingModal
          documentBundle={documentBundle}
          onFinishedProcessing={() => {
            setOpenModal(null);
            handleInitiateSigningForBundle();
          }}
        />
      ) : openModal === "select-signers" ? (
        <SelectSignersModal
          documentBundle={documentBundle}
          participantGroups={participantGroups}
          participantGroupOwners={participantGroupOwners}
          selectedOtherParticipantGroupOwners={selectedOtherParticipantGroupOwners}
          onParticipantSelectToggled={handleParticipantSelectToggled}
          onContinue={handleContinueSelectModalClicked}
          onCancel={() => {
            navigate(-1);
          }}
          onViewDocuments={handleViewDocumentsClicked}
          loading={outstandingAction}
          hasMultipleRequiredSigners={hasMultipleRequiredSigners}
          participantGroup={participantGroup || []}
        />
      ) : openModal === "accept-tos" && participantAcceptingTos ? (
        <TermsOfServiceModal
          onAccept={handleContinueAcceptTosModalClicked}
          onCancel={closeModals}
          user={participantAcceptingTos}
          loading={outstandingAction}
          showForm
        />
      ) : null}
      {children({
        initiateSigningForBundle: handleInitiateSigningForBundle,
        loading: outstandingAction,
        participantGroups,
        participantGroupOwners,
        hasOptionalSigners,
        hasMultipleRequiredSigners,
      })}
    </>
  );
}

export default memo(InitiateSigningWrapper);
