import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useQuery } from "util/graphql";
import { segmentTrack } from "util/segment";
import LoadingIndicator from "common/core/loading_indicator";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import { PRIVACY_REQUEST_URL } from "constants/support";
import Link from "common/core/link";

import { AccountDeletionModal } from "./account_deletion_modal";
import AccountDeletionQuery from "./account_deletion_query.graphql";

export function AccountDeletion() {
  const { data, loading } = useQuery(AccountDeletionQuery);
  const [modalOpen, setModalOpen] = useState(false);
  const onOpen = () => {
    setModalOpen(true);
    segmentTrack("User opened 'delete account' modal");
  };
  const onClose = () => {
    setModalOpen(false);
    segmentTrack("User closed 'delete account' modal");
  };

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <section>
      <Card
        footer={
          data?.viewer.user?.accountDeletionEnabled && (
            <Button onClick={onOpen} buttonColor="action" variant="primary">
              <FormattedMessage
                id="55014249-9a8f-4721-8207-6c45130fa7af"
                defaultMessage="Delete account"
              />
            </Button>
          )
        }
      >
        <CardHeading level="h3" headingStyle="headingFive">
          <FormattedMessage
            id="c8aefaf9-7d78-47a5-a282-70769d204d6e"
            defaultMessage="Account deletion"
          />
        </CardHeading>
        <CardText>
          {data?.viewer.user?.accountDeletionEnabled ? (
            <FormattedMessage
              id="6dc0fe2a-d9be-43dc-9a56-ee5c9a658c4d"
              defaultMessage="Permanently delete your Proof account and all documents associated with it."
            />
          ) : (
            <FormattedMessage
              id="6dc0fe2a-d9be-43dc-9a56-ee5c9a658c4d"
              defaultMessage="You can request that your account is deleted by filling out <link>this form</link> for our Privacy Team."
              values={{
                link: (text) => <Link href={PRIVACY_REQUEST_URL}>{text}</Link>,
              }}
            />
          )}
        </CardText>
        {modalOpen && <AccountDeletionModal onClose={onClose} />}
      </Card>
    </section>
  );
}
