import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { Observable } from "rxjs";
import classnames from "classnames";
import { type ComponentProps, type ReactNode } from "react";

import { CompletionRequirement, type ProcessingStates } from "graphql_globals";
import DocumentUploadHandler, {
  type DocumentUploaderHandlerRenderProps,
} from "common/document/multidoc_uploader/document_upload_handler";
import MultiUploader, {
  type UploadedDocument,
} from "common/document/multidoc_uploader/multi_uploader";
import { useMobileScreenClass } from "common/core/responsive";
import { useIsAuthenticated } from "common/authentication";
import type {
  Branding_viewer_referralInfo_organizationBrand as OrganizationBrand,
  Branding_viewer_referralInfo_publicOrganization as Organization,
} from "common/account/guest_signup/branding_query.graphql";
import { NotarizeNetwork } from "common/core/logo/notarize_network";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import TosV2 from "common/tos";
import { AVAILABLE_FEATURES } from "constants/organization";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";
import {
  SplitLayoutHeading,
  SplitLayoutSubheading,
  SplitLayoutSteps,
  SplitLayoutActionButton,
} from "../shared/split_layout";
import { DocsProvidedQRCode, QRBlock } from "../mobile_web_qr_code";

const MESSAGES = defineMessages({
  tosActionText: {
    id: "a7c6e3b9-5f5b-4e2a-8f6a-2d3a1c7a5b3a",
    defaultMessage: "By adding a document and clicking 'Continue',",
  },
  headerNotaryDocsProvided: {
    id: "7930ae2e-26e2-4e95-90e4-18677fb243c1",
    defaultMessage: "Connect with an online notary, now.",
  },
  headerNotary: {
    id: "71143243-7811-4da0-bb69-38035270a6d3",
    defaultMessage: "Upload your documents",
  },
  headerEsignDocsProvided: {
    id: "c329c166-a63a-45dd-86d3-abde509f0f92",
    defaultMessage: "eSign your document",
  },
  headerEsign: {
    id: "a7fe53a8-91b3-435f-911b-35851ada951f",
    defaultMessage: "Upload documents for eSign",
  },
  headerNSTNotary: {
    id: "a8a1daa0-cd34-4fe8-9d16-24055449b7c0",
    defaultMessage: "Connect with a notary at {orgName}",
  },
});

type Props = {
  uploadStrategy: (file: File) => Observable<
    {
      id: string;
      name: string;
      status: ProcessingStates;
      mimeType: string;
      processingError?: string;
      classification?: { category: string | null; languages: string[] } | null;
      metadata: { pagesTotal: number | null } | null;
    }[]
  >;
  completeStrategy: (
    documents: { id: string; name: string; bundlePosition: number }[],
  ) => Promise<unknown>;
  completionRequirement?: CompletionRequirement;
  paymentCoveringOrgName?: string;
  organizationBrand: OrganizationBrand | null;
  orgFeatureFlags: Organization["featureFlags"];
  children?: ReactNode;
  signerPrice?: number | null;
  analyticsPrefix?: string;
  providedDocuments?: { url: string }[];
  onProvidedDocumentsContinue?: () => void;
  onProvidedDocumentsPreview?: () => void;
  isRetail?: boolean;
  onDocumentDeleteCb?: (doc: UploadedDocument) => void;
  showIneligibleWarning?: boolean;
  forNst: boolean;
  qrStream?: string | null;
};

export default function GuestUpload(props: Props) {
  const {
    uploadStrategy,
    completeStrategy,
    completionRequirement = CompletionRequirement.NOTARIZATION,
    paymentCoveringOrgName,
    organizationBrand,
    orgFeatureFlags = [],
    children,
    signerPrice,
    analyticsPrefix,
    providedDocuments,
    onProvidedDocumentsContinue,
    onProvidedDocumentsPreview,
    forNst,
    isRetail,
    onDocumentDeleteCb,
    showIneligibleWarning,
    qrStream,
  } = props;
  const isNotarization = completionRequirement === CompletionRequirement.NOTARIZATION;
  const isMobile = useMobileScreenClass();
  const isAuthenticated =
    useIsAuthenticated() && !(organizationBrand?.header && organizationBrand.organizationLogoUrl);
  const signerPriceString = signerPrice ? `$${(signerPrice / 100).toFixed(2)}` : null;
  const hasDocsProvided = providedDocuments && providedDocuments.length > 0;

  const organizationFeatures = orgFeatureFlags.filter((f) => f.value === "true").map((f) => f.key);
  const docsProvidedPreviewCopyExptEnabled =
    hasDocsProvided &&
    organizationFeatures.includes(AVAILABLE_FEATURES.DOCS_PROVIDED_EASYLINK_EXPT);

  const steps: ComponentProps<typeof SplitLayoutSteps>["steps"] = [];
  if (!hasDocsProvided) {
    steps.push({
      heading: (
        <FormattedMessage
          id="215335be-578d-4b65-88ee-4c70b2abed58"
          defaultMessage="Your document - PDF, DOCX, or a scanned file."
        />
      ),
    });
  }

  if (isNotarization) {
    steps.push({
      heading: (
        <FormattedMessage
          id="5d7c414d-8f7c-4855-897d-ceebc7574888"
          defaultMessage="Your ID - A valid, unexpired photo ID."
        />
      ),
    });
  }

  steps.push(
    {
      heading: (
        <FormattedMessage
          id="87499811-e9bc-492d-a911-82cf69226f40"
          defaultMessage="Your device - A WiFi-enabled device with a camera."
        />
      ),
    },
    {
      heading: (
        <FormattedMessage
          id="651031c5-8fac-4b53-8262-32341c5b8d8f"
          defaultMessage="Your email - For confirmation and updates."
        />
      ),
    },
  );

  const retailPrice = isNotarization ? "$25" : "$4";
  const subHeading = (
    <>
      {/* Action description */}
      {isNotarization && !forNst ? (
        <FormattedMessage
          id="6a80eca4-465e-4b3f-bab4-e7be977c3815"
          defaultMessage="With Proof's {notarizeNetworkImage}, connect with an online notary 24/7 and complete your notarization in minutes"
          values={{
            notarizeNetworkImage: <NotarizeNetwork />,
          }}
        />
      ) : isNotarization && forNst ? (
        <FormattedMessage
          id="6fd8897c-36f4-4158-b602-85fccc23a8df"
          defaultMessage="Connect with a notary at {orgName}, to get your document notarized by the right professional"
          values={{ orgName: organizationBrand?.organizationName }}
        />
      ) : (
        <FormattedMessage
          id="09573f07-8c13-4294-8f22-60b307a76b78"
          defaultMessage="Adopt an electronic signature and apply it to your documents"
        />
      )}
      {/* Payment description */}
      {paymentCoveringOrgName ? (
        <FormattedMessage
          id="f83621e3-cd4e-416b-a0bf-f5994f35a219"
          defaultMessage=". {coveringOrgName} will cover the cost of this transaction - there will be no cost to you."
          values={{
            coveringOrgName: paymentCoveringOrgName,
          }}
        />
      ) : !!signerPriceString || isRetail ? (
        <FormattedMessage
          id="9dcfd67b-ab16-4dc8-a97c-36b3e9dab1df"
          defaultMessage=". Price starts at {signerPriceString}."
          values={{
            signerPriceString: signerPriceString || retailPrice,
          }}
        />
      ) : (
        "."
      )}
    </>
  );

  return (
    <>
      <div
        className={classnames(Styles.multiUpload, {
          [Styles.authenticatedWrapper]: isAuthenticated,
        })}
      >
        <DocumentUploadHandler
          uploadStrategy={uploadStrategy}
          cacheUploadedDocuments={!hasDocsProvided}
        >
          {({
            uploadedDocuments$,
            onSelectFiles,
            onDocumentDelete,
          }: DocumentUploaderHandlerRenderProps) => {
            return (
              <>
                <div className={Styles.top} data-growth-id="upload-container">
                  <div className={Styles.innerTop}>
                    <div className={Styles.column}>
                      <SplitLayoutHeading data-growth-id="upload-heading">
                        {UploadHeading({
                          isNotarization,
                          hasDocsProvided,
                          forNst,
                          orgName: organizationBrand?.organizationName,
                        })}
                      </SplitLayoutHeading>

                      <SplitLayoutSubheading data-growth-id="upload-subheading">
                        {subHeading}
                      </SplitLayoutSubheading>

                      <div data-growth-id="notarization-steps">
                        <Heading level="h3" textStyle="headingSix">
                          <FormattedMessage
                            id="d2d15299-7a14-4746-b0e2-46def342079f"
                            defaultMessage="What you'll need:"
                          />
                        </Heading>
                        <SplitLayoutSteps steps={steps} clickable={false} />

                        {isMobile && !hasDocsProvided && <Tos />}

                        {!isMobile && hasDocsProvided && onProvidedDocumentsContinue && (
                          <SplitLayoutActionButton
                            onClick={onProvidedDocumentsContinue}
                            automationId="guest-upload-action-button"
                          >
                            {isNotarization ? (
                              docsProvidedPreviewCopyExptEnabled ? (
                                <FormattedMessage
                                  id="2967f53a-9952-446c-aff3-a329179a4e87"
                                  defaultMessage="Review and edit"
                                />
                              ) : (
                                <FormattedMessage
                                  id="a81d1146-18b9-48ba-a22b-11026d4ed793"
                                  defaultMessage="Notarize now"
                                />
                              )
                            ) : (
                              <FormattedMessage
                                id="50e52c50-9eb9-4fcc-8fbf-4b3f7deb1d8c"
                                defaultMessage="Sign now"
                              />
                            )}
                          </SplitLayoutActionButton>
                        )}
                        {completionRequirement !== CompletionRequirement.PROOFING &&
                          (hasDocsProvided ? (
                            <DocsProvidedQRCode
                              completionRequirement={completionRequirement}
                              qrStream={qrStream}
                            />
                          ) : (
                            <QRBlock
                              completionRequirement={completionRequirement}
                              qrStream={qrStream}
                            />
                          ))}
                      </div>
                    </div>

                    {!isMobile &&
                      (hasDocsProvided ? (
                        <div className={Styles.column}>
                          <div className={Styles.previewContainer}>
                            <PDFViewer
                              className={Styles.previewViewer}
                              url={providedDocuments[0].url || ""}
                            />
                            <div className={Styles.previewButton}>
                              <ButtonStyledLink
                                onClick={() => {
                                  segmentTrack(
                                    SEGMENT_EVENTS.GUEST_UPLOAD_LOGIN_PREVIEW_PREVIEW_CLICK,
                                  );
                                  onProvidedDocumentsPreview?.();
                                }}
                                variant="tertiary"
                                buttonSize="condensed"
                                buttonColor="action"
                              >
                                <FormattedMessage
                                  id="e8b4d526-173b-474e-901b-dcb646232e0e"
                                  defaultMessage="Preview document"
                                />
                              </ButtonStyledLink>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.column} data-growth-id="uploader-desktop">
                          <MultiUploader
                            aria-label="Document uploader"
                            onSelectFiles={onSelectFiles}
                            onDocumentDelete={(doc) => {
                              if (onDocumentDeleteCb) {
                                onDocumentDeleteCb(doc);
                              }
                              onDocumentDelete(doc);
                            }}
                            uploadedDocuments$={uploadedDocuments$}
                            completeStrategy={completeStrategy}
                            enableScanning
                            rowsClassName={Styles.uploadRows}
                            rowsWrapperClassName={Styles.uploadRowsWrapper}
                            continueButtonClassName={Styles.uploadContinueButton}
                            analyticsPrefix={analyticsPrefix}
                            showIneligibleWarning={showIneligibleWarning}
                          />
                          <Tos />
                        </div>
                      ))}

                    {children}
                  </div>
                </div>
                {isMobile && (
                  <div className={Styles.bottom} data-growth-id="uploader-mobile">
                    <MultiUploader
                      aria-label="Document uploader"
                      onSelectFiles={onSelectFiles}
                      onDocumentDelete={(doc) => {
                        if (onDocumentDeleteCb) {
                          onDocumentDeleteCb(doc);
                        }
                        onDocumentDelete(doc);
                      }}
                      uploadedDocuments$={uploadedDocuments$}
                      completeStrategy={completeStrategy}
                      rowsClassName={Styles.uploadRowsMobile}
                      enableScanning
                      isAddButtonFixedMobile
                      completionRequirement={completionRequirement}
                      hasDocsProvided={hasDocsProvided}
                      onProvidedDocumentsContinue={onProvidedDocumentsContinue}
                      onProvidedDocumentsPreview={onProvidedDocumentsPreview}
                      showIneligibleWarning={showIneligibleWarning}
                    />
                  </div>
                )}
              </>
            );
          }}
        </DocumentUploadHandler>
      </div>
    </>
  );
}

function Tos() {
  const intl = useIntl();
  return (
    <TosV2
      textColor="subtle"
      size="small"
      automationId="guest-signup-terms-of-service"
      className={Styles.tos}
      underlined
      actionText={intl.formatMessage(MESSAGES.tosActionText)}
    />
  );
}

function UploadHeading({
  isNotarization,
  hasDocsProvided,
  forNst,
  orgName,
}: {
  isNotarization: boolean;
  hasDocsProvided?: boolean;
  forNst: boolean;
  orgName: string | undefined;
}) {
  const intl = useIntl();
  let heading;

  if (forNst) {
    heading = MESSAGES.headerNSTNotary;
  } else if (isNotarization) {
    heading = hasDocsProvided ? MESSAGES.headerNotaryDocsProvided : MESSAGES.headerNotary;
  } else {
    heading = hasDocsProvided ? MESSAGES.headerEsignDocsProvided : MESSAGES.headerEsign;
  }
  return intl.formatMessage(heading, { orgName });
}
