import { FormattedMessage, useIntl } from "react-intl";

import { Payer } from "graphql_globals";
import { useQuery } from "util/graphql";
import { useDocumentTitles } from "util/document_title";
import LoadingIndicator from "common/core/loading_indicator";
import { Card } from "common/core/card";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { usePermissions } from "common/core/current_user_role";
import { SettingsTitle } from "common/settingsv2/common";
import { useActiveOrganization } from "common/account/active_organization";

import ContactDetails from "../billing_contact/contact";
import { PaymentMethod } from "../payment_settings/payment";
import BillingSettingsQuery, {
  type BillingSettings_node_Organization as Organization,
} from "../billing_query.graphql";
import { ManagedBillingCard } from "../common/managed_billing_card";
import Styles from "../payer_settings/paid_by/index.module.scss";

export const BILLING_SETTINGS_ROUTE = "billing-settings";

export function BillingSettingsTab() {
  return (
    <Tab to={BILLING_SETTINGS_ROUTE}>
      <FormattedMessage
        id="15f75dfe-3786-4f7a-93b2-4234dd549a2e"
        defaultMessage="Billing Settings"
      />
    </Tab>
  );
}

export function BillingSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingPaymentSettings),
  });

  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BillingSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const { hasPermissionFor } = usePermissions();

  if (loading) {
    return <LoadingIndicator />;
  }
  const organization = data?.node as Organization | null;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }

  const { defaultPaymentSource, defaultPayer, validCard, managedBilling } = organization;
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="b814b200-3f6b-4eda-bc04-72b7ef7b42de"
          defaultMessage="Billing Settings"
        />
      </SettingsTitle>
      {managedBilling ? (
        <ManagedBillingCard headingText="Billing" subtext="billing" />
      ) : (
        <>
          <Card>
            {defaultPayer !== Payer.NOTARIZE ? (
              <PaymentMethod
                defaultPaymentSource={defaultPaymentSource}
                defaultPayer={defaultPayer}
                validCard={validCard!}
                instructions={
                  <FormattedMessage
                    id="7476a53d-bf1b-47e4-9a80-98df19e50b22"
                    defaultMessage="This payment method will be used for the platform and transaction fees paid to Proof."
                  />
                }
              />
            ) : (
              <p className={Styles.info}>
                <FormattedMessage
                  id="b469803b-73a6-4eb6-bf03-d85380592a69"
                  defaultMessage="Your account is set to pay by invoice. Please reach out to your Customer Success Manager if you need to change this."
                />
              </p>
            )}
          </Card>
          <ContactDetails
            organization={organization}
            admin={hasPermissionFor("editOrganizationDetails")}
          />
        </>
      )}
    </>
  );
}
