import { useState, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import Button from "common/core/button";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import { DeprecatedRadioGroupContainer } from "common/form/inputs/radio/radio_group_container";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardText, CardSection, CardHeading } from "common/core/card";
import { Payer } from "graphql_globals";
import { useMutation } from "util/graphql";
import { useId } from "util/html";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { ErrorMessage } from "common/settingsv2/common";
import { isNotaryNST, isNotaryODN } from "common/notary/capacity";
import { Paragraph } from "common/core/typography";

import UpdateOrganizationDefaultPayerMutation from "./update_organization_defaults.graphql";
import type {
  BillingSettings_node_Organization as Organization,
  BillingSettings_viewer_user_notaryProfile as NotaryProfile,
} from "../../billing_query.graphql";
import Styles from "./index.module.scss";
import { PaymentMethod } from "../../payment_settings/payment";

type Props = {
  organization: Organization;
  notaryProfile?: null | NotaryProfile;
};

const payoutsettingsLink = (msg: ReactNode[]) => (
  <Link to="/settings/notary/profile/PayoutOptions">{msg}</Link>
);

const MESSAGES = defineMessages({
  paymentSettingsSuccess: {
    id: "05d0a62b-2c10-40c2-94a3-04da1b5d9c0a",
    defaultMessage: "Payment settings successfully updated",
  },
  errorMessage: {
    id: "776db9ff-675a-4935-bfa7-7355b88433d4",
    defaultMessage: "You must provide a payment method for charging this account.",
  },
  errorMessageWithBillingLink: {
    id: "b39eb53a-d855-409b-a344-6996de580b7a",
    defaultMessage:
      "You must provide a payment method for charging this account. Please head to {link} to add one.",
  },
  chargeRecipientsLabel: {
    id: "138a3423-20e7-4a51-be0d-b3cd4257a067",
    defaultMessage: "Charge recipients",
  },
  chargeAccountLabel: {
    id: "62e293ab-9067-4725-875b-970cac3e5f1c",
    defaultMessage: "Charge this account",
  },
});

export function PaidBy({ organization, notaryProfile }: Props) {
  const {
    defaultPayer,
    id,
    defaultPaymentSource,
    validCard,
    paymentSpecified,
    chargeablePaymentMethod,
    companyBillingAndPricingEnabled,
  } = organization;
  const isNSTNotary = isNotaryNST(notaryProfile || null);
  const isODNNotary = isNotaryODN(notaryProfile || null);

  const [status, setStatus] = useState<"error" | "success" | null>(null);
  const whoPaysId = useId();

  const [payer, setPayer] = useState(paymentSpecified ? defaultPayer : null);
  const [payerUpdating, setPayerUpdating] = useState(false);
  const intl = useIntl();

  const updateOrgDefaultPayer = useMutation(UpdateOrganizationDefaultPayerMutation);

  async function updateDefaultPayer() {
    setPayerUpdating(true);
    try {
      await updateOrgDefaultPayer({
        variables: {
          input: {
            id,
            defaultPayer: payer,
          },
        },
      });
      setStatus("success");
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.paymentSettingsSuccess),
      });
    } catch {
      setStatus("error");
    } finally {
      setPayerUpdating(false);
    }
  }

  return isNSTNotary ? (
    <>
      <h4 className={Styles.subheader}>
        <FormattedMessage
          id="f344d2b7-72d2-4eb6-9f01-e57c0116600c"
          defaultMessage="Sending transactions to signers"
        />
      </h4>
      <Card>
        <h5 className={Styles.cardSubheader}>
          <FormattedMessage
            id="50946a3b-9b29-4ecc-a271-ccf19403def7"
            defaultMessage="Payment Information"
          />
        </h5>
        <Paragraph>
          <FormattedMessage
            id="482fe33f-51d1-4adf-9ff0-a1c764313a1b"
            defaultMessage="To send transactions to signer, add a payment method for platform and transaction fees paid to Proof on the <link>Payment information settings</link>."
            values={{ link: payoutsettingsLink }}
          />
        </Paragraph>
      </Card>
      {isODNNotary && (
        <h4 className={Styles.subheader}>
          <FormattedMessage
            id="42b59578-5185-4a80-9be6-9ca88398d9a8"
            defaultMessage="On-demand queue transactions"
          />
        </h4>
      )}
      <Card>
        <h5 className={Styles.cardSubheader}>
          <FormattedMessage
            id="6796c7e1-0034-4de3-b514-d9f8d00e8e25"
            defaultMessage="Payout Information"
          />
        </h5>
        {isODNNotary ? (
          <FormattedMessage
            id="80558761-52b3-411c-b53b-641fabae163a"
            defaultMessage="Proof partners with Stripe Connect for fast and secure payouts. To collect payouts for completing on-demand queue meetings, add a payout account on the <link>Payment information settings</link>."
            tagName="p"
            values={{ link: payoutsettingsLink }}
          />
        ) : (
          <FormattedMessage
            id="12ec81a4-a7be-48a3-a038-aa1cb4685e1c"
            defaultMessage="Proof partners with Stripe Connect for fast, secure payouts. To collect signer payments via Proof, you must have a Stripe payout account. Add or update your payout account on the <link>Payment Information screen</link>."
            tagName="p"
            values={{ link: payoutsettingsLink }}
          />
        )}
      </Card>
    </>
  ) : (
    <>
      {defaultPayer === Payer.NOTARIZE && companyBillingAndPricingEnabled ? (
        <></>
      ) : (
        <Card
          footer={
            defaultPayer !== Payer.NOTARIZE && (
              <>
                <Button
                  automationId="account-payer-button"
                  onClick={updateDefaultPayer}
                  isLoading={payerUpdating}
                  disabled={!payer || (payer === Payer.ORGANIZATION && !validCard!)}
                  buttonColor="action"
                  variant="primary"
                >
                  <FormattedMessage
                    id="ad648c73-dc32-417b-bd29-55ab28641b0f"
                    defaultMessage="Save Changes"
                  />
                </Button>
              </>
            )
          }
        >
          {defaultPayer !== Payer.NOTARIZE ? (
            <>
              <CardHeading level="h3">
                <FormattedMessage
                  id="b7c7720b-4851-4be7-96ee-035a5894485d"
                  defaultMessage="Transaction Paid By"
                />
              </CardHeading>
              <p className={Styles.info}>
                <FormattedMessage
                  id="b469803b-73a6-4eb6-bf03-d85380592a69"
                  defaultMessage="If recipients are selected, signers are prompted for payment information when they complete each transaction."
                />
              </p>
              <CardText>
                <p id={whoPaysId}>
                  <FormattedMessage
                    id="73d5ee6b-097c-474a-9ab2-03a8cee52279"
                    defaultMessage="Select who pays for documents sent to others."
                  />
                </p>
              </CardText>
              <CardSection>
                <DeprecatedRadioGroupContainer className={Styles.radioContainer}>
                  <DeprecatedRadioButton
                    aria-describedby={whoPaysId}
                    radioValue={Payer.ORGANIZATION}
                    labelText={intl.formatMessage(MESSAGES.chargeAccountLabel)}
                    onChange={setPayer}
                    groupValue={payer}
                    className={Styles.radio}
                  />
                  <DeprecatedRadioButton
                    aria-describedby={whoPaysId}
                    radioValue={Payer.CUSTOMER}
                    labelText={intl.formatMessage(MESSAGES.chargeRecipientsLabel)}
                    onChange={setPayer}
                    groupValue={payer}
                    className={Styles.radio}
                  />
                </DeprecatedRadioGroupContainer>
              </CardSection>
              {!companyBillingAndPricingEnabled && (
                <PaymentMethod
                  defaultPaymentSource={defaultPaymentSource}
                  defaultPayer={defaultPayer}
                  validCard={validCard!}
                  instructions={
                    <FormattedMessage
                      id="7476a53d-bf1b-47e4-9a80-98df19e50b22"
                      defaultMessage="This payment method will be used for the platform and transaction fees paid to Proof."
                    />
                  }
                />
              )}
            </>
          ) : (
            <p className={Styles.info}>
              <FormattedMessage
                id="b469803b-73a6-4eb6-bf03-d85380592a69"
                defaultMessage="Your account is set to pay by invoice. Please reach out to your Customer Success Manager if you need to change this."
              />
            </p>
          )}
          {payer === Payer.ORGANIZATION && !chargeablePaymentMethod && (
            <ErrorMessage>
              {!companyBillingAndPricingEnabled ? (
                intl.formatMessage(MESSAGES.errorMessage)
              ) : (
                <FormattedMessage
                  id="71e0e120-7626-4983-b1b5-efb75b3fda6b"
                  defaultMessage="You must provide a payment method for charging this account. Please head to {link} to add one."
                  values={{
                    link: (
                      <Link to="/settings/billing/billing-settings">
                        <FormattedMessage
                          id="96ded4c0-6c2c-4f1c-b238-dbc9416e7720"
                          defaultMessage="Billing Settings"
                        />
                      </Link>
                    ),
                  }}
                />
              )}
            </ErrorMessage>
          )}
          {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
        </Card>
      )}
    </>
  );
}
