import { type ReactElement, useState } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useLogout } from "common/authentication";
import { pushNotification } from "common/core/notification_center/actions";

type Props = {
  children?: ReactElement;
  viewer: { user: { id: string; email: string | null } | null; limitedSession: boolean };
};

export default function LimitedSessionWrapper({ viewer, children }: Props) {
  const bundleMatch = useMatch({ path: "/bundle/:documentBundleId", end: false });
  const dashboardMatch = useMatch({ path: "/bundle/records", end: false });
  const meetingMatch = useMatch({ path: "/meeting/:meetingId", end: false });
  const esignMatch = useMatch({ path: "/esign/:globalID", end: false });
  const refereeMatch = useMatch({ path: "referee-meeting/:meetingId", end: false });
  const email = viewer.user?.email;
  const { pathname, search } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout({
    redirectUrl: `${pathname}${search || "?"}${email ? `&passwordless_email=${encodeURIComponent(email)}` : ""}`,
  });

  const onCreate = async () => {
    setLoading(true);
    if (email) {
      pushNotification({
        message: (
          <FormattedMessage
            id="89836457-d138-41e5-97ea-dabff66d7807"
            defaultMessage="Check your email to set a password."
          />
        ),
      });
    }
    await logout();
    setLoading(false);
  };
  if (
    viewer.limitedSession &&
    !(meetingMatch || (bundleMatch && !dashboardMatch) || esignMatch || refereeMatch)
  ) {
    return (
      <WorkflowModal
        title={
          <FormattedMessage
            id="ac962e44-6127-4a33-a3b8-461c2117640a"
            defaultMessage="You can't access this page without a password"
          />
        }
        footerSeparator={false}
        buttons={[
          <Button
            buttonColor="action"
            variant="secondary"
            key="cancel"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="9b6a5817-f2c5-446f-98e0-9b08176fc25f" defaultMessage="Cancel" />
          </Button>,
          <Button
            buttonColor="action"
            variant="primary"
            key="link"
            onClick={onCreate}
            isLoading={loading}
            disabled={loading}
          >
            <FormattedMessage
              id="30aa8b69-6c6b-4538-8ad9-96d37c1282d4"
              defaultMessage="Create password"
            />
          </Button>,
        ]}
        autoFocus
        isSensitive={false}
      >
        <FormattedMessage
          id="2c80569d-a998-4403-90ce-b77f465d52b7"
          defaultMessage="To continue verify your email and set a password."
          tagName="p"
        />
      </WorkflowModal>
    );
  }

  return children || <></>;
}
