import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { Card, CardHeading } from "common/core/card";

import { AcceptedTouList } from "./applicable_tou";
import Styles from "./index.module.scss";

type Props = {
  applicableTou: ComponentProps<typeof AcceptedTouList>["applicableTou"];
};

function AcceptedTerms(props: Props) {
  return (
    <Card
      className={Styles.card}
      header={
        <CardHeading level="h3">
          <FormattedMessage
            id="d39173a5-4eec-478e-980e-25f9668dd356"
            defaultMessage="Accepted Terms"
          />
        </CardHeading>
      }
    >
      <div className={Styles.listItem}>
        <AcceptedTouList applicableTou={props.applicableTou} />
      </div>
    </Card>
  );
}

export default memo(AcceptedTerms);
