import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import CustomerProfileQuery from "common/settingsv2/sidebar_settings/profile/customer_profile_query.graphql";
import { AccountDeletion } from "common/settingsv2/sidebar_settings/profile/privacy/account_deletion";

import Styles from "../index.module.scss";

export default function Privacy() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalDetails),
  });
  const { data, loading } = useQuery(CustomerProfileQuery, {
    variables: { chargeStatuses: undefined },
  });
  const user = data?.viewer.user;

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage id="597039dc-b55a-419b-b77f-8243995559f6" defaultMessage="Privacy" />
        </Heading>
      </div>
      <div className={Styles.body}>
        {loading || !user ? <LoadingIndicator /> : <AccountDeletion />}
      </div>
    </>
  );
}
