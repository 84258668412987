import { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { validateEmailFormat } from "validators/account";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedTextField } from "common/form/fields/text";
import { composeValidators } from "util/form";
import compose from "util/compose";
import SaveButton from "common/core/save_button";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { Paragraph } from "common/core/typography";

export type FormValues = {
  inboxEmail: string;
};

type Props = {
  submitting: boolean;
  onEmailSubmit: (values: FormValues) => void;
  inboxEmail: string;
};

type FormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & FormProps;

const MESSAGES = defineMessages({
  emailPlaceholder: {
    id: "d5621515-9f9b-483f-8a06-7034eefefc2a",
    defaultMessage: "Email address",
  },
});

function SharedInboxEmailForm({
  handleSubmit,
  onEmailSubmit,
  submitting,
  invalid,
  initialize,
  inboxEmail,
}: InnerProps) {
  const intl = useIntl();

  useEffect(() => {
    initialize({
      inboxEmail,
    });
  }, [inboxEmail]);

  return (
    <>
      <form onSubmit={handleSubmit(onEmailSubmit)}>
        <Card
          footer={
            <SaveButton
              disabled={submitting || invalid}
              isLoading={submitting}
              className="is-form"
              title={
                <FormattedMessage id="3cf6d86a-5f5d-4d05-9ce8-0c90f7d52607" defaultMessage="Save" />
              }
            />
          }
        >
          <CardSection>
            <CardHeading level="h3">
              <FormattedMessage
                id="5af0a404-1ec7-452c-8e17-384c8eb2d991"
                defaultMessage="Add a Shared Email Inbox"
              />
            </CardHeading>

            <CardText>
              <Paragraph>
                <FormattedMessage
                  id="8c438f71-b6d5-4c91-989c-a9f9246bce7f"
                  defaultMessage="Add your team's shared inbox to set global notification preferences and increase your organization's discoverability."
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="32ad2f7a-1c08-446a-92e5-bb549c829123"
                  defaultMessage="Your team will access Proof with their own login credentials, and will receive notifications sent to the shared inbox."
                />
              </Paragraph>
            </CardText>
          </CardSection>

          <FormGroup disableFormRowStyle fields={["inboxEmail"]}>
            <DeprecatedTextField
              useStyledInput
              placeholderAsLabel
              name="inboxEmail"
              placeholder={intl.formatMessage(MESSAGES.emailPlaceholder)}
              data-automation-id="inbox-email-field"
            />
            <FormGroupErrors fields={["inboxEmail"]} />
          </FormGroup>
        </Card>
      </form>
    </>
  );
}

export default compose(
  reduxForm<FormValues, Props>({
    form: "SharedInboxEmailForm",
    validate: composeValidators(validateEmailFormat({ field: "inboxEmail", label: "Email" })),
  }),
)(SharedInboxEmailForm);
