import { useEffect, useRef, useState } from "react";
import type ScanbotInstance from "scanbot-web-sdk";
import { type DocumentDetectionStatus } from "scanbot-web-sdk/@types";
import { defineMessages, useIntl } from "react-intl";

import { grantedPermissions } from "util/permissions";
import { captureException } from "util/exception";
import { isiOSDevice } from "util/support";

const LICENSE_KEY =
  "BNb6HVAFe8GFy2T+KB6R3e+FmqQKWp" +
  "T6JRl7uuBTg6i0M1to5vJhl8pEUcRE" +
  "YQz0PXqY5l/KEbJRWwCg6dY3Yz40Lk" +
  "u+XwwFSYkxBwGK5iWBco+6NIDu2EZ6" +
  "qvjsQ5sdeE0UiabnvHH4+VwZhGIO4S" +
  "GE2oK+Qj5ZzI/hEn4P8eFcHC0baiA3" +
  "yNntHwpnVWKi2X4RY4mXe87PMJj+1m" +
  "4Obbi8meqAZTQiVGHJ/7mhwLy7D4vD" +
  "g26ejM78ZcUYW9GUdnRrmyu1HE8YSW" +
  "edYb4VrBvqIp68EP+VX+ytyQb2JzZ6" +
  "tQ7AwczV3Bb7b1rT6HwGuwBhUgUGvR" +
  "uzIMg3A3eDRg==\nU2NhbmJvdFNESw" +
  "oqcHJvb2YuY29tfCpwcm9vZi5jb2Fj" +
  "aHwqbm90YXJpemUucm9ja3N8bG9jYW" +
  "xob3N0fCpkZXYtbm90YXJpemUuY29t" +
  "CjE3NzExOTk5OTkKNTkwCjg=\n";

function useLazyScanbot() {
  const [instance, setInstance] = useState<ScanbotInstance>();
  useEffect(() => {
    import("scanbot-web-sdk")
      .then((module) => {
        return module.default.initialize({
          licenseKey: LICENSE_KEY,
          enginePath: "/scanbot-sdk-resources/",
        });
      })
      .then(setInstance);
  }, []);
  return instance;
}

export type { DocumentDetectionStatus };

export type DocumentScannerError = "media_permission_error" | "unknown_error";

export type CaptureResult = { imageBuffer: ArrayBuffer; dataUrl: string };

type DocScannerCaptureState =
  | { state: "loading" }
  | { state: "error"; error: DocumentScannerError }
  | { state: "ready" };
type OnCapture = (
  result:
    | ({ success: true } & CaptureResult)
    | { success: false; detectionStatus: DocumentDetectionStatus },
) => void;
export type UseDocScannerCapture = (args: {
  containerId: string;
  onCapture: OnCapture;
}) => DocScannerCaptureState;

type RotateImage = (captureResult: CaptureResult) => Promise<CaptureResult>;
type GeneratePdf = (
  captureResults: CaptureResult[],
) => Promise<{ blob: BlobPart; filename: string }>;

type DocScannerState =
  | { state: "loading" }
  | { state: "error" }
  | {
      state: "ready";
      useDocScannerCapture: UseDocScannerCapture;
      rotateImage: RotateImage;
      generatePdf: GeneratePdf;
    };

const messages = defineMessages({
  OK: {
    id: "190b41af-cb29-439e-ab13-721020f70964",
    defaultMessage: "Capturing your document... Please do not move the camera.",
  },
  OK_SmallSize: {
    id: "665b7e2f-95bb-4c8a-a6d3-ee76c78568d2",
    defaultMessage: "The document is too small. Try moving closer.",
  },
  OK_BadAngles: {
    id: "1dcbc271-d0bc-46cd-8c3c-2ccb4e09357a",
    defaultMessage: "This is a bad camera angle. Hold the device straight over the document.",
  },
  OK_BadAspectRatio: {
    id: "fc0fde8f-72e0-4b95-accd-48e0cbf5c1a0",
    defaultMessage: "Rotate the device sideways, so that the document fits better into the screen.",
  },
  OK_OffCenter: {
    id: "159738ba-c280-4a23-9c0e-954518ffa3b5",
    defaultMessage: "Try holding the device at the center of the document.",
  },
  Error_NothingDetected: {
    id: "02ab81a4-5fb8-4512-8a30-8d50833cef08",
    defaultMessage: "Please hold the device over a document to start scanning.",
  },
  Error_Brightness: {
    id: "ab1aea3a-8a32-497b-9c08-353ff4991af1",
    defaultMessage: "It is too dark. Try turning on a light.",
  },
  Error_Noise: {
    id: "9e556d17-3de5-4095-8f49-22146c8ce859",
    defaultMessage:
      "Place your document on a solid, non-reflective background in a well-lit space.",
  },
});

/**
 * Converts a Uint8Array to an ArrayBuffer, handling both ArrayBuffer and SharedArrayBuffer cases
 * @param typedArray The Uint8Array to process
 * @returns A standard ArrayBuffer containing the data
 */
function processBuffer(typedArray: Uint8Array): ArrayBuffer {
  if (typedArray.buffer instanceof ArrayBuffer) {
    return typedArray.buffer;
  }

  const newBuffer = new ArrayBuffer(typedArray.byteLength);
  new Uint8Array(newBuffer).set(typedArray);
  return newBuffer;
}

export function useDocScanner() {
  const scanbotInstance = useLazyScanbot();
  const [scannerState, setScannerState] = useState<DocScannerState>({ state: "loading" });

  useEffect(() => {
    if (scanbotInstance) {
      scanbotInstance
        .getLicenseInfo()
        .then((licenseInfo) => {
          if (!licenseInfo.isValid()) {
            setScannerState({ state: "error" });
            return;
          }

          const useDocScannerCapture = ({
            containerId,
            onCapture,
          }: {
            containerId: string;
            onCapture: OnCapture;
          }) => {
            const intl = useIntl();
            const scannerHandle = useRef<{ dispose: () => void }>();
            const [captureState, setCaptureState] = useState<DocScannerCaptureState>({
              state: "loading",
            });

            const checkGrantedPermissions = () => {
              grantedPermissions().then((permissions) => {
                const { webcam } = permissions;
                if (!webcam) {
                  setCaptureState({ state: "error", error: "media_permission_error" });
                }
              });
            };

            // permissions.query is not available on all versions of Safari
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (navigator?.permissions?.query) {
              navigator.permissions
                .query({ name: "camera" as unknown as PermissionName })
                .then((status) => {
                  // onchange not (yet) available on safari so can only rely on rerender when shutter button clicked
                  if (isiOSDevice()) {
                    if (status.state !== "granted") {
                      setCaptureState({ state: "error", error: "media_permission_error" });
                    }
                  } else {
                    status.onchange = () => {
                      if (status.state !== "granted") {
                        setCaptureState({ state: "error", error: "media_permission_error" });
                      }
                    };
                  }
                })
                .catch(() => {
                  checkGrantedPermissions();
                });
            } else {
              checkGrantedPermissions();
            }

            useEffect(() => {
              scanbotInstance
                .createDocumentScanner({
                  onError: captureException,
                  containerId,
                  autoCaptureEnabled: true,
                  async onDocumentDetected({ croppedImage, status }) {
                    if (!croppedImage || status !== "OK") {
                      return;
                    }
                    scanbotInstance.utils.flash();
                    const imageBuffer = await scanbotInstance
                      .imageToJpeg(croppedImage)
                      .then(processBuffer);

                    onCapture({
                      success: true,
                      imageBuffer,
                      dataUrl: await scanbotInstance.toDataUrl(imageBuffer),
                    });
                  },
                  text: {
                    hint: {
                      OK: intl.formatMessage(messages.OK),
                      OK_BUT_TOO_SMALL: intl.formatMessage(messages.OK_SmallSize),
                      OK_BUT_BAD_ANGLES: intl.formatMessage(messages.OK_BadAngles),
                      OK_BUT_BAD_ASPECT_RATIO: intl.formatMessage(messages.OK_BadAspectRatio),
                      OK_BUT_OFF_CENTER: intl.formatMessage(messages.OK_OffCenter),
                      ERROR_NOTHING_DETECTED: intl.formatMessage(messages.Error_NothingDetected),
                      ERROR_TOO_DARK: intl.formatMessage(messages.Error_Brightness),
                      ERROR_TOO_NOISY: intl.formatMessage(messages.Error_Noise),
                    },
                  },
                })
                .then((handle) => {
                  scannerHandle.current = handle;
                  setCaptureState({ state: "ready" });
                })
                .catch((error: Error) => {
                  if (error.name === "MediaPermissionError") {
                    setCaptureState({ state: "error", error: "media_permission_error" });
                  } else {
                    setCaptureState({ state: "error", error: "unknown_error" });
                  }
                  captureException(error);
                });

              return () => {
                scannerHandle.current?.dispose();
              };
            }, []);

            return captureState;
          };

          const rotateImage: RotateImage = async (captureResult) => {
            const rotatedImageBuffer = await scanbotInstance
              .imageRotate(captureResult.imageBuffer, "CLOCKWISE_90")
              .then((rotated) => scanbotInstance.imageToJpeg(rotated))
              .then(processBuffer);

            return {
              imageBuffer: rotatedImageBuffer,
              dataUrl: await scanbotInstance.toDataUrl(rotatedImageBuffer),
            };
          };

          const generatePdf: GeneratePdf = async (captureResults) => {
            const pdfGenerator = await scanbotInstance.beginPdf({ pageSize: "LETTER" });
            await Promise.all(
              captureResults.map(async (captureResult) => {
                await pdfGenerator.addPage(captureResult.imageBuffer);
              }),
            );
            return {
              blob: await pdfGenerator.complete(),
              filename: `scanned ${new Date().toLocaleString()}.pdf`,
            };
          };

          setScannerState({
            state: "ready",
            useDocScannerCapture,
            rotateImage,
            generatePdf,
          });
        })
        .catch((error) => {
          captureException(error);
          setScannerState({ state: "error" });
        });
    }
  }, [scanbotInstance]);

  return scannerState;
}
