import type { ReactNode } from "react";

import Icon from "common/core/icon";
import Link from "common/core/link";
import ProgressBar from "common/core/progress_bar";
import { CardSection, CardHeading } from "common/core/card";

import Styles from "./progress.module.scss";

type Props = {
  iconName?: string;
  title: ReactNode;
  startLabel: ReactNode;
  endLabel: ReactNode;
  percent: number; // 0 to 1
  linkText?: ReactNode;
  linkTo?: string;
  linkAutomationId?: string;
};

export function SubscriptionPlanProgress({
  iconName,
  title,
  startLabel,
  endLabel,
  percent,
  linkText,
  linkTo,
  linkAutomationId,
}: Props) {
  return (
    <CardSection>
      <div className={Styles.wrapper}>
        <div className={Styles.title}>
          {iconName && <Icon className={Styles.icon} name={iconName} />}
          <CardHeading level="h3">{title}</CardHeading>
        </div>
        {linkTo && (
          <Link underlined={false} to={linkTo} automationId={linkAutomationId}>
            {linkText}
          </Link>
        )}
      </div>
      <ProgressBar total={1} progress={percent} highlightSuccess={false} />
      <div className={Styles.period}>
        <p className={Styles.label}>{startLabel}</p>
        <p className={Styles.label}>{endLabel}</p>
      </div>
    </CardSection>
  );
}
