import { useState, type ReactNode } from "react";
import classnames from "classnames";

import { Heading, Paragraph, Substyle } from "common/core/typography";
import useBrandedStyle from "common/core/brand/org_brand_style";
import Button from "common/core/button";

import Styles from "./index.module.scss";

type SplitLayoutProps = {
  heading: ReactNode;
  pane1: ReactNode;
  pane2: ReactNode;
};
export function SplitLayout(props: SplitLayoutProps) {
  return (
    <div className={Styles.root}>
      {props.heading}

      <div className={Styles.panes}>
        <div>{props.pane1}</div>
        <div>{props.pane2}</div>
      </div>
    </div>
  );
}

export function SplitLayoutHeading(props: { children: ReactNode; "data-growth-id": string }) {
  return (
    <Heading
      className={Styles.heading}
      level="h1"
      textStyle="headingFour"
      data-growth-id={props["data-growth-id"]}
    >
      {props.children}
    </Heading>
  );
}

export function SplitLayoutSubheading(props: { children: ReactNode; "data-growth-id": string }) {
  return (
    <Paragraph className={Styles.subheading} data-growth-id={props["data-growth-id"]}>
      <Substyle textStyle="subtitleSmall">{props.children}</Substyle>
    </Paragraph>
  );
}

type SplitLayoutStepsProps = {
  steps: { heading: ReactNode; info?: ReactNode }[];
  clickable?: boolean;
};

export function SplitLayoutSteps({ steps, clickable = true }: SplitLayoutStepsProps) {
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  return (
    <ol className={Styles.steps}>
      {steps.map((step, index) => (
        <SplitLayoutStep
          key={index}
          heading={step.heading}
          info={step.info}
          isSelected={clickable ? selectedStepIndex === index : false}
          onSelect={clickable ? () => setSelectedStepIndex(index) : undefined}
        />
      ))}
    </ol>
  );
}

function SplitLayoutStep(props: {
  heading: ReactNode;
  info: ReactNode;
  isSelected: boolean;
  onSelect?: () => void;
}) {
  const brandStyles = useBrandedStyle();
  return (
    <li
      style={{ ...brandStyles }}
      className={classnames(Styles.listItem, props.isSelected && Styles.active)}
      onClick={props.onSelect}
    >
      {props.heading}
      {props.isSelected && (
        <Paragraph size="defaultSize" data-growth-id="notarization-step-detail">
          {props.info}
        </Paragraph>
      )}
    </li>
  );
}

export function SplitLayoutActionButton(props: {
  onClick: () => void;
  automationId?: string;
  children: ReactNode;
}) {
  return (
    <Button
      className={Styles.actionButton}
      variant="primary"
      automationId={props.automationId || "split-layout-action-button"}
      buttonColor="action"
      buttonSize="large"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
