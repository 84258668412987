import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  NextStepButton,
  FlexResponsiveWrapper,
  FlexFooter,
  VerticalStepIndicator,
} from "common/signer/common";
import ProofLogoSeal from "assets/images/logos/proof-seal.svg";
import ProofLogo from "assets/images/logos/proof-logo-full.svg";
import Svg from "common/core/svg";
import { useMatchScreenClass } from "common/core/responsive";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./IdentifyAccountRecovery.module.scss";
import type {
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_viewer as Viewer,
} from "../index.query.graphql";
import { LandingWrapper, useHandleReadyToSign } from "./common";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  handleBlockedAction: (action: () => void) => void;
};

type InnerProps = Props & {
  renderExpiredOrInactiveContent: () => ReactNode;
};

function ProofLanding({
  documentBundle,
  viewer,
  renderExpiredOrInactiveContent,
  handleBlockedAction,
}: InnerProps) {
  const handleContinue = useHandleReadyToSign(documentBundle, viewer);
  const isMobile = useMatchScreenClass("xs");
  const expiredOrInactive = renderExpiredOrInactiveContent();

  if (expiredOrInactive) {
    return <>{expiredOrInactive}</>;
  }

  const brandLogo = documentBundle.brandingInfo?.organizationLogoUrl;
  const brandName =
    documentBundle.brandingInfo?.organizationName ||
    documentBundle.organizationTransaction.publicOrganization.organizationBrand.name;

  if (!viewer.user?.email) {
    throw Error("Could not find user's email.");
  }
  return (
    <FlexResponsiveWrapper
      centered={!isMobile}
      fullHeight
      footer={
        <FlexFooter
          nextStepButton={
            <NextStepButton
              automationId="get-started-button"
              onClick={() => handleBlockedAction(handleContinue)}
              text={
                <FormattedMessage
                  id="2e23e694-4550-4766-a424-2772f353ce25"
                  defaultMessage="Begin verification"
                />
              }
            />
          }
        />
      }
    >
      <>
        {brandLogo && (
          <div className={Styles.logosContainer}>
            <img className={Styles.logo} src={brandLogo} alt="" />
            <svg className={Styles.dash} aria-hidden="true">
              <path d="M1.5 1.61133H26.5"></path>
            </svg>
            <Svg className={Styles.logo} src={ProofLogoSeal} alt="" />
          </div>
        )}
        <Heading
          className={Styles.heading}
          level="h1"
          textStyle="headingFour"
          textAlign={isMobile ? "left" : "center"}
        >
          <FormattedMessage
            id="d597c94e-7b0b-4500-b561-d252d2a9c12c"
            defaultMessage="To recover your <b>{email}</b> account, {brandName} requires identity verification with {logo}"
            values={{
              email: viewer.user.email,
              brandName,
              b: (chunks) => <strong>{chunks}</strong>,
              logo: <Svg className={Styles.inlineLogo} src={ProofLogo} alt="Proof logo" />,
            }}
          />
        </Heading>
        <Paragraph className={Styles.subtext}>
          <FormattedMessage
            id="81ec9c87-5008-4b52-96d2-37e057b635b7"
            defaultMessage="To ensure security, we need to collect some information to confirm your identity."
          />
        </Paragraph>
        <Paragraph className={Styles.subtext}>
          {/* TODO: RX-1061 - We want to only show this copy based on the limitedTransactionDetails setting */}
          <FormattedMessage
            id="fcee5627-a1b0-4804-a5f2-44234aa68c82"
            defaultMessage="<b>Proof will not share this information with {brandName}.</b>"
            values={{
              b: (chunks) => <strong>{chunks}</strong>,
              brandName,
            }}
          />
        </Paragraph>

        <VerticalStepIndicator
          className={Styles.stepIndicator}
          numberedSteps
          listItems={[
            {
              item: (
                <FormattedMessage
                  id="d9a14421-bac7-434e-a761-b561a2c3d308"
                  defaultMessage="Verify your information"
                />
              ),
              parentheses: (
                <FormattedMessage
                  id="10d8acbd-e801-4803-a57e-5fefedb0d466"
                  defaultMessage="(2 min)"
                />
              ),
            },
            {
              item: (
                <FormattedMessage
                  id="bea6f9ba-04cf-4012-b877-b3c4ce6b9c4b"
                  defaultMessage="Verify your identity"
                />
              ),
              parentheses: (
                <FormattedMessage
                  id="10d8acbd-e801-4803-a57e-5fefedb0d466"
                  defaultMessage="(5 min)"
                />
              ),
            },
          ]}
        />
      </>
    </FlexResponsiveWrapper>
  );
}

function ProofLandingWrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix="authenticated-esign-landing" {...props}>
      {(renderExpiredOrInactiveContent) => (
        <ProofLanding renderExpiredOrInactiveContent={renderExpiredOrInactiveContent} {...props} />
      )}
    </LandingWrapper>
  );
}

export { ProofLandingWrapper as IdentifyAccountRecoveryLanding };
