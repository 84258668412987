import type { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import LoadingIndicator from "common/core/loading_indicator";
import { Card, CardText } from "common/core/card";
import Tab from "common/core/tabs/tab";
import { useActiveOrganization } from "common/account/active_organization";
import { SettingsTitle } from "common/settingsv2/common";
import { isNotaryNST, isNotaryODN } from "common/notary/capacity";
import { useQuery } from "util/graphql";
import { useDocumentTitles } from "util/document_title";
import PayoutOptionsSection from "common/notary/profile_wizard/section/payout";

import BillingSettingsQuery from "../billing_query.graphql";
import { PaidBy } from "./paid_by";
import { EasylinksPaidBy } from "./easylinks_paid_by";

export const PAYER_SETTINGS_ROUTE = "payer-settings";

export function PayerSettingsTab() {
  return (
    <Tab to={PAYER_SETTINGS_ROUTE}>
      <FormattedMessage id="15f75dfe-3786-4f7a-93b2-4234dd549a2e" defaultMessage="Payer Settings" />
    </Tab>
  );
}

export function DefaultPaymentSettings({
  payerOrPayment,
  extendedSettings,
  showNotaryPaymentSettings,
}: {
  payerOrPayment?: "Payer" | "Payment";
  extendedSettings?: ReactNode;
  showNotaryPaymentSettings?: boolean;
}) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingPaymentSettings),
  });

  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BillingSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  const user = data!.viewer.user!;
  const notaryProfile = user.notaryProfile;
  const isODNNotary = isNotaryODN(notaryProfile);
  const isNSTNotary = isNotaryNST(notaryProfile);
  const isPayerTab = payerOrPayment === "Payer";

  return (
    <>
      <SettingsTitle>
        {isPayerTab ? (
          <FormattedMessage
            id="061a2933-3738-46ec-9bbe-8b75f83b5113"
            defaultMessage="Payer Settings"
          />
        ) : (
          <FormattedMessage
            id="c486e498-5bd1-44f3-aced-d14c0a8b3b60"
            defaultMessage="Payment Settings"
          />
        )}
        {isODNNotary ? (
          <CardText>
            <FormattedMessage
              id="11b42151-dc61-46da-a06f-6a0b9acf43d3"
              defaultMessage="In your state, you must collect signer payments for transactions you create outside of Proof. Signers will not be charged by Proof. Payouts for completing on-demand transactions can be collected via a Stripe Connect account."
            />
          </CardText>
        ) : isNSTNotary ? (
          <CardText>
            <FormattedMessage
              id="d870f8f8-7195-4c8c-a8ef-c5b3514cf1c1"
              defaultMessage="You can collect signer payments via Proof. When signers pay on Proof, the notarization fee is deposited directly into your Stripe Payout account. Alternatively, you can choose to collect signer payments outside of Proof. You must set default fees regardless of how the signer will pay. You can always change the signer fees for individual transactions."
            />
          </CardText>
        ) : null}
      </SettingsTitle>
      {showNotaryPaymentSettings && (isODNNotary || isNSTNotary) ? (
        <Card className="NotaryProfileWizardStep">
          <PayoutOptionsSection user={user} inSettings />
        </Card>
      ) : (
        <>
          <PaidBy notaryProfile={notaryProfile} organization={organization} />
          {extendedSettings}
          <EasylinksPaidBy />
        </>
      )}
    </>
  );
}

export function PayerSettings({
  extendedSettings,
  showNotaryPaymentSettings,
}: {
  extendedSettings?: ReactNode;
  showNotaryPaymentSettings?: boolean;
}) {
  return (
    <DefaultPaymentSettings
      showNotaryPaymentSettings={showNotaryPaymentSettings}
      extendedSettings={extendedSettings}
      payerOrPayment="Payer"
    />
  );
}
