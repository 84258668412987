import { useEffect, useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import Button from "common/core/button";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { Hr } from "common/core/horizontal_rule";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { AuthTypes, OrganizationTypeEnum, Feature } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { useId } from "util/html";

import UpdateOrganizationDefaultsMutation from "./update_organization_defaults_mutation.graphql";
import UpdateDefaultTransactionAuthenticationRequirementMutation from "./update_default_transaction_authentication_requirement_mutation.graphql";
import AuthSecurityQuery, {
  type AuthSecurity_organization_Organization as Organization,
} from "./auth_security_query.graphql";

type Props = {
  organization: Organization;
};
type FormValues = {
  defaultAuthRequirement: AuthTypes;
  secondaryIdRequiredDefault: boolean;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps;

const MESSAGES = defineMessages({
  none: { id: "65f95d39-0379-4a24-9390-046500bbcbb8", defaultMessage: "None" },
  noneDescription: {
    id: "ada8a1d3-2514-4732-9dbf-def2f3da37e7",
    defaultMessage: "The signer can create their account with only email and password",
  },
  sms: { id: "efc856ce-198e-45d8-bdff-d731c7071812", defaultMessage: "SMS authentication" },
  smsDescription: {
    id: "b13cc5cf-fc17-463b-bc48-5960e5060dae",
    defaultMessage:
      "After creating their account, the signer will receive a code by SMS to verify their identity",
  },
  zip: {
    id: "124e9424-f5d3-4f7e-8036-5b1f8a982522",
    defaultMessage: "ZIP Code authentication",
  },
  zipDescription: {
    id: "31005177-5698-417f-9612-eb4916cc4e15",
    defaultMessage:
      "The signer will need to verify their identity by inputting the property's ZIP Code",
  },
  save: { id: "fd29f1e2-3dc6-48ff-a544-3e64dfbdd8b2", defaultMessage: "Save changes" },
  settingsSuccess: {
    id: "aa41c3a1-cb26-4110-8fc8-1099ec821247",
    defaultMessage: "Authentication & security settings successfully updated",
  },
  tooltipTriggerLabel: {
    id: "8d591a8b-43c1-4dc1-a951-d6043752bbe0",
    defaultMessage: "More details about this setting",
  },
});

function LoadedAuthenticationSecurity({
  handleSubmit,
  initialize,
  organization,
  dirty,
}: InnerProps) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<"error" | "success" | null>(null);

  const formId = useId();

  const updateOrganizationDefaults = useMutation(UpdateOrganizationDefaultsMutation);

  const updateDefaultTransactionAuthenticationRequirement = useMutation(
    UpdateDefaultTransactionAuthenticationRequirementMutation,
  );
  const onSubmit = async (values: FormValues) => {
    const { defaultAuthRequirement, secondaryIdRequiredDefault } = values;
    if (dirty) {
      setIsSubmitting(true);
      try {
        await updateDefaultTransactionAuthenticationRequirement({
          variables: {
            input: {
              organizationId: organization.id,
              defaultAuthenticationRequirement: defaultAuthRequirement,
            },
          },
        });
        await updateOrganizationDefaults({
          variables: {
            input: {
              id: organization.id,
              secondaryIdRequiredDefault,
            },
          },
        });
        setStatus("success");
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.settingsSuccess),
        });
        initialize(values);
      } catch {
        setStatus("error");
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    initialize({
      defaultAuthRequirement: organization.defaultAuthenticationRequirement,
      secondaryIdRequiredDefault: organization.secondaryIdRequiredDefault,
    });
  }, []);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      data-automation-id="signer-authentication-security-form"
    >
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            disabled={!dirty}
            isLoading={isSubmitting}
            automationId="save-button"
          >
            {intl.formatMessage(MESSAGES.save)}
          </Button>
        }
      >
        <CardHeading level="h3" headingStyle="headingFive">
          <FormattedMessage
            id="2b64bac8-19f8-48a1-96dd-37163fd7e573"
            defaultMessage="Authentication & security"
          />
        </CardHeading>

        {organization.featureList.includes(Feature.TRANSACTION_MFA) && (
          <CardSection>
            <CardText>
              <FormattedMessage
                id="e6ff238f-1fff-4f5e-8944-5cc04e101637"
                defaultMessage="Multi-factor authentication for all transactions"
              />
            </CardText>
            <CardText>
              <DeprecatedRadioButtonField
                name="defaultAuthRequirement"
                automationId="default-auth-type-none"
                radioValue={AuthTypes.NONE}
                size="small"
                labelText={intl.formatMessage(MESSAGES.none)}
                infoText={intl.formatMessage(MESSAGES.noneDescription)}
              />
            </CardText>
            <CardText>
              <DeprecatedRadioButtonField
                name="defaultAuthRequirement"
                automationId="default-auth-type-sms"
                radioValue={AuthTypes.SMS}
                size="small"
                labelText={intl.formatMessage(MESSAGES.sms)}
                infoText={intl.formatMessage(MESSAGES.smsDescription)}
              />
            </CardText>
            <CardText>
              {organization.organizationType !== OrganizationTypeEnum.BUSINESS && (
                <DeprecatedRadioButtonField
                  name="defaultAuthRequirement"
                  automationId="default-auth-type-zip"
                  radioValue={AuthTypes.ZIP}
                  size="small"
                  labelText={intl.formatMessage(MESSAGES.zip)}
                  infoText={intl.formatMessage(MESSAGES.zipDescription)}
                />
              )}
            </CardText>
          </CardSection>
        )}

        <Hr />

        <CardSection>
          <CardText>
            <FormattedMessage
              id="43fb6b4f-4f6e-4aae-8241-f91cb22ac46a"
              defaultMessage="Secondary ID required?"
            />
            &nbsp;
            <Tooltip
              target={<Icon className="icon" name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
            >
              <FormattedMessage
                id="e14cda1a-c8a8-4694-9b18-b758a88c7ef2"
                defaultMessage="Sets the default value for secondary ID requirements. Updating this setting will not impact transactions that have already been created."
              />
            </Tooltip>
          </CardText>

          <DeprecatedRadioButtonField
            name="secondaryIdRequiredDefault"
            automationId="secondary-id-required-false"
            radioValue={false}
            labelText={
              <FormattedMessage id="9572413f-72e1-4ed1-8ab4-bb5f2e01cac2" defaultMessage="No" />
            }
            size="small"
          />
          <DeprecatedRadioButtonField
            name="secondaryIdRequiredDefault"
            automationId="secondary-id-required-true"
            labelText={
              <FormattedMessage id="d0650b1e-1cb1-4e1f-9519-25f396c9aad8" defaultMessage="Yes" />
            }
            radioValue
            size="small"
          />
        </CardSection>
      </Card>
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </form>
  );
}

const AuthenticationSecurityWithForm = reduxForm<FormValues, Props>({
  form: "DefaultAuthSettings",
})(LoadedAuthenticationSecurity);

export function AuthenticationSecurity({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(AuthSecurityQuery, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return <AuthenticationSecurityWithForm organization={data!.organization as Organization} />;
}
