import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import ActiveSessionBlockingModalWrapper from "common/signer/signer_identity/active_session_blocking_modal";
import { getLinkForQrStream } from "common/account/util";
import { pendoAddParamsToUrl } from "util/pendo";

import SignerLandingQuery, {
  type SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  type SignerLanding_viewer as Viewer,
} from "./index.query.graphql";
import { BitLandingV2 } from "./content/generic";
import { RealEstateLanding } from "./content/real_estate";
import { EsignLanding } from "./content/esign";
import { ProofLanding } from "./content/proof";
import { BusinessRonLandingPage } from "./v3/landings/business_ron";
import { RealRonLandingPage } from "./v3/landings/real_ron";
import { getLandingVariant, isEsignTransaction, LANDING_VARIANTS } from "./v3/util";
import { TransactionBlocked } from "../common";
import { IdentifyAccountRecoveryLanding } from "./content/IdentifyAccountRecovery";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
};

function LandingComponent({
  documentBundle,
  viewer,
  readOnly,
  handleBlockedAction,
}: Props & { handleBlockedAction: (action: () => void) => void }) {
  useEffect(() => {
    const bundleOrgId = documentBundle.organizationTransaction.publicOrganization.id;
    const parentOrgId =
      documentBundle.organizationTransaction.publicOrganization.parentOrganizationId;
    pendoAddParamsToUrl({
      orgId: bundleOrgId,
      ...(parentOrgId && { parentOrgId }),
    });
  }, []);

  const isEsign = isEsignTransaction(documentBundle, viewer);
  const landingPage = getLandingVariant(documentBundle, viewer);
  const landingProps = { handleBlockedAction, documentBundle, viewer, readOnly };

  switch (landingPage) {
    case LANDING_VARIANTS.BUSINESS_RON:
      return <BusinessRonLandingPage {...landingProps} />;
    case LANDING_VARIANTS.REAL_RON:
      return <RealRonLandingPage {...landingProps} />;
    case LANDING_VARIANTS.REAL_HYBRID:
      return <RealEstateLanding isEsign={isEsign} {...landingProps} />;
    case LANDING_VARIANTS.PROOF:
      return <ProofLanding isEsign={isEsign} {...landingProps} />;
    case LANDING_VARIANTS.IDENTIFY_ACCOUNT_RECOVERY:
      return <IdentifyAccountRecoveryLanding {...landingProps} />;
    case LANDING_VARIANTS.ESIGN:
      return <EsignLanding isEsign={isEsign} {...landingProps} />;
    default:
      return <BitLandingV2 isEsign={isEsign} {...landingProps} />;
  }
}

function BitLanding({ documentBundle, viewer, readOnly }: Props) {
  return (
    <ActiveSessionBlockingModalWrapper viewer={viewer} participants={documentBundle.participants}>
      {({ handleBlockedAction }) => (
        <LandingComponent
          documentBundle={documentBundle}
          viewer={viewer}
          readOnly={readOnly}
          handleBlockedAction={handleBlockedAction}
        />
      )}
    </ActiveSessionBlockingModalWrapper>
  );
}

function BitLandingWrapper({
  previewDocumentBundle,
  previewViewer,
}: {
  previewDocumentBundle?: DocumentBundle;
  previewViewer?: Viewer;
}) {
  const [searchParams] = useSearchParams();
  const documentBundleId = searchParams.get("id") || "";

  const { data, loading } = useQuery(SignerLandingQuery, {
    variables: {
      documentBundleId,
      link: getLinkForQrStream(),
    },
    skip: !documentBundleId,
  });

  if (previewDocumentBundle && previewViewer) {
    return <BitLanding readOnly documentBundle={previewDocumentBundle} viewer={previewViewer} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const { documentBundle, viewer } = data!;

  if (!documentBundle) {
    // documentBundle can be null if bundle was recalled

    return <TransactionBlocked />;
  }

  if (documentBundle.__typename !== "DocumentBundle") {
    throw new Error(`Expected DocumentBundle, got ${documentBundle.__typename}.`);
  }

  return <BitLanding documentBundle={documentBundle} viewer={viewer} />;
}

export default BitLandingWrapper;
