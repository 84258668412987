import { FormattedMessage, useIntl } from "react-intl";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { SettingsTitle } from "common/settingsv2/common";
import { useActiveOrganization } from "common/account/active_organization";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import BillingSettingsQuery, {
  type BillingSettings_node_Organization as Organization,
} from "../billing_query.graphql";
import SubscriptionPlan from "./plan";
import { ManagedBillingCard } from "../common/managed_billing_card";

export const BILLING_PLAN_DETAILS_ROUTE = "plan-details";

export function PlanDetailsTab() {
  return (
    <Tab to={BILLING_PLAN_DETAILS_ROUTE}>
      <FormattedMessage id="8dcc8eab-f5e4-4b25-a95f-b0a1ee74c6ef" defaultMessage="Plan Details" />
    </Tab>
  );
}

export function PlanDetails() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingPlanDetails),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BillingSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const nodeOrg = data?.node as Organization | null;
  if (nodeOrg?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${nodeOrg?.__typename}`);
  }

  const { managedBilling } = nodeOrg;
  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="972b6228-06b7-46f1-87ea-6f5497adff3c" defaultMessage="Plan Details" />
      </SettingsTitle>
      {managedBilling ? (
        <ManagedBillingCard headingText="Your plan" subtext="plan details" />
      ) : (
        <SubscriptionPlan organization={nodeOrg} isAdmin />
      )}
    </>
  );
}
