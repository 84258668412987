import { FormattedMessage, FormattedList } from "react-intl";

import { userFullName } from "util/user";
import { RequiredFeature, OrgTransactionStates } from "graphql_globals";
import DiscountCheck from "common/modals/discount_modal/discount_check";
import { useMobileScreenClass } from "common/core/responsive";
import { Paragraph } from "common/core/typography";
import { useViewer } from "util/viewer_wrapper";
import { useMutation } from "util/graphql";
import SignTosMutation from "common/modals/terms_of_service/sign_tos_mutation.graphql";
import AlertMessage from "common/core/alert_message";
import { useHandleReadyToSign, LandingWrapper } from "common/signer/bit_landing/content/common";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
} from "common/signer/bit_landing/index.query.graphql";
import { isActive } from "util/transaction";
import CoBrandedBlock from "common/signer/bit_landing/content/custom_block";
import Contacts from "common/signer/bit_landing/content/contacts";
import Support from "common/signer/bit_landing/content/support";
import { ProofFooter } from "common/proof_footer";

import { PageWrapper, ContentWrapper, MobileFooter } from "../../sections/common";
import { BitLandingQrCode } from "../../sections/qr_code";
import { Stats } from "./stats";
import { Expired } from "../../sections/hero/expired";
import { RonHero, InactiveRonHero } from "../../sections/hero/ron";
import Styles from "./index.module.scss";

export const BUSINESS_RON_LANDING_AUTOMATION_PREFIX = "bit-landing";
export const BUSINESS_RON_PENDO_ID = "business-ron-landing-cta";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  handleBlockedAction: (action: () => void) => void;
};

const BusinessRonLandingPage = function BusinessRonLandingPage({
  documentBundle,
  viewer,
  readOnly,
  handleBlockedAction,
}: Props) {
  const {
    organizationTransaction,
    documents: { totalCount },
    availableForSigning,
    requiredFeatures,
    participants,
  } = documentBundle;
  const isMobile = useMobileScreenClass();
  const { publicOrganization } = organizationTransaction;
  const { organizationBrand } = publicOrganization;

  const { refetch: refetchViewer } = useViewer();
  const signTermsOfService = useMutation(SignTosMutation);
  const skipDocPreview = !availableForSigning;

  const showExpiredState =
    organizationTransaction.isExpired &&
    organizationTransaction.state !== OrgTransactionStates.COMPLETED;
  const handleContinue = useHandleReadyToSign(documentBundle, viewer, readOnly);

  const showInactiveState = !isActive(organizationTransaction.activation);
  const expiredOrInactive = showExpiredState || showInactiveState;

  const hasSigningOrder = requiredFeatures?.includes(RequiredFeature.SIGNING_ORDER);
  const currentParticipant = participants?.find((p) => p?.userId === viewer.user?.id);
  const nextInOrder = participants?.filter((p) => p?.canStartSigning);
  const participantNames = nextInOrder?.map((p) => userFullName(p));

  const brandName = organizationBrand.name;

  const signTosAndGetStarted = async () => {
    if (viewer.user?.applicableTou.every((tou) => !tou.acceptedAt)) {
      await signTermsOfService({
        variables: { input: { userId: viewer.user.id } },
      });
      await refetchViewer();
    }
    handleContinue();
  };

  const buttonCta = showInactiveState ? (
    <FormattedMessage
      id="9e858a1a-3ece-4548-82db-2491f83826b1"
      defaultMessage="Preview documents"
    />
  ) : skipDocPreview || showExpiredState ? (
    <FormattedMessage id="054f830e-dc9c-45e3-8316-7cd008f635a6" defaultMessage="View documents" />
  ) : (
    <FormattedMessage id="8af5131a-fc0d-44c7-9dea-cf47f1c691c4" defaultMessage="Notarize now" />
  );

  const buttonCtaOnClick = expiredOrInactive
    ? handleContinue
    : () => handleBlockedAction(signTosAndGetStarted);

  const signingOrderAlert = (
    <AlertMessage banner={!isMobile} centerText kind="info" className={Styles.signingOrderAlert}>
      <Paragraph size={isMobile ? "defaultSize" : "large"}>
        {hasSigningOrder ? (
          !currentParticipant?.canStartSigning ? (
            <FormattedMessage
              id="090d83d2-adc8-4074-941c-57a0dddb5357"
              defaultMessage="It's {formattedParticipantNames}'s turn to sign. You will receive an email when it's your turn."
              values={{
                formattedParticipantNames: participantNames?.length ? (
                  <FormattedList type="conjunction" value={participantNames} />
                ) : (
                  <FormattedMessage
                    id="65d93c86-d981-493c-8e84-5ecc2af02db5"
                    defaultMessage="another signer"
                  />
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="e59c5987-d919-4658-8084-1cc5be53ff91"
              defaultMessage="Someone else is currently signing."
            />
          )
        ) : (
          <FormattedMessage
            id="87942a68-489f-40b8-a516-e52ff2bb7c59"
            defaultMessage="Someone else is currently signing. We’ll send an email shortly when we’re ready for you to sign. In the meantime, you can view your documents."
          />
        )}
      </Paragraph>
    </AlertMessage>
  );

  function renderHero() {
    if (showExpiredState) {
      return (
        <Expired
          brandName={brandName}
          buttonOnClick={handleContinue}
          automationPrefix={BUSINESS_RON_LANDING_AUTOMATION_PREFIX}
          pendoId={`${BUSINESS_RON_PENDO_ID}-expired`}
        />
      );
    }

    if (showInactiveState) {
      return (
        <InactiveRonHero
          buttonCta={buttonCta}
          buttonOnClick={buttonCtaOnClick}
          brandName={brandName}
          documentCount={totalCount}
          activation={organizationTransaction.activation}
        />
      );
    }
    return (
      <>
        {!isMobile && skipDocPreview && signingOrderAlert}
        <RonHero
          buttonCta={buttonCta}
          buttonOnClick={buttonCtaOnClick}
          brandName={brandName}
          documentCount={totalCount}
        />
      </>
    );
  }

  return (
    <>
      <PageWrapper>
        <ContentWrapper>
          <DiscountCheck />
          {renderHero()}
          <CoBrandedBlock useNewStyles organizationBrand={organizationBrand} />
          <Contacts useNewStyles contacts={organizationTransaction.contacts} />
          <BitLandingQrCode
            qrStream={viewer.qrStream}
            automationPrefix={BUSINESS_RON_LANDING_AUTOMATION_PREFIX}
          />
          <Stats />
          <Support useNewStyles />
          <ProofFooter withContainer />
        </ContentWrapper>
        <MobileFooter
          pendoId={BUSINESS_RON_PENDO_ID}
          buttonCta={buttonCta}
          buttonOnClick={signTosAndGetStarted}
          signingOrderAlert={expiredOrInactive ? undefined : skipDocPreview && signingOrderAlert}
        />
      </PageWrapper>
    </>
  );
};

function BusinessRonLandingPageWrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix={BUSINESS_RON_LANDING_AUTOMATION_PREFIX} {...props}>
      {() => <BusinessRonLandingPage {...props} />}
    </LandingWrapper>
  );
}

export { BusinessRonLandingPageWrapper as BusinessRonLandingPage };
