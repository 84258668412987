import {
  IdentifyUseCase,
  OrganizationTransactionVariant,
  SigningRequirementEnum,
} from "graphql_globals";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";

import type {
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle_participants as ParticipantType,
} from "../index.query.graphql";

type Participant = ParticipantType | null | undefined;

export const LANDING_VARIANTS = {
  BUSINESS_RON: "business-ron",
  REAL_RON: "real-estate-ron",
  REAL_HYBRID: "real-estate-hybrid",
  ESIGN: "esign",
  PROOF: "proof",
  IDENTIFY_ACCOUNT_RECOVERY: "identify-account-recovery",
  NONE: "none",
};

/**
 * @description Helpers used in getLandingVariant below, but also in bit_landing/index
 */

function viewerParticipant(participants: DocumentBundle["participants"], user: Viewer["user"]) {
  return participants?.find((p) => p!.userId === user!.id);
}

function viewerParticipantIsEsign(viewerParticipant: Participant) {
  return viewerParticipant?.signingRequirement === SigningRequirementEnum.ESIGN;
}

function isProofTransaction(documentBundle: DocumentBundle, viewerParticipant: Participant) {
  return (
    [
      OrganizationTransactionVariant.PROOF,
      OrganizationTransactionVariant.IDENTIFY,
      OrganizationTransactionVariant.CERTIFY,
    ].includes(documentBundle.organizationTransaction.transactionVariant) ||
    viewerParticipant?.proofRequirement?.ca?.selfie
  );
}

export function isEsignTransaction(documentBundle: DocumentBundle, { user }: Viewer) {
  const { participants, completionRequirements } = documentBundle;
  const participant = viewerParticipant(participants, user);
  return (
    (viewerParticipantIsEsign(participant) || onlyRequiresEsign(completionRequirements)) &&
    !isProofTransaction(documentBundle, participant)
  );
}

/**
 * @description Get the landing variant based on transaction type and participant signing requirements
 */

export function getLandingVariant(documentBundle: DocumentBundle, viewer: Viewer) {
  const { organizationTransaction, participants, completionRequirements } = documentBundle;
  const { user } = viewer;
  const { isMortgage, requiresNsaMeeting, transactionVariant, identifyUseCase } =
    organizationTransaction;

  const participant = viewerParticipant(participants, user);
  const participantIsEsign = viewerParticipantIsEsign(participant);

  // Proof will have eSign requirement but also proof requirement
  const isNotEsignOrProof =
    !(participantIsEsign || onlyRequiresEsign(completionRequirements)) &&
    !isProofTransaction(documentBundle, participant);

  const isProof = isProofTransaction(documentBundle, participant);
  const isEsign = isEsignTransaction(documentBundle, viewer);

  if (
    transactionVariant === OrganizationTransactionVariant.NOTARIZATION &&
    requiresNsaMeeting &&
    isNotEsignOrProof
  ) {
    if (isMortgage) {
      return LANDING_VARIANTS.REAL_RON;
    }
    return LANDING_VARIANTS.BUSINESS_RON;
  }
  if (isMortgage && !participantIsEsign) {
    return LANDING_VARIANTS.REAL_HYBRID;
  }
  if (
    transactionVariant === OrganizationTransactionVariant.IDENTIFY &&
    identifyUseCase === IdentifyUseCase.ACCOUNT_RECOVERY
  ) {
    return LANDING_VARIANTS.IDENTIFY_ACCOUNT_RECOVERY;
  }
  if (isProof) {
    // Render proof.tsx for Proof transactions (including transactions requiring esign authentication with Persona)
    return LANDING_VARIANTS.PROOF;
  }
  if (isEsign) {
    // Render esign_content.tsx for all esign transactions that have customizable landing pages enabled
    return LANDING_VARIANTS.ESIGN;
  }
  return LANDING_VARIANTS.NONE;
}
