import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Payer } from "graphql_globals";
import PaymentForm from "common/settings/payment/index.apollo";
import Button from "common/core/button";
import Link from "common/core/link";
import SROnly from "common/core/screen_reader";
import { TIER_PRICING_URL } from "constants/marketing";
import { CardText, CardHeading } from "common/core/card";
import { Paragraph } from "common/core/typography";
import { usePermissions } from "common/core/current_user_role";
import WorkflowModal from "common/modals/workflow_modal";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./payment.module.scss";

type Props = {
  defaultPaymentSource:
    | {
        __typename: "Card";
        name: string | null;
        last4: string | null;
      }
    | {
        __typename: "AchAccount";
        bankName: string | null;
        last4: string | null;
      }
    | null;
  defaultPayer: Payer;
  validCard: boolean;
  instructions: ReactNode;
};

type CardPayment = {
  name: string;
  last4: string;
  brand?: string;
  expMonth?: number;
  expYear?: number;
  className?: string;
  deleteAction?: {
    onClick: () => void;
    disabledLabel?: string;
  };
};

export function CardPaymentMethod({
  name,
  last4,
  expMonth,
  expYear,
  className,
  deleteAction,
}: CardPayment) {
  return (
    <div className={classnames(Styles.payment, className)}>
      <SROnly>
        <FormattedMessage
          id="d0da98e8-29b6-4b70-9751-66431d66f050"
          defaultMessage="Credit card number for {name} with last 4 digits of {last4}{hasExpiration, select, true {, expires {expMonth}/{expYear}} other {}}"
          values={{ name, last4, hasExpiration: Boolean(expMonth && expYear), expMonth, expYear }}
        />
      </SROnly>
      <span aria-hidden="true" data-automation-id="card-name">
        {name}
      </span>
      <span aria-hidden="true">
        <FormattedMessage
          id="a34d22b8-9207-4f27-971e-58ec0297bbec"
          defaultMessage="ending in {last4}"
          values={{ last4 }}
        />
      </span>
      {expMonth && expYear && (
        <span aria-hidden="true">
          <FormattedMessage
            id="e5a0b07b-2db8-4dce-a8de-a06a4f9de7a9"
            defaultMessage="exp {expMonth}/{expYear}"
            values={{ expMonth, expYear }}
          />
        </span>
      )}
      {deleteAction?.onClick && (
        <IconButton
          label={
            deleteAction.disabledLabel ? (
              deleteAction.disabledLabel
            ) : (
              <FormattedMessage
                id="abcede5a-0d53-45c0-a470-d46a7b6e8c1e"
                defaultMessage="Delete card"
              />
            )
          }
          buttonColor="danger"
          variant="tertiary"
          name="delete"
          disabled={Boolean(deleteAction.disabledLabel)}
          onClick={deleteAction.onClick}
          hoverLabel={deleteAction.disabledLabel ? "top" : undefined}
        />
      )}
    </div>
  );
}

export function AchPaymentMethod({
  defaultPaymentSource,
  className,
}: {
  defaultPaymentSource: { __typename: "AchAccount"; bankName: string | null; last4: string | null };
  className?: string;
}) {
  return (
    <div className={classnames(Styles.payment, className)}>
      <SROnly>
        <FormattedMessage
          id="032ac6f0-0060-4730-8e3c-0521cd02836e"
          defaultMessage="ACH Account: {bankName} with last 4 digits of {last4}"
          values={{
            bankName: defaultPaymentSource.bankName,
            last4: defaultPaymentSource.last4,
          }}
        />
      </SROnly>
      <span aria-hidden="true">{defaultPaymentSource.bankName}</span>
      <span aria-hidden="true">
        {"**** "}
        {defaultPaymentSource.last4}
      </span>
    </div>
  );
}

export function PaymentMethod({
  defaultPaymentSource,
  defaultPayer,
  validCard,
  instructions,
}: Props) {
  const [showSetup, setShowSetup] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const canEditPaymentDetails = hasPermissionFor("editOrganizationDetails");

  function renderFooterButtons() {
    return (
      canEditPaymentDetails &&
      (defaultPaymentSource ? (
        <div className={Styles.updatePaymentButton}>
          <Button
            aria-describedby="update-plan-payment-instructions"
            automationId="account-payment-button"
            onClick={() => setShowSetup(true)}
            buttonColor="action"
            variant="tertiary"
          >
            <FormattedMessage
              id="5808827a-8274-4dae-94ac-38e878189ff7"
              defaultMessage="Update Payment Method"
            />
          </Button>
        </div>
      ) : (
        <div className={Styles.addPaymentButton}>
          <Button
            aria-describedby="update-plan-payment-instructions"
            automationId="account-payment-button"
            onClick={() => setShowSetup(true)}
            buttonColor="action"
            variant="secondary"
            withIcon={{ name: "add", placement: "left" }}
          >
            <FormattedMessage
              id="0bae5311-5424-4156-81d5-e2fdd8fc7741"
              defaultMessage="Add Payment Method"
            />
          </Button>
        </div>
      ))
    );
  }
  const nonAdminSubText = (
    <FormattedMessage
      id="77867d34-a2e8-488d-876a-e2ed0f6b598a"
      defaultMessage={
        "An account admin can set the payment method that will be used for the platform and transaction fees paid to Proof."
      }
    />
  );
  return (
    <>
      <CardHeading level="h3">
        <FormattedMessage
          id="c755018e-2c95-414c-b700-fda9164e47ae"
          defaultMessage="Account Payment"
        />
      </CardHeading>
      <CardText>
        <Paragraph id="update-plan-payment-instructions">
          {canEditPaymentDetails ? instructions : nonAdminSubText}
        </Paragraph>
      </CardText>

      {defaultPayer === Payer.NOTARIZE ? (
        <CardText>
          <p>
            <FormattedMessage
              id="a75e4629-1791-4f87-8b9f-e62837e1f9a4"
              defaultMessage="Paying by invoice"
            />
          </p>
          <p>
            <FormattedMessage
              id="3737910f-1ade-4101-a207-487c74e27e0a"
              defaultMessage="<link>Contact sales</link> to change"
              values={{
                link: (msg) => <Link href={TIER_PRICING_URL}>{msg}</Link>,
              }}
            />
          </p>
        </CardText>
      ) : (
        <div
          data-automation-id="account-payment-details"
          className={classnames(
            defaultPaymentSource && !validCard && Styles.invalid,
            !defaultPaymentSource && Styles.noCard,
          )}
        >
          {defaultPaymentSource?.__typename === "AchAccount" && (
            <AchPaymentMethod defaultPaymentSource={defaultPaymentSource} />
          )}
          {defaultPaymentSource?.__typename === "Card" && (
            <CardPaymentMethod
              last4={defaultPaymentSource.last4!}
              name={defaultPaymentSource.name!}
            />
          )}
          {!defaultPaymentSource && !canEditPaymentDetails && (
            <FormattedMessage
              id="ddab1d19-d6c3-40a7-b0a5-d73008a25359"
              defaultMessage="Payment not set up"
            />
          )}
        </div>
      )}

      {showSetup && (
        <WorkflowModal
          className={Styles.modal}
          title={
            <FormattedMessage
              id="8a85760f-63dc-4ac6-8205-aac167eca1bb"
              defaultMessage="Account Payment"
            />
          }
          closeBehavior={{ tag: "with-button", onClose: () => setShowSetup(false) }}
        >
          <PaymentForm
            onChangesSaved={() => setShowSetup(false)}
            useModalStyle
            forceNewPayment
            collectOrgPaymentInfo
          />
        </WorkflowModal>
      )}
      {renderFooterButtons()}
    </>
  );
}
