import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper, SettingsTitle } from "common/settingsv2/common";

import OAuthSettingsContent from "./oauth_settings";

const TITLE = <FormattedMessage id="085f1c00-0c4d-48bb-a769-98ff9da081bd" defaultMessage="OAuth" />;
export const OAUTH_SETTINGS_ROOT_PREFIX = "oauth";

export function SettingsSidebarOAuthLink() {
  return <SidebarTabLink to={OAUTH_SETTINGS_ROOT_PREFIX}>{TITLE}</SidebarTabLink>;
}

export function OAuthSettings() {
  return (
    <SettingsPageWrapper>
      <SettingsHeader title={TITLE} tabs={[]} />

      <SettingsTitle>
        <FormattedMessage
          id="9a39e500-cf38-497a-b0a4-9f13f63cf62a"
          defaultMessage="Client applications"
        />
      </SettingsTitle>

      <OAuthSettingsContent />
    </SettingsPageWrapper>
  );
}
