import "./index.scss";

import { useState, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import WorkflowModal from "common/modals/workflow_modal";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import { getParticipantName } from "util/document_bundle";
import { useMobileScreenClass } from "common/core/responsive";
import { useDocumentTitles } from "util/document_title";
import { RadioGroup, RadioLabel, RadioInput } from "common/core/form/option";
import { DocumentBundleParticipantActivity } from "graphql_globals";
import { Heading, Paragraph } from "common/core/typography";
import { useId } from "util/html";

import ParticipantSelector from "./participant_selector";

type SelectorProps = ComponentProps<typeof ParticipantSelector>;
type Props = {
  documentBundle: {
    organizationTransaction: {
      expiry: string | null;
      isMortgage: boolean;
      splitSigningDisabled: boolean;
    };
  };
  onCancel: () => void;
  onContinue: () => void;
  onViewDocuments: () => void;
  onParticipantSelectToggled: (participanId: string) => void;
  hasMultipleRequiredSigners: boolean;
  participantGroups: Record<string, SelectorProps["participants"]>;
  participantGroup: {
    userId: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    requiredToSignWith: string | null;
  }[];
  participantGroupOwners: string[];
  selectedOtherParticipantGroupOwners: string[];
  loading?: boolean;
};

const LIST_FORMAT_OPTIONS = { style: "long", type: "conjunction" } as const;
const MESSAGES = defineMessages({
  multipleSignersTitle: {
    id: "d386978f-8e8a-47c4-8d0e-c775f0132a1f",
    defaultMessage: "Who is joining the meeting from this device?",
  },
  twoSignersTitle: {
    id: "11d305f0-5a34-482f-b9ab-2a27922beda6",
    defaultMessage: "Are you and {otherSignerFirstName} joining the meeting from the same device?",
  },
  requiredSignersTitle: {
    id: "a860aa43-600a-40b0-8136-50e3511f8264",
    defaultMessage:
      "{requiredSigners} are required to sign together. Are you {requiredSignerCount, select, 2 {both} other {all} } joining the meeting from this device?",
  },
  requiredNotTogetherTitle: {
    id: "d3b0752a-c272-4cef-b1ed-5e21cd263b18",
    defaultMessage: "Not with required signers?",
  },
  requiredTogetherYesLabel: {
    id: "f2b319bb-1fcb-44fc-b6f2-60c4ed968ddf",
    defaultMessage: "Yes, we're together",
  },
  requiredTogetherNoLabel: {
    id: "4ad18bd4-89ae-40c8-9b63-ef28c6e2bdd5",
    defaultMessage: "No, we're not together",
  },
  requiredOnlyLabel: {
    id: "5b6e1021-ee00-43b1-ab09-3068f8fab532",
    defaultMessage: "{requiredSigners} (required to sign together)",
  },
  requiredAndOtherLabel: {
    id: "87c4d64e-2447-43c2-99d4-b45780716108",
    defaultMessage: "{requiredAndOther} signer(s) on this transaction",
  },
  otherParticipant: {
    id: "abae942e-6095-48f0-b1cd-0a54da99d440",
    defaultMessage: "other",
  },
  twoOptionalTogetherYesLabel: {
    id: "10cc81c5-1448-41d3-8f9d-d89f6dc3db38",
    defaultMessage: "Yes, we're together",
  },
  twoOptionalTogetherNoLabel: {
    id: "d3170a0a-b575-4c6b-a8ee-5f91b92a6378",
    defaultMessage: "No, we're not together",
  },
  manyOptionalJustUser: {
    id: "2ea40d22-61d4-419c-80a2-a0c876046dc7",
    defaultMessage: "Just me",
  },
  manyOptionalTogether: {
    id: "5aed401e-0a15-460d-88f5-0c526661eb2f",
    defaultMessage: "Me and other signer(s) on this transaction",
  },
  otherJoined: {
    id: "9077e0c5-bb41-461a-85b2-bdcdd23a2ecf",
    defaultMessage: "{firstName} joined from another device",
  },
  otherInMeeting: {
    id: "0a29b6ff-1aeb-4a3e-bc01-ca797aa1cb43",
    defaultMessage: "{firstName} is currently in the meeting",
  },
  otherInWaitingRoom: {
    id: "6a2f02f5-0b22-4987-af4e-1febf96d2793",
    defaultMessage: "{firstName} is waiting to join the meeting",
  },
});

const RADIO_VALUES = {
  requiredTogether: {
    yes: "requiredTogetherYes",
    no: "requiredTogetherNo",
  },
  requiredAndOptional: {
    required: "requiredOnly",
    requiredAndOptional: "requiredAndOptional",
  },
  twoOptional: {
    user: "twoOptionalOnlyUser",
    userAndOptional: "twoOptionalUserAndOptional",
  },
  manyOptional: {
    user: "manyOptionalOnlyUser",
    userAndOptional: "ManuOptionalUserAndOptional",
  },
} as const;

function SplitSigningDisabledBanner() {
  return (
    <AlertMessage className="SelectSignersModal--body--banner" kind="info">
      <FormattedMessage
        id="22f73c8f-c3a3-4b4e-89db-0a0b81258124"
        defaultMessage="All signers must be ready to join the meeting at the same time."
      />
    </AlertMessage>
  );
}

function SelectSignersModal(props: Props) {
  const intl = useIntl();
  const isXsOrSm = useMobileScreenClass();
  const [notTogether, setNotTogether] = useState(false);
  const [radioValue, setRadioValue] = useState<string | null>(null);
  const signerMessageId = useId();
  const {
    participantGroupOwners,
    participantGroups,
    loading,
    onViewDocuments,
    onCancel,
    selectedOtherParticipantGroupOwners,
    onParticipantSelectToggled,
    documentBundle: { organizationTransaction },
    hasMultipleRequiredSigners,
    participantGroup,
  } = props;
  const { expiry, isMortgage, splitSigningDisabled } = organizationTransaction;
  const totalSignerGroups = participantGroupOwners.length;
  const participantGroupOwnerId =
    participantGroup[0].requiredToSignWith || participantGroup[0].userId;
  const otherParticipantGroupOwners = participantGroupOwners.filter(
    (owner) => owner !== participantGroupOwnerId,
  );
  const otherParticipantGroups = otherParticipantGroupOwners.map(
    (owner) => participantGroups[owner],
  );

  const handleNotTogether = () => {
    setNotTogether(true);
  };

  const handleStartSigning = () => {
    const { onContinue } = props;
    if (radioValue === RADIO_VALUES.requiredTogether.no) {
      setNotTogether(true);
    } else {
      onContinue();
    }
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const deselectAllOptionalParticipantGroupOwners = () => {
    selectedOtherParticipantGroupOwners.forEach((selectedParticipantGroupOwner) => {
      if (selectedParticipantGroupOwner !== participantGroupOwnerId) {
        onParticipantSelectToggled(selectedParticipantGroupOwner);
      }
    });
  };

  function useGetTitle() {
    const documentTitles = useDocumentTitles();

    if (notTogether) {
      return {
        modal: intl.formatMessage(MESSAGES.requiredNotTogetherTitle),
        document: intl.formatMessage(documentTitles.requiredNotTogetherTitle),
      };
    }

    if (hasMultipleRequiredSigners) {
      if (totalSignerGroups > 1) {
        return {
          "aria-label": intl.formatMessage(MESSAGES.multipleSignersTitle),
          document: intl.formatMessage(documentTitles.multipleSignersTitle),
        };
      }
      // Only 1 group of required signers
      return {
        "aria-label": intl.formatMessage(MESSAGES.requiredSignersTitle, {
          requiredSigners: intl.formatList(
            participantGroup.map((participant, index) =>
              getParticipantName(intl, participant, false, index),
            ),
            LIST_FORMAT_OPTIONS,
          ),
          requiredSignerCount: participantGroup.length,
        }),
        document: intl.formatMessage(documentTitles.requiredSignersTitle),
      };
    } else if (
      totalSignerGroups === 2 &&
      otherParticipantGroups[0].length === 1 &&
      otherParticipantGroups[0][0].canStartSigning
    ) {
      return {
        "aria-label": intl.formatMessage(MESSAGES.twoSignersTitle, {
          otherSignerFirstName: getParticipantName(intl, otherParticipantGroups[0][0], false, 1),
        }),
        document: intl.formatMessage(documentTitles.twoSignersTitle),
      };
    }
    return {
      "aria-label": intl.formatMessage(MESSAGES.multipleSignersTitle),
      document: intl.formatMessage(documentTitles.multipleSignersTitle),
    };
  }

  const getOptionalSignerSelector = () => {
    if (
      radioValue === RADIO_VALUES.requiredAndOptional.requiredAndOptional ||
      radioValue === RADIO_VALUES.manyOptional.userAndOptional
    ) {
      return (
        <div className="SelectSignersModal--body--radioGroup--optionalSigners">
          <FormattedMessage
            id="283ef4c8-2bff-4106-87d4-83692dc912fa"
            defaultMessage="Select all signers who are physically present with you and sharing this device:"
          />
          {otherParticipantGroupOwners.map((ownerId, i) => (
            <ParticipantSelector
              key={i}
              participants={participantGroups[ownerId]}
              selected={selectedOtherParticipantGroupOwners.includes(ownerId)}
              ownerId={ownerId}
              onParticipantSelectToggle={onParticipantSelectToggled}
              automationId={`participant-selector-${i}`}
            />
          ))}
        </div>
      );
    }
  };

  const getBody = () => {
    if (notTogether) {
      return (
        <Heading textStyle="subtitleSmall" level="h2">
          <FormattedMessage
            id="a502d9b3-5dfb-4003-b4d2-3a11c177e376"
            defaultMessage="For now, you can read through the contents of {isMortgage, select, true{your closing package} other{the documents}} and return later when you are together.{isMortgage, select, true{If you cannot meet before the closing expires, on {closingDate}, please contact support.} other{}}"
            values={{
              closingDate: <CustomFormattedDateTime value={expiry} formatStyle="MMMM do" />,
              isMortgage,
            }}
          />
        </Heading>
      );
    }

    if (hasMultipleRequiredSigners) {
      if (totalSignerGroups > 1) {
        // Multiple Required participants and optional pariticipants on transaction

        const onRequiredTogetherChange = (value: string) => {
          setRadioValue(value);
          if (value === RADIO_VALUES.requiredAndOptional.required) {
            deselectAllOptionalParticipantGroupOwners();
          }
        };
        const requiredSignerNames = participantGroup.map((participant, index) =>
          getParticipantName(intl, participant, false, index),
        );
        const requiredAndOtherNames = requiredSignerNames.concat(
          intl.formatMessage(MESSAGES.otherParticipant),
        );
        return (
          <>
            <RadioGroup
              data-automation-id="select-signer-radioGroup"
              className="SelectSignersModal--body--radioGroup"
              label={intl.formatMessage(MESSAGES.multipleSignersTitle)}
            >
              {splitSigningDisabled && <SplitSigningDisabledBanner />}
              <RadioLabel
                label={intl.formatMessage(MESSAGES.requiredOnlyLabel, {
                  requiredSigners: intl.formatList(requiredSignerNames, LIST_FORMAT_OPTIONS),
                })}
                radio={
                  <RadioInput<
                    (typeof RADIO_VALUES.requiredAndOptional)[keyof typeof RADIO_VALUES.requiredAndOptional]
                  >
                    name="requiredAndOptional"
                    data-automation-id="select-user-group"
                    value={RADIO_VALUES.requiredAndOptional.required}
                    checked={RADIO_VALUES.requiredAndOptional.required === radioValue}
                    onChange={(event) => onRequiredTogetherChange(event.currentTarget.value)}
                  />
                }
                additionalLabelContent={
                  <Button
                    variant="tertiary"
                    buttonColor="action"
                    onClick={handleNotTogether}
                    automationId="not-together-button"
                  >
                    <FormattedMessage
                      id="60f3aa4d-5ba0-4c4c-8526-f990297ed5b4"
                      defaultMessage="Not together?"
                    />
                  </Button>
                }
              />
              <RadioLabel
                label={intl.formatMessage(MESSAGES.requiredAndOtherLabel, {
                  requiredAndOther: intl.formatList(requiredAndOtherNames, LIST_FORMAT_OPTIONS),
                })}
                radio={
                  <RadioInput<
                    (typeof RADIO_VALUES.requiredAndOptional)[keyof typeof RADIO_VALUES.requiredAndOptional]
                  >
                    name="requiredAndOptional"
                    data-automation-id="select-optional"
                    value={RADIO_VALUES.requiredAndOptional.requiredAndOptional}
                    checked={RADIO_VALUES.requiredAndOptional.requiredAndOptional === radioValue}
                    onChange={(event) => onRequiredTogetherChange(event.currentTarget.value)}
                  />
                }
              />
            </RadioGroup>
            {getOptionalSignerSelector()}
          </>
        );
      }
      // Only multiple required participants on transaction

      return (
        <>
          <RadioGroup
            data-automation-id="select-signer-radioGroup"
            className="SelectSignersModal--body--radioGroup"
            label={intl.formatMessage(MESSAGES.requiredSignersTitle, {
              requiredSigners: intl.formatList(
                participantGroup.map((participant, index) =>
                  getParticipantName(intl, participant, false, index),
                ),
                LIST_FORMAT_OPTIONS,
              ),
              requiredSignerCount: participantGroup.length,
            })}
          >
            <RadioLabel
              label={intl.formatMessage(MESSAGES.requiredTogetherYesLabel)}
              radio={
                <RadioInput<
                  (typeof RADIO_VALUES.requiredTogether)[keyof typeof RADIO_VALUES.requiredTogether]
                >
                  name="requiredAreTogether"
                  data-automation-id="select-user-group"
                  value={RADIO_VALUES.requiredTogether.yes}
                  checked={RADIO_VALUES.requiredTogether.yes === radioValue}
                  onChange={(event) => setRadioValue(event.currentTarget.value)}
                />
              }
            />
            <RadioLabel
              label={intl.formatMessage(MESSAGES.requiredTogetherNoLabel)}
              radio={
                <RadioInput<
                  (typeof RADIO_VALUES.requiredTogether)[keyof typeof RADIO_VALUES.requiredTogether]
                >
                  name="requiredAreTogether"
                  data-automation-id="select-not-together"
                  value={RADIO_VALUES.requiredTogether.no}
                  checked={RADIO_VALUES.requiredTogether.no === radioValue}
                  onChange={(event) => setRadioValue(event.currentTarget.value)}
                />
              }
            />
          </RadioGroup>
        </>
      );
    } else if (
      totalSignerGroups === 2 &&
      otherParticipantGroups[0].length === 1 &&
      otherParticipantGroups[0][0].canStartSigning
    ) {
      // user group does not have multiple required, exactly one other optional participant
      const onTwoOptionalTogetherChange = (value: string) => {
        setRadioValue(value);
        if (value === RADIO_VALUES.twoOptional.userAndOptional) {
          onParticipantSelectToggled(otherParticipantGroupOwners[0]);
        } else {
          deselectAllOptionalParticipantGroupOwners();
        }
      };
      const otherSigner = otherParticipantGroups[0][0];
      const isOtherSignerSigning =
        otherSigner.signingActivity !== DocumentBundleParticipantActivity.NOT_SIGNING;
      const otherSignerMessage =
        otherSigner.signingActivity === DocumentBundleParticipantActivity.SIGNING_IN_MEETING
          ? MESSAGES.otherInMeeting
          : otherSigner.signingActivity === DocumentBundleParticipantActivity.WAITING_ROOM
            ? MESSAGES.otherInWaitingRoom
            : MESSAGES.otherJoined;
      return (
        <RadioGroup
          label={intl.formatMessage(MESSAGES.twoSignersTitle, {
            otherSignerFirstName: getParticipantName(intl, otherParticipantGroups[0][0], false, 1),
          })}
          data-automation-id="select-signer-radioGroup"
          className="SelectSignersModal--body--radioGroup"
        >
          {splitSigningDisabled && <SplitSigningDisabledBanner />}
          {isOtherSignerSigning && (
            <Paragraph id={signerMessageId} className="SelectSignersModal--body--signer-message">
              {intl.formatMessage(otherSignerMessage, { firstName: otherSigner.firstName })}
            </Paragraph>
          )}
          <RadioLabel
            label={intl.formatMessage(MESSAGES.twoOptionalTogetherYesLabel)}
            radio={
              <RadioInput<(typeof RADIO_VALUES.twoOptional)[keyof typeof RADIO_VALUES.twoOptional]>
                name="twoOptionalTogether"
                data-automation-id="participant-selector-0"
                value={RADIO_VALUES.twoOptional.userAndOptional}
                checked={RADIO_VALUES.twoOptional.userAndOptional === radioValue}
                onChange={(event) => onTwoOptionalTogetherChange(event.currentTarget.value)}
                disabled={isOtherSignerSigning}
                aria-describedby={signerMessageId}
              />
            }
          />
          <RadioLabel
            label={intl.formatMessage(MESSAGES.twoOptionalTogetherNoLabel)}
            radio={
              <RadioInput<(typeof RADIO_VALUES.twoOptional)[keyof typeof RADIO_VALUES.twoOptional]>
                name="twoOptionalTogether"
                data-automation-id="select-user-group"
                value={RADIO_VALUES.twoOptional.user}
                checked={RADIO_VALUES.twoOptional.user === radioValue}
                onChange={(event) => onTwoOptionalTogetherChange(event.currentTarget.value)}
              />
            }
          />
        </RadioGroup>
      );
    }
    // user group does not have multiple required, multiple other optional participants
    const onManyOptionalChange = (value: string) => {
      setRadioValue(value);
      if (value === RADIO_VALUES.manyOptional.user) {
        deselectAllOptionalParticipantGroupOwners();
      }
    };
    return (
      <>
        <RadioGroup
          label={intl.formatMessage(MESSAGES.multipleSignersTitle)}
          data-automation-id="select-signer-radioGroup"
          className="SelectSignersModal--body--radioGroup"
        >
          {splitSigningDisabled && <SplitSigningDisabledBanner />}
          <RadioLabel
            label={intl.formatMessage(MESSAGES.manyOptionalJustUser)}
            radio={
              <RadioInput<
                (typeof RADIO_VALUES.manyOptional)[keyof typeof RADIO_VALUES.manyOptional]
              >
                name="manyOptional"
                data-automation-id="select-user-group"
                value={RADIO_VALUES.manyOptional.user}
                checked={RADIO_VALUES.manyOptional.user === radioValue}
                onChange={(event) => onManyOptionalChange(event.currentTarget.value)}
              />
            }
          />
          <RadioLabel
            label={intl.formatMessage(MESSAGES.manyOptionalTogether)}
            radio={
              <RadioInput<
                (typeof RADIO_VALUES.manyOptional)[keyof typeof RADIO_VALUES.manyOptional]
              >
                name="manyOptional"
                data-automation-id="select-optional"
                value={RADIO_VALUES.manyOptional.userAndOptional}
                checked={RADIO_VALUES.manyOptional.userAndOptional === radioValue}
                onChange={(event) => onManyOptionalChange(event.currentTarget.value)}
              />
            }
          />
        </RadioGroup>
        {getOptionalSignerSelector()}
      </>
    );
  };

  const getButtons = () => {
    let continueEnabled = false;
    switch (radioValue) {
      case null:
        continueEnabled = false;
        break;
      case RADIO_VALUES.requiredAndOptional.requiredAndOptional:
      case RADIO_VALUES.manyOptional.userAndOptional:
        continueEnabled = selectedOtherParticipantGroupOwners.length > 0;
        break;
      default:
        continueEnabled = true;
        break;
    }
    const cancelLink = (
      <Button
        key="SelectSignersModal-cancel"
        variant="tertiary"
        buttonColor="action"
        buttonSize={isXsOrSm ? "large" : undefined}
        onClick={handleCancel}
        automationId="cancel-button"
        aria-label={intl.formatMessage({
          id: "883cdf8e-8029-4cdf-b0a7-658c8169027f",
          defaultMessage: "Back to previous page",
        })}
      >
        <FormattedMessage id="3c34c3d5-e007-4c6a-9363-6f49c9bbb88c" defaultMessage="Back" />
      </Button>
    );

    if (notTogether) {
      return [
        <Button
          key="SelectSignersModal-not-together-back"
          buttonColor="action"
          variant="secondary"
          onClick={() => setNotTogether(false)}
          automationId="not-together-back-button"
          buttonSize={isXsOrSm ? "large" : undefined}
        >
          <FormattedMessage id="eaef0a8c-a699-4e03-a8c5-3c4d933741e7" defaultMessage="Back" />
        </Button>,
        <Button
          key="SelectSignersModal-view-documents"
          buttonColor="action"
          variant="primary"
          onClick={onViewDocuments}
          automationId="view-documents-button"
          buttonSize={isXsOrSm ? "large" : undefined}
        >
          <FormattedMessage
            id="c1d678e3-4a5f-4e98-9118-396dfb774cb3"
            defaultMessage="View documents"
          />
        </Button>,
      ];
    }

    return [
      cancelLink,
      <Button
        key="SelectSignersModal-start-signing"
        buttonColor="action"
        variant="primary"
        isLoading={loading}
        onClick={handleStartSigning}
        disabled={!continueEnabled}
        automationId="start-signing-button"
        buttonSize={isXsOrSm ? "large" : undefined}
      >
        <FormattedMessage id="655714e8-ef11-460f-b1ad-f2c139a0c6e5" defaultMessage="Continue" />
      </Button>,
    ];
  };

  const title = useGetTitle();

  return (
    <WorkflowModal
      automationId="select_signers_modal"
      title={title.modal}
      buttons={getButtons()}
      closeBehavior={{ tag: "without-button", disableClickOutside: true, onClose: onCancel }}
      autoFocus
      documentTitle={title.document}
      aria-label={title["aria-label"]}
      isSensitive={false}
    >
      <div role="region" aria-live="assertive">
        {notTogether && getBody()}
      </div>
      {!notTogether && getBody()}
    </WorkflowModal>
  );
}

export default SelectSignersModal;
