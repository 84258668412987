import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState, useEffect } from "react";

import { OrganizationTransactionVariant } from "graphql_globals";
import { Heading } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { Footer, NextStepButton, PreviousStepButton } from "common/signer/common";
import { AutomaticFormRow, FormRow, Legend, SpacedMultipartFormRow } from "common/core/form/layout";
import { EmailTextInput } from "common/core/form/text";
import { PhoneNumberInput } from "common/core/form/phone-number";
import { useFieldArray, useForm, type UseFormReturn } from "common/core/form";
import { FormattedFieldError, emailPatternValidation, isAriaInvalid } from "common/core/form/error";
import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import { SignerFullNameSubForm } from "common/core/form/subforms/signer_full_name";
import { type Pronouns, PronounsButtonSubForm } from "common/core/form/subforms/pronouns";
import { SRONLY_CLASSNAME } from "common/core/screen_reader";

import Styles from "./index.module.scss";

export const COLOCATED_SIGNER_EMAILS_KEY = "colocatedSignerEmails";

const LABELS = defineMessages({
  fullNameLabel: {
    id: "c670de8a-a08d-4678-adc1-8701fec0cd61",
    defaultMessage:
      "Enter your name as it appears on your ID. We'll use this to create your signature.",
  },
  hasCosignerLabel: {
    id: "cc66a080-47c9-4c7f-b12c-d4d61eb71ab6",
    defaultMessage: "Does anyone else need to sign this document?",
  },
  hasCosignerYesLabel: {
    id: "29e5b715-fa2f-49f9-b7d2-9a80b0bd4b4b",
    defaultMessage: "Yes",
  },
  hasCosignerNoLabel: {
    id: "a22eb983-eb48-4794-937f-d7cd60e9cd0a",
    defaultMessage: "No, it's just me",
  },
  cosignerLegend: {
    id: "17987870-c41e-41ba-9f7a-e091ebba486e",
    defaultMessage: "Additional signer {signerNumber}",
  },
  cosignerEmailLabel: {
    id: "3ddbf05c-3221-4ebd-88ce-3afcfb0ca31e",
    defaultMessage: "Enter their email",
  },
  cosignerEmailHelperLabel: {
    id: "72744d93-5b3f-4006-a2c4-427f4afeab9e",
    defaultMessage:
      "We'll use their email to create their account and add them to the transaction.",
  },
  cosignerColocatedLabel: {
    id: "854bf5b7-326c-46b0-96a4-b978cc9077b8",
    defaultMessage: "Will this signer be joining the meeting from this device with you?",
  },
  cosignerColocatedYesLabel: {
    id: "06346968-61f9-4789-92a2-3a39ff629458",
    defaultMessage: "Yes",
  },
  cosignerColocatedNoLabel: {
    id: "5e055ebb-98bd-4ee3-a621-49700812ab05",
    defaultMessage: "No",
  },
  cosignerColocatedHelperLabel: {
    id: "6a594243-08a8-4ef3-bc6f-2d0cd10a00df",
    defaultMessage: "They can join the transaction from this device or their own.",
  },
  cosignerColocatedYesAriaLabel: {
    id: "b2e7ee46-f278-42b9-a726-ebdfe6e23259",
    defaultMessage: `Additional signer {signerNumber} will join the meeting from this device`,
  },
  cosignerColocatedNoAriaLabel: {
    id: "89280d8f-c3fd-4a8b-b90c-0f644101381f",
    defaultMessage: `Additional signer {signerNumber} will not join the meeting from this device`,
  },
  cosignerEmailAriaLabel: {
    id: "1d21be80-090e-48de-8af9-a5a53c416746",
    defaultMessage: `Additional signer {signerNumber} email input`,
  },
  removeCosignerAriaLabel: {
    id: "57b24cec-5669-4a37-a98e-167d17c1ec5d",
    defaultMessage: `Remove additional signer {signerNumber} email`,
  },
  cosignerPhoneNumberAriaLabel: {
    id: "baeeb366-cadc-4c50-a3fe-52d7d11af782",
    defaultMessage: `Additional signer {signerNumber} mobile phone number input`,
  },
});

const ERRORS = defineMessages({
  hasCosigner: {
    id: "15ff89fd-d301-4268-ae2c-5844a5ba9bda",
    defaultMessage: "You must answer the question before continuing.",
  },
  firstName: {
    id: "ccc59402-572c-49c4-a7f0-ff77703c80cc",
    defaultMessage: "First name is required.",
  },
  lastName: {
    id: "a1c5f870-c458-4276-9bb1-b966de102f9b",
    defaultMessage: "Last name is required.",
  },
  cosigners: {
    id: "3587486c-5acf-4123-96ac-e1807c733518",
    defaultMessage: "You must fill in the field before continuing.",
  },
  cosignersCanRemove: {
    id: "a38cc07e-13df-409e-a8c6-1e9d3c3a49a4",
    defaultMessage: "You must fill in the field before continuing, or remove the signer.",
  },
  duplicateEmailError: {
    id: "caaf0ed0-1cc2-498d-9d6b-ba963098455f",
    defaultMessage: "Cannot include duplicate emails.",
  },
});
const MESSAGES = defineMessages({
  extraSignerCalloutSplitSigningDisabled: {
    id: "47053a21-61f9-4236-8485-7f0a6b67219e",
    defaultMessage:
      "Not together? No problem! We'll email them a secure link to join the notary meeting remotely now.",
  },
  extraSignerCalloutSignerPays: {
    id: "47053a21-61f9-4236-8485-7f0a6b67219e",
    defaultMessage:
      "Not together? No problem! We'll email them a secure link to join the notary meeting remotely now, or they can sign later on their own and pay for an additional notary meeting.",
  },
  extraSignerCalloutOrgPays: {
    id: "3f837533-612f-401d-a30e-df47440c797b",
    defaultMessage:
      "Not together? No problem! We'll email them a secure link to join the notary meeting remotely now, or they can sign later on their own.",
  },
});

function setDefaultSigners(min: number, includeSms: boolean) {
  const defaultSigners = [];
  const defaultVal = includeSms ? { email: "", phoneNumber: "" } : { email: "" };
  for (let i = 1; i < min; i++) {
    defaultSigners.push(defaultVal);
  }
  return defaultSigners;
}

function canRemoveCosigner(index: number, minSigners: number) {
  // If the min is 1, then they selected yes they have cosigners,
  // which makes the minimum for deletion purposes 2
  const min = minSigners === 1 ? 2 : minSigners;
  return index >= min - 1;
}

type GatherSignerDetailsForm = {
  firstName: string;
  middleName: string;
  lastName: string;
  hasCosigners: "yes" | "no" | "";
  pronouns: Pronouns | undefined;
  customPronouns: string | undefined;
  coSigners: { email: string; phoneNumber?: string; colocated?: "yes" | "no" }[];
};
export function GatherSignerDetails({
  minSigners,
  maxSigners,
  includeSms,
  canJoinMeetingWithoutOtherSigners,
  orgPays,
  onSubmit,
  currentUserEmail,
  transactionVariant,
}: {
  minSigners: number;
  maxSigners: number;
  includeSms: boolean;
  canJoinMeetingWithoutOtherSigners: boolean;
  orgPays: boolean;
  onSubmit: (values: GatherSignerDetailsForm) => void;
  currentUserEmail: string;
  transactionVariant: OrganizationTransactionVariant;
}) {
  const [step, setStep] = useState<"primarySigner" | "coSigners">("primarySigner");
  const intl = useIntl();

  useEffect(() => {
    segmentTrack(SEGMENT_EVENTS.ADD_COSIGNER_PAGE_SHOWN);
  }, []);

  const canAddCosigner = minSigners === 1 && maxSigners > 1;

  const requiresCosigners = minSigners > 1;

  const form = useForm<GatherSignerDetailsForm>({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      pronouns: undefined,
      customPronouns: undefined,
      hasCosigners: "",
      coSigners: setDefaultSigners(minSigners, includeSms),
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    control,
    resetField,
  } = form;

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "coSigners",
  });

  const onContinue = (values: GatherSignerDetailsForm) => {
    if (step === "primarySigner") {
      if (errors.hasCosigners || errors.firstName || errors.lastName) {
        return;
      }
      if (form.getValues("hasCosigners") === "yes" || requiresCosigners) {
        if (form.getValues("hasCosigners") === "yes") {
          // If they have cosigners, we need to add the default signers (2 total signers min)
          replace(setDefaultSigners(2, includeSms));
        }
        setStep("coSigners");
      } else {
        segmentTrack(SEGMENT_EVENTS.ADD_COSIGNER_PAGE_SIGNER_CLICKED_CONTINUE);
        onSubmit(values);
      }
    }
    if (step === "coSigners") {
      if (errors.coSigners) {
        return;
      }
      const cosigners = form.getValues("coSigners");
      const colocatedSignerEmails = [
        currentUserEmail,
        ...cosigners
          .filter((cosigner) => {
            return cosigner.colocated === "yes";
          })
          .map((cosigner) => {
            return cosigner.email;
          }),
      ];
      if (colocatedSignerEmails.length > 0) {
        sessionStorage.setItem(COLOCATED_SIGNER_EMAILS_KEY, JSON.stringify(colocatedSignerEmails));
      }
      segmentTrack(SEGMENT_EVENTS.ADD_COSIGNER_PAGE_SIGNER_CLICKED_CONTINUE);
      onSubmit(values);
    }
  };

  const onBack = () => {
    if (step === "coSigners") {
      setStep("primarySigner");
    }
  };

  const canBeColocated =
    transactionVariant === OrganizationTransactionVariant.NOTARIZATION ||
    transactionVariant === OrganizationTransactionVariant.VERIFICATION_OF_FACT;

  return (
    <div className={Styles.container} data-automation-id="review-signing-details-form">
      <div className={Styles.content}>
        {step === "primarySigner" && (
          <>
            <Heading className={Styles.heading} level="h1" textStyle="headingFour">
              <FormattedMessage
                id="32a0b96d-d82f-41dc-b07d-5127d840f37b"
                defaultMessage={"Provide your full name and document details"}
              />
            </Heading>
            <fieldset className={Styles.section}>
              <Legend className={Styles.subText} label={intl.formatMessage(LABELS.fullNameLabel)} />
              <SignerFullNameSubForm
                wrapperElement={SpacedMultipartFormRow}
                errors={{
                  firstName: errors.firstName,
                  middleName: errors.middleName,
                  lastName: errors.lastName,
                }}
                firstNameProps={form.register("firstName", {
                  required: intl.formatMessage(ERRORS.firstName),
                })}
                middleNameProps={form.register("middleName")}
                lastNameProps={form.register("lastName", {
                  required: intl.formatMessage(ERRORS.lastName),
                })}
              />
              <PronounsButtonSubForm
                selectedPronouns={form.watch("pronouns")}
                pronounsProps={form.register("pronouns")}
                customPronounsProps={form.register("customPronouns")}
                resetField={
                  resetField as UseFormReturn<{
                    pronouns?: Pronouns;
                    customPronouns?: string;
                  }>["resetField"]
                }
              />
            </fieldset>
            {canAddCosigner && (
              <>
                <Heading level={"h2"} className={Styles.subText} textStyle="subtitleSmall">
                  <FormattedMessage
                    id="e20e9dec-6b1e-4974-8ac4-a52ac505497e"
                    defaultMessage={"Additional signers"}
                  />
                </Heading>
                <RadioGroup
                  horizontal
                  displayRequiredAsterisk
                  label={intl.formatMessage(LABELS.hasCosignerLabel)}
                  groupError={
                    <FormattedFieldError inputName="hasCosigners" error={errors.hasCosigners} />
                  }
                >
                  <ChoiceChip
                    label={intl.formatMessage(LABELS.hasCosignerYesLabel)}
                    hasError={Boolean(errors.hasCosigners)}
                    radio={
                      <RadioInput
                        value={"yes"}
                        aria-invalid={isAriaInvalid(errors.hasCosigners)}
                        data-automation-id="select-yes-cosigner"
                        {...form.register("hasCosigners", {
                          required: intl.formatMessage(ERRORS.hasCosigner),
                        })}
                      />
                    }
                  />
                  <ChoiceChip
                    label={intl.formatMessage(LABELS.hasCosignerNoLabel)}
                    hasError={Boolean(errors.hasCosigners)}
                    radio={
                      <RadioInput
                        value={"no"}
                        data-automation-id="select-no-cosigner"
                        aria-invalid={isAriaInvalid(errors.hasCosigners)}
                        {...form.register("hasCosigners", {
                          required: intl.formatMessage(ERRORS.hasCosigner),
                        })}
                      />
                    }
                  />
                </RadioGroup>
              </>
            )}
          </>
        )}
        {step === "coSigners" && (
          <>
            <Heading className={Styles.heading} level="h1" textStyle="headingFour">
              {requiresCosigners ? (
                <FormattedMessage
                  id="e976984e-5657-40c2-85b5-5e246588bffd"
                  defaultMessage="Your document requires {signers} additional signer{signers, plural, one{} other{s}}"
                  values={{
                    signers: minSigners - 1,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="24604e60-3ce6-4da5-9f53-44ab783e3c9e"
                  defaultMessage="Additional signers"
                />
              )}
            </Heading>
            {fields.map((signer, i) => (
              <div className={Styles.section} key={signer.id}>
                <div className={Styles.cosignerHeaderRow}>
                  {i > 0 && (
                    <Heading level="h2" textStyle="subtitleSmall">
                      <FormattedMessage
                        id="cc8b40d3-670b-40f3-a554-0dfde6113c63"
                        defaultMessage="Additional signer"
                      />
                    </Heading>
                  )}
                  {canRemoveCosigner(i, minSigners) && (
                    <Button
                      aria-label={intl.formatMessage(LABELS.removeCosignerAriaLabel, {
                        signerNumber: i + 1,
                      })}
                      onClick={() => {
                        segmentTrack(SEGMENT_EVENTS.ADD_COSIGNER_PAGE_SIGNER_CLICKED_REMOVE_SIGNER);
                        remove(i);
                      }}
                      variant="tertiary"
                      buttonColor="danger"
                      buttonSize="condensed"
                      withIcon={{
                        name: "delete",
                        placement: "left",
                      }}
                    >
                      <FormattedMessage
                        id="da03675a-7628-4c89-bcd3-6bd17087d9a4"
                        defaultMessage="Remove"
                      />
                    </Button>
                  )}
                </div>
                <fieldset>
                  <Legend
                    className={SRONLY_CLASSNAME}
                    label={intl.formatMessage(LABELS.cosignerLegend, {
                      signerNumber: i + 1,
                    })}
                  ></Legend>
                  <AutomaticFormRow<GatherSignerDetailsForm>
                    aria-label={intl.formatMessage(LABELS.cosignerEmailAriaLabel, {
                      signerNumber: i + 1,
                    })}
                    label={intl.formatMessage(LABELS.cosignerEmailLabel)}
                    helperText={{
                      text: intl.formatMessage(LABELS.cosignerEmailHelperLabel),
                      placement: "above",
                    }}
                    registerOptions={{
                      required: canRemoveCosigner(i, minSigners)
                        ? intl.formatMessage(ERRORS.cosignersCanRemove)
                        : intl.formatMessage(ERRORS.cosigners),
                      pattern: emailPatternValidation(intl),
                      validate: (value, formValues) => {
                        const hasDuplicates = formValues.coSigners.some((val, index) => {
                          return (
                            index !== i &&
                            (value as string).toLowerCase() === val.email.toLowerCase()
                          );
                        });
                        return (
                          !(
                            (value as string).toLowerCase() === currentUserEmail || hasDuplicates
                          ) || intl.formatMessage(ERRORS.duplicateEmailError)
                        );
                      },
                    }}
                    form={form}
                    fullWidth
                    name={`coSigners.${i}.email`}
                    required
                    as={EmailTextInput}
                  />
                  {includeSms && (
                    <FormRow fullWidth>
                      <PhoneNumberInput
                        control={form.control}
                        name={`coSigners.${i}.phoneNumber`}
                        isRequired
                        customRegisterOptions={{
                          required: canRemoveCosigner(i, minSigners)
                            ? intl.formatMessage(ERRORS.cosignersCanRemove)
                            : intl.formatMessage(ERRORS.cosigners),
                        }}
                        aria-label={intl.formatMessage(LABELS.cosignerPhoneNumberAriaLabel, {
                          signerNumber: i + 1,
                        })}
                        label={
                          <FormattedMessage
                            id="1809ceeb-85d1-49d2-b692-6114e28a3923"
                            defaultMessage="Phone number"
                          />
                        }
                      />
                    </FormRow>
                  )}
                  {canBeColocated && (
                    <>
                      <RadioGroup
                        horizontal
                        displayRequiredAsterisk
                        label={intl.formatMessage(LABELS.cosignerColocatedLabel)}
                        helperText={intl.formatMessage(LABELS.cosignerColocatedHelperLabel)}
                        groupError={
                          <FormattedFieldError
                            inputName={`coSigners.${i}.colocated`}
                            error={errors.coSigners?.[i]?.colocated}
                          />
                        }
                      >
                        <ChoiceChip
                          label={intl.formatMessage(LABELS.cosignerColocatedYesLabel)}
                          hasError={Boolean(errors.coSigners?.[i]?.colocated)}
                          radio={
                            <RadioInput
                              value={"yes"}
                              aria-label={intl.formatMessage(LABELS.cosignerColocatedYesAriaLabel, {
                                signerNumber: i + 1,
                              })}
                              aria-invalid={isAriaInvalid(errors.coSigners?.[i]?.colocated)}
                              data-automation-id={`select-yes-cosigner-${i}`}
                              {...form.register(`coSigners.${i}.colocated`, {
                                required: canRemoveCosigner(i, minSigners)
                                  ? intl.formatMessage(ERRORS.cosignersCanRemove)
                                  : intl.formatMessage(ERRORS.cosigners),
                              })}
                            />
                          }
                        />
                        <ChoiceChip
                          label={intl.formatMessage(LABELS.cosignerColocatedNoLabel)}
                          hasError={Boolean(errors.coSigners?.[i]?.colocated)}
                          radio={
                            <RadioInput
                              value={"no"}
                              aria-label={intl.formatMessage(LABELS.cosignerColocatedNoAriaLabel, {
                                signerNumber: i + 1,
                              })}
                              data-automation-id={`select-no-cosigner-${i}`}
                              aria-invalid={isAriaInvalid(errors.coSigners?.[i]?.colocated)}
                              {...form.register(`coSigners.${i}.colocated`, {
                                required: canRemoveCosigner(i, minSigners)
                                  ? intl.formatMessage(ERRORS.cosignersCanRemove)
                                  : intl.formatMessage(ERRORS.cosigners),
                              })}
                            />
                          }
                        />
                      </RadioGroup>
                      {form.watch(`coSigners.${i}.colocated`) === "no" && (
                        <div className={Styles.additionalSignerInfoBox}>
                          <AlertMessage
                            kind="info"
                            customIcon="info"
                            automationId="meeting-info-message"
                          >
                            {!canJoinMeetingWithoutOtherSigners
                              ? intl.formatMessage(MESSAGES.extraSignerCalloutSplitSigningDisabled)
                              : orgPays
                                ? intl.formatMessage(MESSAGES.extraSignerCalloutOrgPays)
                                : intl.formatMessage(MESSAGES.extraSignerCalloutSignerPays)}
                          </AlertMessage>
                        </div>
                      )}
                    </>
                  )}
                  {i < maxSigners - 2 && <Hr className={Styles.signerDivider} />}
                </fieldset>
              </div>
            ))}
            {fields.length < maxSigners - 1 && (
              <Button
                buttonColor="action"
                variant="tertiary"
                buttonSize="condensed"
                withIcon={{ name: "name", placement: "left" }}
                onClick={() => {
                  segmentTrack(SEGMENT_EVENTS.ADD_COSIGNER_PAGE_SIGNER_CLICKED_ADD_SIGNER);
                  append({ email: "" });
                }}
              >
                <FormattedMessage
                  id="b11620e2-a9d2-4ed5-8c5e-4e1cc5767de4"
                  defaultMessage="Add another signer (optional)"
                />
              </Button>
            )}
          </>
        )}
        <Footer
          previousStepButton={
            step !== "primarySigner" && (
              <PreviousStepButton data-automation-id="back-button" onClick={onBack} />
            )
          }
          nextStepButton={
            <NextStepButton
              data-automation-id="continue-button"
              onClick={handleSubmit((values) => {
                onContinue(values);
              })}
            />
          }
        />
      </div>
    </div>
  );
}
