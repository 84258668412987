import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import CustomerProfileQuery from "common/settingsv2/sidebar_settings/profile/customer_profile_query.graphql";
import CustomerProfileForm from "common/settingsv2/sidebar_settings/profile/personal_details/customer_profile_form";
import { useMatchScreenClass } from "common/core/responsive";

import Styles from "../index.module.scss";

export default function PersonalDetails() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalDetails),
  });
  const { data, loading } = useQuery(CustomerProfileQuery, {
    variables: { chargeStatuses: undefined },
  });
  const user = data?.viewer.user;
  const isExtraSmall = useMatchScreenClass("xs");

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage
            id="1ae5f7cb-6d28-4586-92a3-2bf55342383a"
            defaultMessage="Personal details"
          />
        </Heading>
      </div>
      <div className={Styles.body}>
        {loading || !user ? (
          <LoadingIndicator />
        ) : (
          <CustomerProfileForm user={user} fullWidthFooter={isExtraSmall} />
        )}
      </div>
    </>
  );
}
