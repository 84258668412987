import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useCallback, useState } from "react";

import { Heading, Paragraph } from "common/core/typography";
import { Card, CardSection } from "common/core/card";
import LockSuccessIcon from "assets/images/mfa_success.svg";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
// import { pushNotification } from "common/core/notification_center/actions";
// import { captureException } from "util/exception";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import { FieldErrorMessage, isAriaInvalid } from "common/core/form/error";

import Styles from "./index.module.scss";
import CommonStyles from "../../profile/overview/index.module.scss";
// import { useMutation } from "../../../../../util/graphql";
// import CreateOathApplicationMutation from "./create_oauth_application_mutation.graphql";

const MESSAGES = defineMessages({
  createMessage: {
    id: "87daacfd-b2f1-4e32-81b9-dfc361125706",
    defaultMessage: "Create client application",
  },
  createModalHeading: {
    id: "0c0c27c3-55fe-4a76-9351-f88ae74db86d",
    defaultMessage: "Create new client application",
  },
  cancel: {
    id: "489ee0cf-b972-4916-ab6f-a54b5682984a",
    defaultMessage: "Cancel",
  },
  successMessage: {
    id: "a78839e7-a14e-4b21-8baf-5d650307aa4a",
    defaultMessage: "Client application created successfully",
  },
  failureMessage: {
    id: "d60a8ded-4b5d-4eab-8712-c85340b58f9e",
    defaultMessage: "Failed to create client application",
  },
  nameFieldLabel: {
    id: "a41fa48d-46d5-46b3-b483-7e46cc747f92",
    defaultMessage: "Display name",
  },
  nameRequiredMessage: {
    id: "4fe4c64e-efb1-4d21-bd68-ae07fd5a69dc",
    defaultMessage: "Client application name is required",
  },
  nameFieldMaxLength: {
    id: "70269f4b-9fe1-4ca2-adb1-57a10711e3a3",
    defaultMessage: "Client application name is too long",
  },
});

export default function OAuthSettingsContent() {
  const intl = useIntl();
  const [currentModal, setCurrentModal] = useState<"create" | "delete" | null>(null);

  const form = useForm({
    defaultValues: {
      displayName: "",
    },
  });

  const {
    formState: { errors },
  } = form;

  const closeModal = useCallback(() => {
    setCurrentModal(null);
    form.reset();
    form.clearErrors();
  }, [form]);

  // const createOauthApplicationMutateFn = useMutation(CreateOathApplicationMutation);

  // const onSubmit = useCallback((formValues) => {
  //   return createOauthApplicationMutateFn({ variables: { input: { name: formValues.displayName } } })
  //     .then(() => {
  //       pushNotification({
  //         subtype: "SUCCESS",
  //         message: intl.formatMessage(MESSAGES.successMessage),
  //       });
  //       closeModal();
  //       form.reset();
  //     })
  //     .catch((err) => {
  //       captureException(err);
  //       pushNotification({
  //         subtype: "ERROR",
  //         message: intl.formatMessage(MESSAGES.failureMessage),
  //       });
  //       closeModal();
  //       throw err;
  //     });
  // }, []);

  return (
    <Card fullWidth>
      <CardSection>
        <div className={Styles.contentWrapper}>
          <div>
            <img src={LockSuccessIcon} aria-hidden="true" alt="" width="120" />
            <Heading level="h3" textStyle="headingFour" className={CommonStyles.heading}>
              <FormattedMessage
                id="8c819680-e1e6-4f98-b197-5e455916261d"
                defaultMessage="Create your first client application"
              />
            </Heading>
            <div className={Styles.textContainer}>
              <Paragraph className={CommonStyles.copy}>
                <FormattedMessage
                  id="188ca57e-64a7-4490-8702-1d21cc716150"
                  defaultMessage="Add a client application with a unique display name. We will generate an app ID and secret(s) for your use."
                />
              </Paragraph>
            </div>
          </div>
          <Button onClick={() => setCurrentModal("create")} buttonColor="action" variant="primary">
            {intl.formatMessage(MESSAGES.createMessage)}
          </Button>
        </div>
        {currentModal === "create" && (
          <WorkflowModal
            autoFocus
            title={intl.formatMessage(MESSAGES.createModalHeading)}
            buttons={[
              <Button
                key="cancel"
                onClick={() => closeModal()}
                buttonColor="dark"
                variant="tertiary"
                disabled={form.formState.isSubmitting}
              >
                {intl.formatMessage(MESSAGES.cancel)}
              </Button>,
              <Button
                key="submit"
                // onClick={form.handleSubmit(onSubmit)} TODO PLAT-5796
                type="submit"
                buttonColor="action"
                variant="primary"
                isLoading={form.formState.isSubmitting}
              >
                {intl.formatMessage(MESSAGES.createMessage)}
              </Button>,
            ]}
          >
            <div>
              <StyledTextInput
                data-automation-id="display-name-input"
                aria-invalid={isAriaInvalid(errors.displayName)}
                disabled={form.formState.isSubmitting}
                label={intl.formatMessage(MESSAGES.nameFieldLabel)}
                displayRequiredAsterisk
                {...form.register("displayName", {
                  required: true,
                  maxLength: {
                    value: 256,
                    message: intl.formatMessage(MESSAGES.nameFieldMaxLength),
                  },
                })}
              />
              {errors.displayName && (
                <div>
                  <FieldErrorMessage
                    inputName="displayName"
                    message={
                      errors.displayName.message || intl.formatMessage(MESSAGES.nameRequiredMessage)
                    }
                  />
                </div>
              )}
            </div>
          </WorkflowModal>
        )}
      </CardSection>
    </Card>
  );
}
