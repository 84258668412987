import { useSearchParams } from "react-router-dom";

import BiometricConsentCopyDisplay from "common/identity_verification/biometric_consent_copy_display";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";

import BiometricConsentCopyQuery from "./index.query.graphql";
import Styles from "./index.module.scss";

export default function AppEmbedDisplayBiometricConsentCopyWrapper() {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transactionId");

  if (!transactionId) {
    throw new Error("transactionId parameter is required.");
  }

  return <AppEmbedDisplayBiometricConsentCopy transactionId={transactionId} />;
}

function AppEmbedDisplayBiometricConsentCopy({ transactionId }: { transactionId: string }) {
  const { data, loading } = useQuery(BiometricConsentCopyQuery, { variables: { transactionId } });

  // TODO: ID-2203: Add notifications to mobile apps when the biometric consent copy has been loaded
  // and displayed. Also implement unit test for it. Something like this:
  // useEffect(() => {
  //   if (!loading && data) {
  //     pushNotification({ loaded: true })
  //   }
  // }, [loading, data]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (data && data.node !== null && data.node.__typename === "OrganizationTransaction") {
    return (
      <div className={Styles.layout}>
        <BiometricConsentCopyDisplay transaction={data.node} />
      </div>
    );
  }

  throw new Error("invalid transaction id");
}
