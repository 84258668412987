import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Tab from "common/core/tabs/tab";

import { TabIncompleteIcon } from "../../notary/incomplete_icon";
import { DefaultPaymentSettings } from "../payer_settings";

export const BILLING_PAYMENT_SETTINGS_ROUTE = "payment-settings";

export function PaymentSettingsTab({
  notaryMissingPayment = false,
}: {
  notaryMissingPayment?: boolean;
}) {
  return (
    <Tab to={BILLING_PAYMENT_SETTINGS_ROUTE}>
      <FormattedMessage
        id="a13d008d-5000-488e-b2d0-4c46580ac77a"
        defaultMessage="Payment Settings"
      />
      {notaryMissingPayment && <TabIncompleteIcon />}
    </Tab>
  );
}

type Props = {
  extendedSettings?: ReactNode;
  // Should only be passed in from business admin settings since
  // ODNs/NSTs are admin's of their orgs and they're the only ones
  // that would access their notary payment options in org settings.
  showNotaryPaymentSettings?: boolean;
};

export function PaymentSettings({ extendedSettings, showNotaryPaymentSettings }: Props) {
  return (
    <DefaultPaymentSettings
      extendedSettings={extendedSettings}
      showNotaryPaymentSettings={showNotaryPaymentSettings}
      payerOrPayment="Payment"
    />
  );
}
