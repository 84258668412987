import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { useForm } from "common/core/form";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { Card, CardHeading, CardSection } from "common/core/card";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { useQuery, useMutation } from "util/graphql";
import { useId } from "util/html";

import SignersPermissionsQuery, {
  type SignerPermissions_organization_Organization as Organization,
} from "./signer_permissions_query.graphql";
import UpdateSignerPermissionsMutation from "./update_signer_permissions_mutation.graphql";

type FormValues = {
  defaultSignerCanAnnotate: "no" | "yes";
  lockRecipientName: "no" | "yes";
};

function LoadedSignerPermissions({ organization }: { organization: Organization }) {
  const form = useForm<FormValues>({
    defaultValues: {
      defaultSignerCanAnnotate: organization.defaultSignerCanAnnotate ? "yes" : "no",
      lockRecipientName: organization.lockRecipientName ? "yes" : "no",
    },
  });
  const updateSignerPermissionsMutateFn = useMutation(UpdateSignerPermissionsMutation);
  const formId = useId();

  const onSubmit = async (values: FormValues) => {
    const { defaultSignerCanAnnotate, lockRecipientName } = values;
    if (form.formState.isDirty) {
      try {
        await updateSignerPermissionsMutateFn({
          variables: {
            input: {
              organizationId: organization.id,
              defaultSignerCanAnnotate: defaultSignerCanAnnotate === "yes",
              lockRecipientName: lockRecipientName === "yes",
            },
          },
        });
        pushNotification({
          message: (
            <FormattedMessage
              id="ddb21cb9-cd21-4a00-b4c4-ac8fd2dc9564"
              defaultMessage="Signer permissions updated"
            />
          ),
        });
        form.reset(values);
      } catch {
        pushNotification({
          subtype: "ERROR",
          message: (
            <FormattedMessage
              id="5475887e-98fc-4f06-b1f5-9afd265d8bed"
              defaultMessage="Signer permissions failed to save"
            />
          ),
        });
      }
    }
  };

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            disabled={!form.formState.isDirty}
            isLoading={form.formState.isSubmitting}
            automationId="save-button"
          >
            <FormattedMessage
              id="e98e2b03-3f01-48eb-a4ad-25c6276ae175"
              defaultMessage="Save changes"
            />
          </Button>
        }
      >
        <CardHeading level="h3" headingStyle="headingFive">
          <FormattedMessage
            id="2b64bac8-19f8-48a1-96dd-37163fd7e573"
            defaultMessage="Signer permissions"
          />
        </CardHeading>

        <CardSection>
          <RadioGroup
            label={
              <FormattedMessage
                id="6e6248e5-b27e-44bd-87b0-a922abc51f87"
                defaultMessage="Signer can edit document by default"
              />
            }
            helperText={
              <FormattedMessage
                id="01794388-657d-451a-8f5a-228cdf8354d3"
                defaultMessage="Sets the default value for document editing. Updating this setting will not impact transactions that have already been created."
              />
            }
            horizontal
          >
            <RadioLabel
              label={
                <FormattedMessage id="3a6dff9d-044a-49af-a964-d6c1158c4fde" defaultMessage="No" />
              }
              radio={
                <RadioInput<FormValues["defaultSignerCanAnnotate"]>
                  value="no"
                  data-automation-id="signer-can-annotate-false"
                  {...form.register("defaultSignerCanAnnotate")}
                />
              }
            />
            <RadioLabel
              label={
                <FormattedMessage id="669579d1-9ea6-48aa-8276-87fcd3e9b521" defaultMessage="Yes" />
              }
              radio={
                <RadioInput<FormValues["defaultSignerCanAnnotate"]>
                  value="yes"
                  data-automation-id="signer-can-annotate-true"
                  {...form.register("defaultSignerCanAnnotate")}
                />
              }
            />
          </RadioGroup>
        </CardSection>
        <CardSection>
          <RadioGroup
            label={
              <FormattedMessage
                id="6e6248e5-b27e-44bd-87b0-a922abc51f67"
                defaultMessage="Lock recipient name"
              />
            }
            helperText={
              <FormattedMessage
                id="01794388-657d-451a-8f5a-228cdf8354d3"
                defaultMessage="Prevents recipients from changing their name to enter the Knowledge Based Authentication questions, or to create their signature."
              />
            }
            horizontal
          >
            <RadioLabel
              label={
                <FormattedMessage id="3a6dff9d-044a-49af-a964-d6c1158c4ade" defaultMessage="No" />
              }
              radio={
                <RadioInput<FormValues["lockRecipientName"]>
                  value="no"
                  data-automation-id="lock-recipient-name-false"
                  {...form.register("lockRecipientName")}
                />
              }
            />
            <RadioLabel
              label={
                <FormattedMessage id="669579d1-9ea6-48aa-8276-87fcd3e9b521" defaultMessage="Yes" />
              }
              radio={
                <RadioInput<FormValues["lockRecipientName"]>
                  value="yes"
                  data-automation-id="lock-recipient-name-true"
                  {...form.register("lockRecipientName")}
                />
              }
            />
          </RadioGroup>
        </CardSection>
      </Card>
    </form>
  );
}

export function SignerPermissions({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(SignersPermissionsQuery, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return <LoadedSignerPermissions organization={data!.organization as Organization} />;
}
