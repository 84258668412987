import { useState } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";
import { SEGMENT_EVENTS } from "constants/analytics";
import { daysFromNow } from "util/date";
import { segmentTrack } from "util/segment";

import { type ProofFrame_viewer_user_organization } from "./index.query.graphql";

const HIDE_DEFEND_TRIAL_BANNER_KEY = "proof:hide-defend-trial-banner";

type Organization = Pick<
  ProofFrame_viewer_user_organization,
  "defendTrial" | "defendRiskScoresTrial"
>;

type Props = {
  organization: Organization;
};

export function DefendTrialBanner({ organization }: Props) {
  const [hideBanner, setHideBanner] = useState(
    () => window.localStorage.getItem(HIDE_DEFEND_TRIAL_BANNER_KEY) === "true",
  );

  const defendTrialDaysLeft = daysFromNow(organization.defendTrial);
  const riskScoresTrialDaysLeft = daysFromNow(organization.defendRiskScoresTrial);
  const daysLeft = Math.max(defendTrialDaysLeft, riskScoresTrialDaysLeft);
  const lastDay = daysLeft === 1;
  const trialType = defendTrialDaysLeft > 0 ? "defend" : "riskScores";

  const handleBannerClose = () => {
    setHideBanner(true);
    window.localStorage.setItem(HIDE_DEFEND_TRIAL_BANNER_KEY, "true");
    segmentTrack(SEGMENT_EVENTS.DEFEND_TRIAL_BANNER_DISMISSED, {
      trialType,
      daysLeft,
    });
  };

  const handleLinkClick = () => {
    segmentTrack(SEGMENT_EVENTS.DEFEND_TRIAL_BANNER_LINK_CLICKED, {
      trialType,
      daysLeft,
    });
  };

  if (hideBanner && !lastDay) {
    return null;
  }

  if (daysLeft > 0) {
    return (
      <AlertMessage
        banner
        kind="subtle"
        centerText
        onClose={lastDay ? undefined : handleBannerClose}
      >
        <FormattedMessage
          id="8c4b0bd6-8229-467f-ac28-b30923774f38"
          defaultMessage="You have {daysLeft} {daysLeft, plural, one {day} other {days}} remaining to monitor identity risk with your free trial of Defend. <a>Learn more</a>"
          values={{
            daysLeft,
            a: (text) => (
              <Link onClick={handleLinkClick} href="https://www.proof.com/product/defend">
                {text}
              </Link>
            ),
          }}
        />
      </AlertMessage>
    );
  }

  return null;
}
