import { FormattedMessage } from "react-intl";

import {
  MfaOptions,
  PhoneNumberEdit,
  type MfaOptionsProps,
} from "common/authentication/mfa_options";
import { useQuery } from "util/graphql";
import { CardHeading, CardText } from "common/core/card";
import { DeprecatedCheckboxWithLabel } from "common/form/inputs/checkbox";
import { AuthTypes } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { FormattedAuthType } from "common/core/format/formatted_auth_type";
import { TotpSettings } from "common/authentication/totp_settings";
import { Heading } from "common/core/typography";

import UserAuthOptionsQuery, {
  type UserAuthOptions_user_User as User,
} from "./index_query.graphql";
import Styles from "./index.module.scss";

type Props = {
  userId: string;
  organizationAuthenticationRequirements: AuthenticationRequirement[];
} & MfaOptionsProps;
type AuthenticationRequirement = {
  authType: AuthTypes;
};

function EnforcedUserAuthOptions(props: {
  user: User;
  organizationAuthenticationRequirements: Props["organizationAuthenticationRequirements"];
}) {
  const { user, organizationAuthenticationRequirements } = props;
  return (
    <>
      <CardText>
        <FormattedMessage
          id="72360e9e-61f4-43cc-925f-488f8b43d5b6"
          defaultMessage="Your account admin has required at least one of these multi-factor authentication options to be set up."
        />
      </CardText>

      <CardHeading level="h3">
        <FormattedMessage
          id="b951d551-2471-4e37-a252-75d521ad98fc"
          defaultMessage="Authentication Methods:"
        />
      </CardHeading>

      <div className={Styles.checkboxes}>
        {organizationAuthenticationRequirements.map(({ authType }) => (
          <div key={authType}>
            <DeprecatedCheckboxWithLabel
              label={<FormattedAuthType authType={authType} />}
              checked
              automationId={`${authType}-checkbox`}
              disabled
              readOnly
            />
            {authType === AuthTypes.SMS && <PhoneNumberEdit phoneNumber={user.phoneNumber} />}
          </div>
        ))}
      </div>

      {organizationAuthenticationRequirements.some(
        (req) => req.authType === AuthTypes.TIME_BASED_ONE_TIME_PASSWORD,
      ) && (
        <>
          <Heading level="h3" textStyle="headingSix" className={Styles.subheader}>
            <FormattedMessage
              id="57f4deda-764f-4dd9-b368-9c95fb9c3a6b"
              defaultMessage="Authenticator App"
            />
          </Heading>
          <TotpSettings
            isSetup={user.setupAuthentication.includes(AuthTypes.TIME_BASED_ONE_TIME_PASSWORD)}
          />
        </>
      )}
    </>
  );
}

function UserAuthOptionsContainer({
  authTypes,
  setAuthTypes,
  userId,
  organizationAuthenticationRequirements,
}: Props) {
  const { loading, data } = useQuery(UserAuthOptionsQuery, { variables: { userId } });

  const user = data?.user;
  if (user && user.__typename !== "User") {
    throw new Error(`Expected user, got ${user.__typename}.`);
  }

  if (loading || !user) {
    return <LoadingIndicator />;
  }

  if (organizationAuthenticationRequirements.length) {
    return (
      <EnforcedUserAuthOptions
        organizationAuthenticationRequirements={organizationAuthenticationRequirements}
        user={user}
      />
    );
  }

  return (
    <div data-automation-id="user-auth-options">
      <CardHeading level="h3">
        <FormattedMessage
          id="635d148f-fedf-43b7-a7ac-ea810d4675b5"
          defaultMessage="Authentication Methods:"
        />
      </CardHeading>

      <MfaOptions authTypes={authTypes} setAuthTypes={setAuthTypes} user={user} />

      {authTypes[AuthTypes.TIME_BASED_ONE_TIME_PASSWORD] && (
        <>
          <h3 className={Styles.subheader}>
            <FormattedMessage
              id="57f4deda-764f-4dd9-b368-9c95fb9c3a6b"
              defaultMessage="Authenticator App"
            />
          </h3>
          <TotpSettings
            isSetup={user.setupAuthentication.includes(AuthTypes.TIME_BASED_ONE_TIME_PASSWORD)}
          />
        </>
      )}
    </div>
  );
}

export default UserAuthOptionsContainer;
