import { useState, type ReactNode, type ChangeEvent } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import SROnly from "common/core/screen_reader";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { useId } from "util/html";
import NotificationSoundMp3 from "assets/sounds/notification.mp3";
import AudioIcon from "assets/images/av_settings/grey_microphone_feedback.svg";
import { TechCheckSpeaker, TestSoundPlayer } from "common/tech_check/audio/test_sound_player";
import { simpleAssetUpload } from "util/uploader";
import { Paragraph } from "common/core/typography";
import Icon from "common/core/icon";

import Styles from "./ringtone.module.scss";
import UpdateNotarySettingsMutation from "./update_notary_settings_mutation.graphql";

type RingtoneState = { type: "none" } | { type: "loading" } | { type: "error"; msg?: ReactNode };
type Props = {
  notaryProfile: { id: string; customRingtone: null | { url: string | null } };
};

const MAX_RINGTONE_UPLOAD_SIZE_BYTES = 5_242_880; // 5mb
const MESSAGES = defineMessages({
  input: {
    id: "5cc97560-c28e-4e26-a7f5-37015327ece3",
    defaultMessage: "Upload a file",
  },
  a11yUploadLabel: {
    id: "c373dc09-c05f-44d2-92e3-1b5ded007f89",
    defaultMessage: "Upload a custom ringtone file",
  },
  wrong: {
    id: "b415801d-3c6f-43a1-af8b-b05bac28e461",
    defaultMessage: "Upload did not succeed",
  },
  tooLarge: {
    id: "85686f4c-3040-475b-b9a9-d458cd5af61c",
    defaultMessage: "Upload is too large",
  },
});

function NotaryCustomizeRingtone(props: Props) {
  const { notaryProfile } = props;
  const customizedRingtoneUrl = notaryProfile.customRingtone?.url;
  const hasCustomRingtone = Boolean(customizedRingtoneUrl);
  const customRingtoneDescribedById = useId();
  const errorId = useId();

  const [ringtone, setRingtone] = useState<RingtoneState>({ type: "none" });
  const hasError = ringtone.type === "error";

  const intl = useIntl();

  const updateNotarySettingsMutateFn = useMutation(UpdateNotarySettingsMutation);
  const wrapAsync = (promise: Promise<unknown>) => {
    setRingtone({ type: "loading" });
    return promise
      .then(() => {
        setRingtone({ type: "none" });
      })
      .catch(() => {
        setRingtone({ type: "error", msg: intl.formatMessage(MESSAGES.wrong) });
      });
  };
  const handleInputUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = target.files?.[0];
    if (!file) {
      return;
    }
    if (file.size > MAX_RINGTONE_UPLOAD_SIZE_BYTES) {
      target.value = "";
      setRingtone({ type: "error", msg: intl.formatMessage(MESSAGES.tooLarge) });
      return;
    }
    wrapAsync(
      simpleAssetUpload({ asset: file }).then((key) => {
        return updateNotarySettingsMutateFn({
          variables: {
            input: {
              notaryProfileId: notaryProfile.id,
              settings: { customRingtoneKey: key },
            },
          },
        });
      }),
    );
  };
  const handleDelete = () => {
    wrapAsync(
      updateNotarySettingsMutateFn({
        variables: {
          input: {
            notaryProfileId: notaryProfile.id,
            settings: { customRingtoneKey: null },
          },
        },
      }),
    );
  };

  return (
    <TestSoundPlayer src={customizedRingtoneUrl || NotificationSoundMp3}>
      {(playTestSound, playing) => (
        <div className={Styles.main}>
          <Paragraph>
            <FormattedMessage
              id="deaf340c-5eb0-4c5e-a8c5-e5b34da3454c"
              defaultMessage="Customize ringtone for calls"
            />
          </Paragraph>
          <Paragraph id={customRingtoneDescribedById} textColor="subtle" size="small">
            <FormattedMessage
              id="942708c2-4c09-4b9a-b8b8-d6fd8c6683b4"
              defaultMessage="Accepts .mp3"
            />
          </Paragraph>
          <div className={Styles.upload}>
            <img src={AudioIcon} alt="" />
            <SROnly>
              <FormattedMessage
                id="5c53d695-d7c7-4439-8e29-e621b6d23121"
                defaultMessage="Current ringtone:"
              />
            </SROnly>
            <FormattedMessage
              id="74e8fe40-5ad7-405e-aaec-bfb13a39335e"
              defaultMessage="{hasCustomRingtone, select, true{Custom} other{Default}} ringtone"
              tagName="div"
              values={{ hasCustomRingtone }}
            />
            {hasCustomRingtone ? (
              <Button
                buttonColor="action"
                variant="primary"
                isLoading={ringtone.type === "loading"}
                onClick={() => {
                  handleDelete();
                }}
              >
                <FormattedMessage
                  id="82375f53-941d-4552-bd37-aaba0683d5f0"
                  defaultMessage="Revert to default"
                />
              </Button>
            ) : (
              <label className={Styles.customRingtoneUpload}>
                <div className={Styles.uploadButton}>
                  <Icon name="arrow-up-square" />
                  {intl.formatMessage(MESSAGES.input)}
                </div>
                <SROnly>
                  <input
                    type="file"
                    accept="audio/mpeg"
                    aria-label={intl.formatMessage(MESSAGES.a11yUploadLabel)}
                    aria-describedby={`${errorId} ${customRingtoneDescribedById}`}
                    onChange={handleInputUpload}
                  />
                </SROnly>
              </label>
            )}
          </div>
          {hasError && (
            <div id={errorId} role="alert" className={Styles.error}>
              {ringtone.msg}
            </div>
          )}
          <div className={Styles.playMain}>
            <TechCheckSpeaker playing={playing} onClick={playTestSound} />
            <div role="button" className={Styles.playLink} tabIndex={0} onClick={playTestSound}>
              <FormattedMessage
                id="82c4a130-5e7c-4301-96b4-6675154bd49e"
                defaultMessage="Play test ringtone"
              />
            </div>
          </div>
        </div>
      )}
    </TestSoundPlayer>
  );
}

export default NotaryCustomizeRingtone;
