import { useState, useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";
import ActionButton from "common/core/action_button";
import { SearchField } from "common/core/search_field";
import TooltipOverlay from "common/core/tooltip/overlay";
import SROnly from "common/core/screen_reader";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import type { AddressType } from "graphql_globals";
import { Heading, Paragraph, Substyle } from "common/core/typography";
import FormattedAddress from "common/core/format/address";
import { useActiveOrganization } from "common/account/active_organization";
import { useId } from "util/html";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useLazyQuery } from "util/graphql/query";
import { Avatar } from "common/core/avatar";

import Styles from "./index.module.scss";
import { NavItem } from "../org_side_nav/nav_item";
import SubsidiaryOrganizationsQuery from "./subsidiary_query.graphql";
import AccountSwitcherQuery, {
  type AccountSwitcher,
  type AccountSwitcherVariables,
  type AccountSwitcher_viewer_user_organization as MainOrganization,
  type AccountSwitcher_node_Organization as Organization,
} from "./index_query.graphql";
import { useIsCommandCenter } from "../path";

type AccountSwitcherProps = {
  mainOrganization: MainOrganization;
  activeOrganization: Organization;
  hasSubsidiaryOrganizations: boolean;
  setActiveOrganization: (organizationId: string) => void;
  handleOpenSwitcherClick: () => void;
  handleCloseSwitcherClick: () => void;
  isCollapsed: boolean;
};

type AccountSwitcherWrapperProps = {
  handleOpenSwitcherClick: () => void;
  handleCloseSwitcherClick: () => void;
  isCollapsed: boolean;
};

type AccountListItemProps = {
  id: string;
  name: string | null;
  address: AddressType | null;
  onClick: () => void;
  isActive: boolean;
  isHomeOrg?: boolean;
};

const MESSAGES = defineMessages({
  switchAccountLabel: {
    id: "8c2d990b-2c6d-42a9-afc8-80be379a9dcd",
    defaultMessage: "Switch organizations. Current organization is:",
  },
  homeOrgHeading: {
    id: "0f69f75c-f880-4c92-9799-b9381a8ec9a2",
    defaultMessage: "Home organization",
  },
  orgListHeading: {
    id: "3f16cc37-d680-4952-823f-4477b8215a50",
    defaultMessage: "All other organizations",
  },
  closeAccountSwitcherLabel: {
    id: "4a769b69-f2ba-4a7b-b29d-5d5b9326c24a",
    defaultMessage: "Back to navigation menu",
  },
  searchPlaceholder: {
    id: "ad87cda6-bfad-486b-a855-109ea45a9d6b",
    defaultMessage: "Name, address, or ID",
  },
  searchLabel: {
    id: "0cae7011-63d5-405b-9adb-13666ec2af13",
    defaultMessage: "Filter organization accounts",
  },
  searchInstructions: {
    id: "b137d095-cec6-455e-a800-684c0cd99bdd",
    defaultMessage: "Results will filter as you type",
  },
  accountMenuHeader: {
    id: "c240f49e-5b5f-440d-a89d-1685483c1030",
    defaultMessage: "Switch organization",
  },
  emptyStateMessage: {
    id: "fe4dda22-0454-447c-a249-f5600841d927",
    defaultMessage: "No results found",
  },
});

function search(searchQuery: string, term: string) {
  return term.toLowerCase().includes(searchQuery);
}

function AccountListItem({
  id,
  name,
  address,
  onClick,
  isActive,
  isHomeOrg,
}: AccountListItemProps) {
  const intl = useIntl();

  return (
    <NavItem
      handleUrl="/"
      onClick={onClick}
      isActive={isActive}
      label={
        <div className={Styles.organizationLabel}>
          {isHomeOrg && (
            <Substyle textStyle="allCapsLabelSmall" textColor="white">
              {intl.formatMessage(MESSAGES.homeOrgHeading)}
            </Substyle>
          )}
          <div className={Styles.organizationName}>{name}</div>
          <div>({id})</div>
          {address?.line1 && (
            <div className={Styles.organizationAddress}>
              <FormattedAddress address={address} />
            </div>
          )}
        </div>
      }
    />
  );
}

function AccountSwitcherInner({
  mainOrganization,
  activeOrganization,
  setActiveOrganization,
  hasSubsidiaryOrganizations,
  handleOpenSwitcherClick,
  handleCloseSwitcherClick,
  isCollapsed,
}: AccountSwitcherProps) {
  const intl = useIntl();
  const switchAccountsSRLabel = useId();
  const navigate = useNavigate();
  const [showOrganizationList, setShowOrganizationList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [getSubsidiaries, { data: subsidiaries, called: queryCalled }] = useLazyQuery(
    SubsidiaryOrganizationsQuery,
  );
  const subsidiaryOrganizations =
    subsidiaries?.viewer.user?.organization?.publicSubsidiaryOrganizations || [];
  const filteredOrganizations = useMemo(() => {
    const splitSearchTerms = searchQuery.toLowerCase().split(" ");
    return subsidiaryOrganizations.filter((org) => {
      const addressString = org.address.line1 ? formattedPropertyAddress(org.address) : "";
      return splitSearchTerms.every((searchTerm) => {
        return (
          search(searchTerm, org.name!) ||
          search(searchTerm, addressString) ||
          search(searchTerm, org.id)
        );
      });
    });
  }, [searchQuery, subsidiaryOrganizations]);
  const isCommandCenter = useIsCommandCenter();

  const companyName = mainOrganization.organizationBrand?.companyName || activeOrganization.name;
  const displayedCompanyName = isCommandCenter ? companyName : activeOrganization.name;
  const displayedCompanyInitial = displayedCompanyName?.charAt(0);
  const organizationSubname = isCommandCenter ? (
    <FormattedMessage
      id="e99c4144-0de0-4a70-8324-3ee10ac5d00c"
      defaultMessage="Administrative tools"
    />
  ) : (
    `(${activeOrganization.id})`
  );

  const AccountMenuHeader = (
    <span id={switchAccountsSRLabel} className={Styles.toggleSwitcherLabel}>
      {hasSubsidiaryOrganizations && (
        <SROnly>
          {/* this serves as a clearer label for the button while also communicating the current org */}
          {intl.formatMessage(MESSAGES.switchAccountLabel)} {displayedCompanyName} (
          {activeOrganization.id})
        </SROnly>
      )}
      {isCollapsed ? (
        // These labels are hidden from screen readers because they aren't clear indicators
        // of what the button does without the visual hints
        <Avatar
          kind="lightGreen"
          size="extraSmall"
          aria-hidden="true"
          userInitials={displayedCompanyInitial}
        />
      ) : (
        <>
          <span aria-hidden="true" className={Styles.switcherButtonLabel}>
            <span className={Styles.organizationName}>{displayedCompanyName}</span>
            <span className={Styles.organizationSubname}>{organizationSubname}</span>
          </span>
          {hasSubsidiaryOrganizations && <Icon name="arrow-left-arrow-right" />}
        </>
      )}
    </span>
  );

  const accountMenuClasses = classnames(
    Styles.toggleSwitcher,
    isCollapsed && Styles.collapsed,
    !hasSubsidiaryOrganizations && Styles.nonInteractive,
  );

  return (
    <div
      className={classnames(
        Styles.accountSwitcherContainer,
        showOrganizationList && Styles.accountMenuOpen,
      )}
    >
      {/* Don't render the header of the sidebar if we're in the command center and there is no explicit company name  */}
      {isCommandCenter && companyName === activeOrganization.name ? null : (
        <div className={Styles.header}>
          {!showOrganizationList && (
            <>
              {hasSubsidiaryOrganizations && !isCommandCenter ? (
                <ActionButton
                  className={accountMenuClasses}
                  onClick={() => {
                    handleOpenSwitcherClick();
                    setShowOrganizationList(!showOrganizationList);
                    if (!queryCalled) {
                      getSubsidiaries();
                    }
                  }}
                  aria-labelledby={switchAccountsSRLabel}
                >
                  {AccountMenuHeader}
                </ActionButton>
              ) : (
                <div className={accountMenuClasses}>{AccountMenuHeader}</div>
              )}
              <TooltipOverlay aria-hidden="true" trigger="hover" placement="right" size="mini">
                <span className={Styles.organizationName}>{displayedCompanyName}</span>
              </TooltipOverlay>
            </>
          )}
          {showOrganizationList && (
            <>
              <h2>{intl.formatMessage(MESSAGES.accountMenuHeader)}</h2>
              <IconButton
                onClick={() => {
                  handleCloseSwitcherClick();
                  setShowOrganizationList(false);
                }}
                name="x-mark"
                label={intl.formatMessage(MESSAGES.closeAccountSwitcherLabel)}
                className={Styles.closeAccountSwitcherButton}
              />
            </>
          )}
        </div>
      )}
      {showOrganizationList && (
        <div className={Styles.accountMenuContainer}>
          <ul className={Styles.homeOrgList}>
            <AccountListItem
              onClick={() => {
                // TODO: Update with REAL-8181
                setActiveOrganization(mainOrganization.id);
                setShowOrganizationList(false);
                handleCloseSwitcherClick();
                setSearchQuery("");
                navigate("/");
              }}
              id={mainOrganization.id}
              name={mainOrganization.name}
              address={mainOrganization.address}
              isActive={mainOrganization.id === activeOrganization.id}
              isHomeOrg
            />
          </ul>
          <div className={Styles.searchFieldContainer}>
            <Heading textStyle="allCapsLabelSmall" textColor="white" level="h3">
              {intl.formatMessage(MESSAGES.orgListHeading)}
            </Heading>
            <SearchField
              onChange={({ value }) => {
                setSearchQuery(value);
              }}
              value={searchQuery}
              placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
              aria-label={intl.formatMessage(MESSAGES.searchLabel)}
              aria-describedby="search-field-instructions"
              searchOnClear
              size="large"
              autoFocus
            />
            <SROnly>
              <p id="search-field-instructions">
                {intl.formatMessage(MESSAGES.searchInstructions)}
              </p>
              <h3>
                <FormattedMessage
                  id="5b026725-b8ee-4468-853c-54c4d959aa20"
                  defaultMessage="{number, plural, one {result} other {results}}"
                  values={{ number: filteredOrganizations.length }}
                />
              </h3>
            </SROnly>
          </div>
          {filteredOrganizations.length ? (
            <ul>
              {filteredOrganizations.map((organization) => {
                return (
                  <AccountListItem
                    key={organization.id}
                    onClick={() => {
                      // TODO: Update with REAL-8181
                      setActiveOrganization(organization.id);
                      setShowOrganizationList(false);
                      handleCloseSwitcherClick();
                      setSearchQuery("");
                      navigate("/");
                    }}
                    id={organization.id}
                    name={organization.name}
                    address={organization.address}
                    isActive={organization.id === activeOrganization.id}
                  />
                );
              })}
            </ul>
          ) : (
            <div aria-live="assertive">
              <Paragraph textAlign="center" textColor="white">
                <strong>{intl.formatMessage(MESSAGES.emptyStateMessage)}</strong>
              </Paragraph>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default function AccountSwitcherWrapper(props: AccountSwitcherWrapperProps) {
  const [activeOrganizationId, setActiveOrganizationId] = useActiveOrganization();
  const isCommandCenter = useIsCommandCenter();

  const { data, loading } = useQuery<AccountSwitcher, AccountSwitcherVariables>(
    AccountSwitcherQuery,
    {
      variables: { organizationId: activeOrganizationId! },
    },
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.node;

  if (!organization) {
    throw new Error("Node did not return an organization");
  }

  if (organization.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization.__typename}`);
  }

  const mainOrganization = data.viewer.user?.organization;

  if (!mainOrganization) {
    return null;
  }

  return (
    <AccountSwitcherInner
      {...props}
      mainOrganization={mainOrganization}
      activeOrganization={organization}
      hasSubsidiaryOrganizations={
        mainOrganization.hasPublicSubsidiaryOrganizations && !isCommandCenter
      }
      setActiveOrganization={setActiveOrganizationId}
    />
  );
}
