import { type InjectedFormProps, reduxForm } from "redux-form";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { type ReactElement, useState } from "react";
import { useSearchParams } from "react-router-dom";

import compose from "util/compose";
import { DeprecatedPhoneCountryCodeField } from "common/form/fields/phone/country_code";
import { DeprecatedPhoneNumberField } from "common/form/fields/phone/number";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { getFormValues } from "util/form";
import { b, useId } from "util/html";
import { validatePhoneNumberLength } from "validators/form";
import { deprecatedIsInternational } from "common/form/inputs/phone/country_code";
import { useMutation, useQuery } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import Button from "common/core/button";
import { ResendCode, VerifyBanner } from "common/auth_gate/sms";
import MfaIcon from "assets/images/mfa_icon.svg";
import MfaSuccessIcon from "assets/images/mfa_success.svg";
import AlertMessage from "common/core/alert_message";
import { DeprecatedMaskedField } from "common/form/fields/masked";
import Icon from "common/core/icon";
import { captureException } from "util/exception";
import { Heading, Paragraph } from "common/core/typography";
import { AuthTypes } from "graphql_globals";
import { hardNavigateTo } from "util/navigation";

import GenerateSmsTokenMutation from "./generate_sms_token_mutation.graphql";
import Styles from "./index.module.scss";
import SubmitSmsTokenMutation from "./submit_sms_token_mutation.graphql";
import UserSetupAuthQuery from "./user_setup_auth.graphql";

type GError = Error & { graphQLErrors: null | { code: number }[] };

type SubmitError = { type: "invalid" } | { type: "unexpected"; message: ReactElement };

type MfaStep =
  | { type: "none" | "success" }
  | { type: "textError" }
  | { type: "confirmSuccess" }
  | {
      type: "submitError";
      error: SubmitError;
    };

type Props = {
  refetch?: () => void;
};

type FormValues = {
  phoneCountryCode: string;
  phoneNumber: string;
  secret: string;
};

type GetFormValueProps = {
  formValues: FormValues;
};

type InnerProps = InjectedFormProps & GetFormValueProps & Props;

type SetupNumberProps = {
  status: MfaStep;
  formValues: FormValues;
  invalid: boolean;
};

type SubmitCodeProps = {
  textError: SubmitError | null;
  last4: string;
  goBack: () => void;
  sendText: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  success: {
    id: "0719de79-7901-4841-8f93-5003825b85cd",
    defaultMessage: "You successfully set up multi-factor authentication.",
  },
});

function validate(values: FormValues) {
  return validatePhoneNumberLength({
    field: "phoneNumber",
    isInternational: deprecatedIsInternational({ countryCode: values.phoneCountryCode }),
  })(values);
}

function getError(error: GError) {
  const graphQLError = error.graphQLErrors?.[0];
  switch (graphQLError?.code) {
    case 401:
      return { type: "invalid" as const };
    default:
      captureException(error);
      return {
        type: "unexpected" as const,
        message: (
          <FormattedMessage
            id="2fa3618b-9231-423a-ba18-f81d19925841"
            defaultMessage="Unexpected error"
          />
        ),
      };
  }
}

function SmsMfaSetup({ formValues, handleSubmit, invalid, change, refetch }: InnerProps) {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const redirectUponCompletion = searchParams.get("redirectUponCompletion");
  const last4 = formValues.phoneNumber ? formValues.phoneNumber.slice(-4) : "";
  const [status, setStatus] = useState<MfaStep>({ type: "none" });
  const generateSmsTokenMutationFn = useMutation(GenerateSmsTokenMutation);
  const submitSmsTokenMutateFn = useMutation(SubmitSmsTokenMutation);

  const { data } = useQuery(UserSetupAuthQuery);
  const testModeAllowed = Boolean(
    data?.viewer.user?.setupAuthentication.includes(AuthTypes.SMS_TEST_MODE),
  );
  const phoneNumber = formValues.phoneCountryCode + formValues.phoneNumber;

  const sendText = () => {
    generateSmsTokenMutationFn({
      variables: {
        input: {
          phoneNumber,
        },
      },
    })
      .then(() => {
        setStatus({ type: "success" });
      })
      .catch(() => setStatus({ type: "textError" }));
  };

  const sendTextCallback = () =>
    generateSmsTokenMutationFn({
      variables: {
        input: { phoneNumber },
      },
    });

  const submitToken = () => {
    submitSmsTokenMutateFn({
      variables: {
        input: {
          secret: formValues.secret,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
        setStatus({ type: "confirmSuccess" });
      })
      .catch((error: GError) => {
        setStatus({ type: "submitError", error: getError(error) });
      });
  };

  const resolveStatus =
    status.type === "none" || status.type === "textError" ? sendText : submitToken;
  const textError = status.type === "submitError" ? status.error : null;
  const goBack = () => {
    // set initial status and clear verify page
    setStatus({ type: "none" });
    change("secret", "");
  };

  if (status.type === "confirmSuccess") {
    return (
      <div className={Styles.form} onSubmit={handleSubmit(resolveStatus)}>
        <img className={Styles.image} src={MfaSuccessIcon} aria-hidden="true" alt="" />
        <Heading level="h1" textStyle="headingFour" textAlign="center" className={Styles.heading}>
          <FormattedMessage
            id="c971e9eb-f0ad-4819-a197-a0e48dfda742"
            defaultMessage="Your account is protected!"
          />
        </Heading>
        <Paragraph size="large" className={Styles.info}>
          <FormattedMessage
            id="5ba6cc44-e882-4bb6-bb0c-873f8c0d1e50"
            defaultMessage="You successfully secured your account with multi-factor authentication."
          />
        </Paragraph>
        <div className={Styles.confirmContainer}>
          <Icon className={Styles.checkmark} name="tick" />
          <div className={Styles.confirmTextContainer}>
            <div className={Styles.confirmLabel}>
              <FormattedMessage
                id="ca3fb27a-5658-42ff-89eb-5becb1919f19"
                defaultMessage="Text message"
              />
            </div>
            <div className={Styles.confirmNumber}>
              <FormattedMessage
                id="d39c461c-ae76-4e1b-bf87-e37ac7d55a1d"
                defaultMessage="<b>+1 (***) ***-{last4}</b>"
                values={{ last4, b }}
              />
            </div>
          </div>
          <Button
            className={Styles.editButton}
            buttonColor="action"
            variant="secondary"
            onClick={goBack}
          >
            <Icon className={Styles.pencil} name="pencil" />
            <FormattedMessage id="6ecf9b08-7cab-4e08-b298-99055e87124c" defaultMessage="Edit" />
          </Button>
        </div>
        <div className={Styles.divider}></div>
        <div className={Styles.completeButtonContainer}>
          <Button
            className={Styles.completeButton}
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            role="link"
            onClick={refetch ? refetch : () => hardNavigateTo(redirectUponCompletion as string)}
          >
            <FormattedMessage id="9a780239-c234-4a23-a641-0082c0718b7e" defaultMessage="Complete" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form className={Styles.form} onSubmit={handleSubmit(resolveStatus)}>
      <img className={Styles.image} src={MfaIcon} aria-hidden="true" alt="" />
      <Heading level="h1" textStyle="headingFour" textAlign="center" className={Styles.heading}>
        <FormattedMessage
          id="59b9a6e5-c501-4c19-9bf0-8e49d70c4ec9"
          defaultMessage="Secure your account"
        />
      </Heading>
      <Paragraph size="large" className={Styles.info}>
        <FormattedMessage
          id="e6b54e00-bcbf-42de-a909-82b3b9e1ec0e"
          defaultMessage="Next, enable multi-factor authentication (MFA) by entering a phone number to receive a 6-digit verification code via text."
        />
      </Paragraph>
      {status.type === "none" || status.type === "textError" ? (
        <>
          <SetUpNumber invalid={invalid} formValues={formValues} status={status} />
          {testModeAllowed && (
            <AlertMessage className={Styles.skipMfaAlert} kind="info" centerText>
              <div className={Styles.skipMfaContainer}>
                <div className={Styles.skipMfaText}>
                  <FormattedMessage
                    id="b8a02db9-ca5b-4786-aa52-0dc82ccf227f"
                    defaultMessage="Skip using code. Visible in test environments only."
                  />
                </div>
                <Button
                  className={Styles.testCodeButton}
                  disabled={invalid}
                  buttonColor="action"
                  variant="primary"
                  onClick={() => {
                    generateSmsTokenMutationFn({
                      variables: {
                        input: {
                          phoneNumber: formValues.phoneNumber,
                          testMode: true,
                        },
                      },
                    })
                      .then(() => {
                        setStatus({ type: "success" });
                        change("secret", "000000");
                      })
                      .catch(() => setStatus({ type: "textError" }));
                  }}
                >
                  <FormattedMessage
                    id="dbd74569-3445-4926-85dc-e6defb23e69a"
                    defaultMessage="Use test code"
                  />
                </Button>
              </div>
            </AlertMessage>
          )}
        </>
      ) : (
        <SubmitCode
          textError={textError}
          last4={last4}
          goBack={goBack}
          sendText={sendTextCallback}
        />
      )}
    </form>
  );
}

function SetUpNumber({ status, formValues, invalid }: SetupNumberProps) {
  return (
    <>
      <div className={Styles.phoneNumber}>
        <FormattedMessage
          id="5cad57bc-4a97-40de-99e6-8d15c30f0082"
          defaultMessage="Enter Phone Number"
        />
        <div className={Styles.phoneNumberInput}>
          <DeprecatedPhoneCountryCodeField id="phoneCountryCode" name="phoneCountryCode" />
          <DeprecatedPhoneNumberField
            id="phoneNumber"
            name="phoneNumber"
            isInternational={deprecatedIsInternational({
              countryCode: formValues.phoneCountryCode,
            })}
          />
        </div>
        <FormGroupErrors fields={["phoneCountryCode", "phoneNumber"]} />
        <div className={Styles.messageRates}>
          <FormattedMessage
            id="1ef7d2e4-bf0b-4a72-95c7-c5b275bdaa90"
            defaultMessage="Message and data rates may apply."
          />
        </div>
        <div className={Styles.phoneDisclaimer}>
          <FormattedMessage
            id="433079b4-b2d3-4cc0-9397-012f27940b10"
            defaultMessage="We'll use this phone number to occasionally send updates from the Proof team"
          />
        </div>
        {status.type === "textError" && (
          <AlertMessage>
            <p className={Styles.errorMessage}>
              <FormattedMessage
                id="45667a38-f220-432f-b91c-cc258829a0c6"
                defaultMessage="Something went wrong. We couldn't update your details at this time. Please try again or reach out to customer support."
              />
            </p>
          </AlertMessage>
        )}
      </div>
      <div className={Styles.divider}></div>
      <div className={Styles.buttonContainer}>
        <Button
          className={Styles.setupButton}
          disabled={invalid}
          buttonColor="action"
          buttonSize="large"
          variant="primary"
          type="submit"
        >
          <FormattedMessage id="ac72adad-2113-4d67-89a8-ce5e804db4e6" defaultMessage="Continue" />
        </Button>
      </div>
    </>
  );
}

function SubmitCode({ textError, last4, goBack, sendText }: SubmitCodeProps) {
  const secretInputId = useId();
  return (
    <>
      <div className={Styles.phoneNumber}>
        <VerifyBanner
          error={textError}
          last4={last4}
          actionButton={
            <ResendCode
              sendText={sendText}
              buttonClass={Styles.bannerDisplayResendButton}
              loadingClass={Styles.bannerDisplayLoading}
            />
          }
        />
        <div>
          <FormattedMessage
            id="4917932e-da83-4893-9429-a9f3fda589a2"
            defaultMessage="Enter 6-digit verification code"
          />
        </div>
        <DeprecatedMaskedField
          name="secret"
          id={secretInputId}
          maskType="number"
          maxlength={6}
          placeholder="000000"
          useStyledInput
        />
      </div>
      <div className={Styles.divider}></div>
      <div className={Styles.verifyButtonContainer}>
        <Button
          className={Styles.verifyButton}
          buttonColor="action"
          buttonSize="large"
          variant="secondary"
          onClick={goBack}
        >
          <FormattedMessage id="867d3ad5-de68-4deb-befb-b65386230da5" defaultMessage="Go Back" />
        </Button>
        <Button
          className={Styles.verifyButton}
          buttonColor="action"
          buttonSize="large"
          variant="primary"
          type="submit"
        >
          <FormattedMessage id="acdd46d2-a0cb-408a-9bd7-d4d37af96bab" defaultMessage="Continue" />
        </Button>
      </div>
    </>
  );
}

export default compose(
  reduxForm({
    form: "smsMfaSetup",
    validate,
  }),
  getFormValues("smsMfaSetup"),
)(SmsMfaSetup) as unknown as (props: Props) => ReactElement;
