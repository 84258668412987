import { FormattedMessage } from "react-intl";
import { Navigate, Routes, Route } from "react-router-dom";

import { SidebarSectionHeader, SidebarV2 } from "common/sidebar";
import { useHeadingDetails } from "common/settingsv2/common";

import {
  TransactionSettings,
  SettingsSidebarTransactionsLink,
  TRANSACTIONS_ROUTE,
} from "../sidebar_settings/transaction";
import {
  NotaryInstructions,
  NotaryInstructionsTab,
  TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE,
} from "../sidebar_settings/transaction/notary_instructions";
import { TermsSettings, TERMS_ROUTE } from "../sidebar_settings/terms";
import { TermsAndConditions } from "../sidebar_settings/terms/terms";
import {
  ClosingSettings,
  ClosingSettingsTab,
  TRANSACTIONS_CLOSING_SETTINGS_ROUTE,
} from "../sidebar_settings/transaction/closing_settings";

function LenderTeamMemberSidebar() {
  const { orgName } = useHeadingDetails();
  return (
    <SidebarV2
      title={
        <FormattedMessage id="78fc6fde-e64b-4dd8-b879-ad3890dcb4fb" defaultMessage="Settings" />
      }
    >
      <SidebarSectionHeader title={orgName} />
      <SettingsSidebarTransactionsLink />
    </SidebarV2>
  );
}

export default function LenderAdminSettings() {
  return (
    <>
      <LenderTeamMemberSidebar />
      <Routes>
        <Route
          path={TRANSACTIONS_ROUTE}
          element={
            <TransactionSettings
              tabs={
                <>
                  <NotaryInstructionsTab />
                  <ClosingSettingsTab />
                </>
              }
            />
          }
        >
          <Route path={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} element={<NotaryInstructions />} />
          <Route path={TRANSACTIONS_CLOSING_SETTINGS_ROUTE} element={<ClosingSettings />} />
          <Route index element={<Navigate to={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} replace />} />
        </Route>

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route
          index
          element={
            <Navigate
              replace
              to={`${TRANSACTIONS_ROUTE}/${TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE}`}
            />
          }
        />
      </Routes>
    </>
  );
}
