import { Outlet } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper, SettingsTitle } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

const TITLE = (
  <FormattedMessage id="a301adda-76f8-45d2-89d3-3a659b40e807" defaultMessage="Team Security" />
);
export const SECURITY_ROUTE = "security";

export function SettingsSidebarSecurityLink() {
  return (
    <SidebarTabLink to={SECURITY_ROUTE}>
      <FormattedMessage id="5c0e31a9-ca1e-4a81-a259-d26ae074debb" defaultMessage="Team Security" />
    </SidebarTabLink>
  );
}

export function SecuritySettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsTeamSecurity),
  });
  return (
    <>
      <SettingsPageWrapper>
        <SettingsHeader title={TITLE} tabs={[]} />

        <SettingsTitle>
          <FormattedMessage
            id="e832cdff-9d64-4510-a157-e79dc86b9349"
            defaultMessage="Login security"
          />
        </SettingsTitle>

        <Outlet />
      </SettingsPageWrapper>
    </>
  );
}
