import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useForm, type UseFormReturn } from "common/core/form";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import OrgBrandTheme from "common/core/brand/org_brand_theme";
import Link from "common/core/link";
import { RadioLabel, RadioInput } from "common/core/form/option";

import { ColorUpdater } from "./common/color_updater";
import ButtonPreviewContainer from "./common/button_preview_container";
import useOrganizationBrandUpdater from "./common/update_organization_brand_styles";
import type { BrandSettingsButtonColor as OrganizationBrand } from "./button_color.fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  inlineLinkColor: {
    id: "7727f716-3e18-4a60-b503-7cccc28a587f",
    defaultMessage: "Inline link color",
  },
});

type FormValues = {
  inlineLinkColor: string;
  inlineLinkColored: string;
};

type Props = {
  organizationBrand: OrganizationBrand;
  disabled?: boolean;
};

export function InlineLinkColorCard({
  form,
  isUpdating = false,
  setResetValue,
  withFooter = false,
  disabled,
}: {
  form: UseFormReturn<FormValues>;
  isUpdating?: boolean;
  setResetValue: (value: boolean) => void;
  withFooter?: boolean;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const { formState, watch, register } = form;
  const { errors } = formState;
  const inlineLinkColor = watch("inlineLinkColor");
  const inlineLinkColored = watch("inlineLinkColored");

  return (
    <Card
      className={Styles.inlineLinkCard}
      footer={
        withFooter && (
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            isLoading={isUpdating}
            automationId="inline-color-submit"
            disabled={disabled}
          >
            <FormattedMessage
              id="5dbb2ebe-5a10-4ef6-a616-b701a13a5c70"
              defaultMessage="Save changes"
            />
          </Button>
        )
      }
    >
      <CardHeading level="h3">
        <FormattedMessage
          id="832fcb60-1b6e-440d-9c84-e9b6d759edcb"
          defaultMessage="Inline link color"
        />
      </CardHeading>
      <CardText>
        <FormattedMessage
          id="5101d463-10ff-42de-b3a0-7f564791a882"
          defaultMessage={
            "The custom inline link color applies to inline links on the signer login screen and signer landing pages. Choose an option to customize your inline like color below."
          }
        />
      </CardText>

      <RadioLabel
        label={
          <FormattedMessage
            id="e834f71e-20ab-4e92-a5c8-934060d15173"
            defaultMessage="Underline and match body copy color"
          />
        }
        radio={
          <RadioInput<FormValues["inlineLinkColored"]>
            value="false"
            disabled={disabled}
            {...register("inlineLinkColored")}
          />
        }
      />
      <RadioLabel
        label={
          <FormattedMessage
            id="3a58c060-14c8-4d73-a989-1098cb2ab260"
            defaultMessage="Underline and apply custom inline link color (only on white backgrounds)"
          />
        }
        radio={
          <RadioInput<FormValues["inlineLinkColored"]>
            value="true"
            disabled={disabled}
            {...register("inlineLinkColored")}
          />
        }
      />

      {inlineLinkColored === "true" ? (
        <ColorUpdater
          colorFieldName="inlineLinkColor"
          form={form}
          placeholderText={intl.formatMessage(MESSAGES.inlineLinkColor)}
          isUpdating={isUpdating}
          setResetValue={setResetValue}
          disabled={disabled}
        />
      ) : null}

      <ButtonPreviewContainer isLink hasHexCodeError={errors.inlineLinkColor?.type === "pattern"}>
        <OrgBrandTheme
          theme={{
            inlineLinkColor,
            inlineLinkColored: inlineLinkColored === "true",
          }}
        >
          This is an example of an&nbsp;
          <Link onClick={() => {}}>
            <FormattedMessage
              id="108bc40f-ee8d-407b-9639-98403ba07269"
              defaultMessage="inline link"
            />
          </Link>
          .
        </OrgBrandTheme>
      </ButtonPreviewContainer>
    </Card>
  );
}

export function InlineLinkColor(props: Props) {
  const { id: organizationBrandId, styles } = props.organizationBrand;
  const defaultThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50");
  const initialInlineLinkColored = styles.inlineLinkColored ?? true;
  const initialLinkColor = styles.inlineLinkColor || defaultThemeColor;
  const [resetValue, setResetValue] = useState(false);
  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      inlineLinkColor: initialLinkColor,
      inlineLinkColored: String(initialInlineLinkColored),
    },
  });
  const { handleUpdate, isUpdating } = useOrganizationBrandUpdater(organizationBrandId);

  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      const { inlineLinkColor, inlineLinkColored } = formValues;
      const color = resetValue ? null : inlineLinkColor;
      const linkColored = resetValue ? null : inlineLinkColored === "true";

      return handleUpdate({
        inlineLinkColor: color,
        inlineLinkColored: linkColored,
      }).then(() => {
        setResetValue(false);
      });
    },
    [organizationBrandId, resetValue],
  );

  const inlineLinkColored = form.watch("inlineLinkColored");

  useEffect(() => {
    if (inlineLinkColored === "true") {
      setResetValue(false);
    }
  }, [inlineLinkColored]);
  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <InlineLinkColorCard
        form={form}
        setResetValue={setResetValue}
        withFooter
        isUpdating={isUpdating}
        disabled={props.disabled}
      />
    </form>
  );
}
