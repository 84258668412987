import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";
import AlertMessage from "common/core/alert_message";
import type { SettingProfileEnum } from "graphql_globals";
import { capitalizeFirstLetter } from "util/string";

import Styles from "./index.module.scss";

export function BrandProfileBadge({
  brandProfileId,
  numOrgsUsing,
  totalOrgs,
}: {
  brandProfileId: string;
  numOrgsUsing: number;
  totalOrgs: number;
}) {
  return (
    <>
      {numOrgsUsing > 0 ? (
        numOrgsUsing === totalOrgs ? (
          <Badge
            kind="success"
            withIcon="success"
            data-automation-id={`bsp-${brandProfileId}-badge`}
          >
            <FormattedMessage
              id="65864d09-dca8-4202-b326-483e29ded59f"
              defaultMessage="In use (all organizations)"
            />
          </Badge>
        ) : (
          <Badge
            kind="success"
            withIcon="success"
            data-automation-id={`bsp-${brandProfileId}-badge`}
          >
            <FormattedMessage
              id="d4c6281e-5a53-4dbf-9ed0-e09bf9a139c6"
              defaultMessage="In use ({orgsCount} {orgsCount, plural, one {organization} other {organizations}})"
              values={{ orgsCount: numOrgsUsing }}
            />
          </Badge>
        )
      ) : (
        <Badge kind="infoBlue" withIcon="info" data-automation-id={`bsp-${brandProfileId}-badge`}>
          <FormattedMessage id="e817f800-8b45-4cdb-a777-f077575bd980" defaultMessage="Not in use" />
        </Badge>
      )}
    </>
  );
}

export function SettingsRestrictedBanner({
  showBanner,
  profileType,
}: {
  showBanner: boolean;
  profileType: SettingProfileEnum;
}) {
  if (!showBanner) {
    return null;
  }
  return (
    <AlertMessage kind="info" className={Styles.restrictedBanner}>
      <FormattedMessage
        id="e817f800-8b45-4cdb-a777-f077575bd980"
        defaultMessage="Your organization is using a {profileType} profile set by a company administrator. You cannot edit these settings. Contact your administrator to make changes."
        values={{ profileType: capitalizeFirstLetter(profileType.toLowerCase()) }}
      />
    </AlertMessage>
  );
}
