import "common/form/deprecated_form.scss";

import { useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import type {
  Eligibility_viewer as Viewer,
  Eligibility_organization_Organization as Organization,
} from "common/settingsv2/sidebar_settings/organization/eligibility/eligibility_query.graphql";
import compose from "util/compose";
import { getFormValues, composeValidators } from "util/form";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import SaveButton from "common/core/save_button";
import { DeprecatedMultiSelectField } from "common/form/fields/multi_select";
import { oldStylePlaceholder } from "util/input";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { useMutation } from "util/graphql";
import { validatePresence } from "validators/form";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardText } from "common/core/card";

import UpdateTitleAgency from "./update_title_agency_mutation.graphql";

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "operatingStates", label: "States you operate in" }),
    validatePresence({
      field: "titleUnderwriters",
      label: "Title underwriters you work with",
    }),
  )(values);

type FormValues = {
  titleUnderwriters: { label: string | null | undefined; value: string | undefined }[] | undefined;
};

type GetFormValueProps = {
  formValues: FormValues;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type Props = {
  organization: Organization;
  viewer: Viewer;
};

type InnerProps = Props & FormProps & GetFormValueProps;

function TitleUnderwriters({
  viewer,
  organization: { id, publicTitleAgency },
  initialize,
  formValues,
  handleSubmit,
  submitting,
}: InnerProps) {
  const [status, setStatus] = useState<"error" | "success" | null>(null);

  useEffect(() => {
    initialize({
      titleUnderwriters: publicTitleAgency?.titleUnderwriters.map((underwriter) => ({
        label: underwriter.name,
        value: underwriter.id,
      })),
    });
  }, []);

  const updateTitleAgencyMutation = useMutation(UpdateTitleAgency);

  const titleUnderwritersList = viewer.publicTitleUnderwriters.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const save = async () => {
    const titleUnderwriters = formValues.titleUnderwriters?.map(
      (underwriter) => underwriter.value!,
    );

    try {
      await updateTitleAgencyMutation({
        variables: {
          input: {
            organizationId: id,
            titleUnderwriterIds: titleUnderwriters,
          },
        },
      });
      setStatus("success");
    } catch {
      setStatus("error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} data-automation-id="title-underwriters-form">
        <Card footer={<SaveButton submitting={submitting} className="is-form" />}>
          <CardHeading level="h3">
            <FormattedMessage
              id="8a039c6c-cbdf-449d-8162-c7c0c1149061"
              defaultMessage="Title Underwriters"
            />
          </CardHeading>
          <CardText>
            <FormattedMessage
              id="b07c0454-ca87-4346-a883-6157ba9654b5"
              defaultMessage="Add all underwriters that your team might work with. If an underwriter is not added to this list, they will not be available for selection when creating a transaction. "
            />
          </CardText>

          <FormGroup disableFormRowStyle fields={["titleUnderwriters"]}>
            <DeprecatedMultiSelectField
              name="titleUnderwriters"
              items={titleUnderwritersList}
              hasAllOption={false}
              displayRequiredAsterisk
              placeholderRenderer={oldStylePlaceholder}
              placeholder="Title Underwriters"
              styledInput={false}
              automationId="title-underwriters-field"
            />

            <FormGroupErrors fields={["titleUnderwriters"]} alert />
          </FormGroup>
        </Card>
      </form>
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </>
  );
}

export default compose(
  reduxForm<FormValues, Props>({
    form: "TitleUnderwriters",
    validate,
  }),
  getFormValues<InnerProps>("TitleUnderwriters"),
)(TitleUnderwriters);
